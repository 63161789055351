import React, {useEffect} from 'react';
import {MdAutoFixHigh, MdContentCopy, MdSend} from "react-icons/md";
import {toast} from "react-toastify";
import {useGptAgent} from "../../../hooks/gptagent";
import {useEntitlements} from "../../../hooks/entitlements";
import { Spinner } from 'react-bootstrap'; // Import react-bootstrap spinner

export function parseTags(prompt) {
    const regex = /\{tag:([^}]+)\}/g;
    let tags = [];
    let match;
    while ((match = regex.exec(prompt)) !== null) {
        const tag = match[1];
        if (!tags.includes(tag)) {
            tags.push(tag);
        }
    }
    return tags;
}

function PromptDesignCard({prompt, onPromptUpdated = (prompt) => prompt = () => {}, onSubmitQuery = (prompt) => undefined}) {
    const {isEntitled, isLoadingEntitlements} = useEntitlements();
    const {postPrompt: elaboratePrompt} = useGptAgent("aiart", "single-prompt-elaboration");
    const [isElaborating, setIsElaborating] = React.useState(false);
    const [activeQuery, setActiveQuery] = React.useState(null);
    const [aspectRatio, setAspectRatio] = React.useState(1);

    useEffect(() => {
        // Parse the aspect ratio from the prompt's --ar field
        const arMatch = /--ar ([0-9]+):([0-9]+)/.exec(prompt.prompt);
        if(arMatch) {
            setAspectRatio(parseInt(arMatch[1]) / parseInt(arMatch[2]));
        }
        // If that wasn't there see if --width and --height were defined
        const widthMatch = /--width ([0-9]+)/.exec(prompt.prompt);
        const heightMatch = /--height ([0-9]+)/.exec(prompt.prompt);
        if(widthMatch && heightMatch) {
            setAspectRatio(parseInt(widthMatch[1]) / parseInt(heightMatch[1]));
        }
    }, [prompt.prompt]);

    useEffect(() => {

    }, [aspectRatio]);

    useEffect(() => {
        if(!isEntitled("gpt") || isLoadingEntitlements) return;

        // If prompt contains gpt() then elaborate it
        if (prompt.prompt.includes("gpt(")) {
            startElaboration(false);
        }
    }, [prompt.prompt, isLoadingEntitlements]);

    async function startElaboration(toast=true) {
        const p = await elaborate(prompt.prompt, toast);
        parseTags(p).forEach((tag) => {
            if (!prompt.metadata.tags.includes(tag)) {
                prompt.metadata.tags.push(tag);
            }
        });
        // Replace tags with empty string
        prompt.prompt = p.replaceAll(/\{tag:([^}]+)\}/g, '').trim();
        prompt.data = JSON.stringify(prompt.metadata);
        onPromptUpdated(prompt);
    }

    // Find the balanced closing parenthesis after the gpt(
    function findBalancedParenthesis(prompt, start) {
        let count = 0;
        for (let i = start; i < prompt.length; i++) {
            if (prompt[i] === '(') {
                count++;
            } else if (prompt[i] === ')') {
                count--;
                if (count === 0) {
                    return i;
                }
            }
        }
        return -1;
    }

    async function elaborate(prompt, toast=true, start=0, end=-1) {
        setIsElaborating(true);
        if(end < 0) end = prompt.length;
        // Find the start of the text after the gpt( if it is in the string
        let gptStart = prompt.indexOf("gpt(", start);
        let gptEnd = findBalancedParenthesis(prompt, gptStart);
        // If gpt stat and gpt end are > -1 let's break the string into 3 parts and elaborate the middle part
        const bodyStart = gptStart > -1 ? prompt.substring(0, gptStart) : '';
        const bodyEnd = prompt.substring(gptEnd + 1);
        const gptBody = gptEnd > -1 ? prompt.substring(gptStart + 4, gptEnd) : prompt;

        const gptValue = await elaboratePrompt(gptBody,
            "Elaborating on prompt: " + gptBody,
            content => "Elaborated prompt.", toast);
        // Reconstruct the string with the indices
        setIsElaborating(false);
        return bodyStart + gptValue + bodyEnd;
    }

    return (
        <div className={"flex-grow-1 black_more row list-group-item mt-3"}>
            {prompt?.metadata?.title && <h4>{prompt.metadata.title}</h4>}
            {prompt.prompt}
            <span className={"wrapped-pre"}><br/>
                {Array.isArray(prompt?.metadata?.tags) && prompt.metadata.tags.map((tag, index) => (
                    <span key={index} className={"tag"}>{tag}</span>))}
            </span>
            <div className={"create-card-flex-between-container"} style={{
                backgroundColor: "#00000033",
                marginLeft: -24,
                marginRight: -24,
                paddingLeft: 16,
                paddingRight: 16,
                paddingTop: 8,
                paddingBottom: 12,
                marginBottom: -16
            }}>
                <div><MdContentCopy className={"mdicon"} onClick={() => {
                    var datastr = `${prompt.data}::.001`.replace("{", "\\{").replace("}", "\\}");
                    navigator.clipboard.writeText(`${datastr} ${prompt.prompt}`);
                    toast(`Copied ${prompt.prompt} to clipboard.`, {type: "success"});
                }}/>
                    {isEntitled("gpt") && <MdAutoFixHigh className={"mdicon ml-2"}
                                                         tooltip={"Elaborate on prompt using GPT"}
                                                         onClick={async () => {
                                                             await startElaboration();
                                                         }}/>}
                </div>
                <div className={"create-card-flex-between-grow"}/>
                <MdSend className={"mdicon"} onClick={() => {
                    navigator.clipboard.writeText(`${prompt.data}::.001 ${prompt.prompt}`);
                    const activeQuery = onSubmitQuery(`${prompt.data}::.001 ${prompt.prompt}`);
                    // if active query is a promise then we should wait for it to resolve
                    if(activeQuery instanceof Promise) {
                        activeQuery.then((result) => {
                            console.log("Active query: ", result);
                        });
                    }
                }}/>

                {isElaborating && <div style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#00000077",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Spinner animation="border" role="status"/>
                    <span>Elaborating...</span>
                </div>}
            </div>
        </div>
    );
}

export default PromptDesignCard;