import React from 'react';
import {Button, Card, Form} from "react-bootstrap";
import {toast} from "react-toastify";
import {useEndpoint} from "../../../../../hooks/api";

function CreateApiKey({agent, onApiKeyCreated, ...props}) {
    const {fetchAuth: createApiKeyRequest} = useEndpoint("gpt/keys/keygen");
    const [ apiKeyDescription, setApiKeyDescription ] = React.useState('');

    function handleCreateApiKey(event) {
        event.preventDefault();
        async function fetchResponse() {
            const messageResponse = createApiKeyRequest({
                "id": agent.id,
                "description": apiKeyDescription
            });
            // if error in response, show error
            if("error" in messageResponse) {
                toast.error(`Failed to create API key for agent ${agent.name}.\n${messageResponse.error}`);
            } else {
                toast.success("API key created.");
                setApiKeyDescription('');
                onApiKeyCreated();
            }
        }

        fetchResponse();
    }

    return (
        <Card>
            <Card.Header>
                <div className={"create-card-flex-between-container"}>
                    <span>Create API Key</span>
                </div>
            </Card.Header>

            <Card.Body>
                <Form onSubmit={handleCreateApiKey}>
                    <Form.Group controlId="content">
                        <Form.Label>Name:</Form.Label>
                        <Form.Control value={apiKeyDescription} onChange={(event) => setApiKeyDescription(event.target.value)} />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Create
                    </Button>
                </Form>
            </Card.Body>
        </Card>
    );
}

export default CreateApiKey;