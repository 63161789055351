import React, {useEffect, useState, useRef} from 'react';
import Dropdown from './dropdown';
import './dropdown.css';

import { Link } from 'react-router-dom';
import useWindowDimensions from "../../../hooks/WindowDimensions";
import {IconButton} from "../styledbutton";

function NavItem({item, maxLabelVisibleWidth=800}) {
    const [open, setOpen] = useState(false);
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const parentDivRef = useRef(null);
    const dropdownRef = useRef(null);

    function updateDropdownPosition() {
        const parentDiv = parentDivRef.current;
        const dropdown = dropdownRef.current;

        if (parentDiv && dropdown) {
            const rect = parentDiv.getBoundingClientRect();
            dropdown.style.top = `${rect.bottom}px`;
            dropdown.style.left = `${rect.left}px`;
        }
    }

    useEffect(() => {
        updateDropdownPosition();
        window.addEventListener('resize', updateDropdownPosition);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateDropdownPosition);
        };
    }, []);

    return (
        <div className={"dm-parent-div"} ref={parentDivRef}>
            <IconButton icon={item?.icon}
                        label={windowWidth > maxLabelVisibleWidth && item?.label ? item.label : undefined}
                        onClick={() => {updateDropdownPosition(); setOpen(!open);}} />

            {open && item?.dropdown && <Dropdown menuItems={item.dropdown} ref={dropdownRef} onSelected={() => {console.log("Closing.");setOpen(false)}} />}
        </div>
    );
}

export default NavItem;