import React, {useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';

const TagFilter = ({ tags, onFilterChanged }) => {
    const [filter, setFilter] = useState("");

    const handleFilterChange = (event) => {
        setFilter(event.target.value);
    };

    useEffect(() => {
        if (!tags || tags.length === 0) return; // Check if tags is not null, undefined, or empty
        const filterValues = filter.split(",").map(filterValue => {
            return filterValue.trim().toLowerCase().replace(/[^a-z]/g, '');
        });

        const filteredTags = tags.filter(tag => {
            // Ignore if tag is undefined or null
            if (!tag) return false;

            const formattedTag = `${tag.name?.toString()}`.toLowerCase().replace(/[^a-z]/g, '');

            return filterValues.some(filterValue => {
                console.log(`${formattedTag} == ${filterValue}`);
                return formattedTag.includes(filterValue);
            });
        });

        console.log("Unfiltered: ", tags)
        console.log("Filtered: ", filteredTags)
        onFilterChanged(filteredTags);
    }, [filter]);

    return (
        <div>
            <Form.Control type="text" placeholder="Enter tag filter values separated by commas" onChange={handleFilterChange} />
            <br/>
        </div>
    );
};

export default TagFilter;
