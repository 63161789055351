// ErrorContext.js
import React, {createContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {MdError} from "react-icons/all";

const ErrorContext = createContext();

export function useError() {
    return React.useContext(ErrorContext);
}

export function ErrorProvider({ children }) {
    const [error, onSetError] = useState(null);
    const [fatalError, onSetFatalError] = useState(null);
    const [blockInput, setBlockInput] = useState(false);

    function setFatalError(error) {
        onSetFatalError(error);
    }

    function setError(error) {
        if(error) {
            toast.error(error);
        }
        onSetError(error);
    }

    window.sendFatalError = setFatalError;
    window.sendError = setError;

    useEffect(() => {

    }, [error, fatalError]);


    const value = { setError, setFatalError };
    return (
        <ErrorContext.Provider value={value}>
            {children}
            {fatalError && !blockInput && <div
                style={{position: "absolute", left: 0, top: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,0.81)", zIndex: 1000, display: fatalError ? "block" : "none"}}>
                <div className={"centered align-content-center"}>
                    <div>
                        <div><MdError style={{fontSize: 150, color: "red"}} /></div>
                        <div className={"mt-5"}><span>Error {fatalError}</span></div>
                    </div>
                </div>
            </div>}
        </ErrorContext.Provider>
    );
}