import React from 'react';
import {useAuthenticatedArtApi} from "../../hooks/artapi";
import {Typeahead} from "react-bootstrap-typeahead";
import {IoMdSend} from "react-icons/all";
import {Button, InputGroup} from "react-bootstrap";

const PromptTextBox = ({placeholder, onChange, onClick}) => {
    const { response, error } = useAuthenticatedArtApi("prompts");

    return <InputGroup>
        <Typeahead className="flex-grow-1" id="prompt" options={response || []} placeholder={placeholder} onInputChange={onChange} onChange={onChange}  />
        <Button variant="outline-secondary" id="button-addon2" onClick={onClick}><IoMdSend/></Button>
    </InputGroup>
}

export default PromptTextBox;