import React, {useState} from 'react';
import PageHeader from "../components/PageHeader/pageheadercontrol";
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
//import { useHistory } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate} from "react-router-dom";
import StyledButton from "../components/styledbutton";

function GalleryLauncher(props) {
    const [headerImage, setHeaderImage] = useState("/img/headers/slideshow.png");
    const [limit, setLimit] = useState(100);
    const [query, setQuery] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        const searchParams = new URLSearchParams({
            chromeless: 'true',
            limit: limit.toString(),
            q: query,
        }).toString();
        const currentDomain = window.location.origin;
        navigate(`/animated-gallery?${searchParams}`);
    };

    return (
        <PageHeader image={headerImage} title={"Gallery Slideshow"}
                    description={"Set up a slideshow of images to display full screen."}
                    breadcrumb={[
                        ["Home", "/"]
                    ]}>
            <section>
                Configure your AI art slideshow by selecting the number of images you want to display or by searching
                for specific themes or keywords. Whether you prefer a small, curated selection or a diverse array, our
                advanced algorithms will generate a stunning collection of artworks tailored to your preferences. Enjoy
                a seamless grid layout that highlights the intricate details and unique styles of each AI-generated
                piece. Customize your viewing experience and immerse yourself in the captivating world of AI creativity.
            </section>

            <section>
                <h4>Configure Your AI Art Slideshow</h4>
                <Form>
                    <Form.Group controlId="formLimit">
                        <Form.Label>Number of Images</Form.Label>
                        <Row>
                            <Col xs="10">
                                <Form.Control
                                    type="range"
                                    min="50"
                                    max="1000"
                                    step="25"
                                    value={limit}
                                    onChange={(e) => setLimit(e.target.value)}
                                    className="form-control-range"
                                />
                            </Col>
                            <Col xs="2">
                                <Form.Control
                                    type="number"
                                    value={limit}
                                    onChange={(e) => setLimit(e.target.value)}
                                    readOnly
                                />
                            </Col>
                        </Row>
                    </Form.Group>

                    <Form.Group controlId="formQuery">
                        <Form.Label>Search Keywords</Form.Label>
                        <Form.Control
                            type="text"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Enter search keywords"
                        />
                    </Form.Group>

                    <StyledButton variant="primary" onClick={handleSubmit} label={"View"}>
                        Submit
                    </StyledButton>
                </Form>
            </section>
        </PageHeader>
);
}

export default GalleryLauncher;