import React, {useRef} from 'react';
import PageHeader from "../components/PageHeader/pageheadercontrol";
import useRandomImage from "../../hooks/RandomImage";
import useInterval from "../components/interval";
import {artApiFetchAsync} from "../../hooks/artapi";
import ImageGrid from "../components/imagegrid/imagegrid";
import useWindowDimensions from "../../hooks/WindowDimensions";
import {Cell} from "../components/imagegrid/grid";
import {useIndexDB} from "../providers/indexdb";
import {toast} from "react-toastify";

function keepDisplayAlive() {
    const Util={};
    Util.base64 = function(mimeType, base64) {
        return 'data:' + mimeType + ';base64,' + base64;
    };

    if(window.video) return;
    window.video = document.createElement('video');
    window.video.setAttribute('loop', '');

    function addSourceToVideo(element, type, dataURI) {
        var source = document.createElement('source');
        source.src = dataURI;
        source.type = 'video/' + type;
        element.appendChild(source);
    }

    addSourceToVideo(window.video,'webm', Util.base64('video/webm', 'GkXfo0AgQoaBAUL3gQFC8oEEQvOBCEKCQAR3ZWJtQoeBAkKFgQIYU4BnQI0VSalmQCgq17FAAw9CQE2AQAZ3aGFtbXlXQUAGd2hhbW15RIlACECPQAAAAAAAFlSua0AxrkAu14EBY8WBAZyBACK1nEADdW5khkAFVl9WUDglhohAA1ZQOIOBAeBABrCBCLqBCB9DtnVAIueBAKNAHIEAAIAwAQCdASoIAAgAAUAmJaQAA3AA/vz0AAA='));
    addSourceToVideo(window.video, 'mp4', Util.base64('video/mp4', 'AAAAHGZ0eXBpc29tAAACAGlzb21pc28ybXA0MQAAAAhmcmVlAAAAG21kYXQAAAGzABAHAAABthADAowdbb9/AAAC6W1vb3YAAABsbXZoZAAAAAB8JbCAfCWwgAAAA+gAAAAAAAEAAAEAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIAAAIVdHJhawAAAFx0a2hkAAAAD3wlsIB8JbCAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAQAAAAAAAAAAAAAAAAAAQAAAAAAIAAAACAAAAAABsW1kaWEAAAAgbWRoZAAAAAB8JbCAfCWwgAAAA+gAAAAAVcQAAAAAAC1oZGxyAAAAAAAAAAB2aWRlAAAAAAAAAAAAAAAAVmlkZW9IYW5kbGVyAAAAAVxtaW5mAAAAFHZtaGQAAAABAAAAAAAAAAAAAAAkZGluZgAAABxkcmVmAAAAAAAAAAEAAAAMdXJsIAAAAAEAAAEcc3RibAAAALhzdHNkAAAAAAAAAAEAAACobXA0dgAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAIAAgASAAAAEgAAAAAAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABj//wAAAFJlc2RzAAAAAANEAAEABDwgEQAAAAADDUAAAAAABS0AAAGwAQAAAbWJEwAAAQAAAAEgAMSNiB9FAEQBFGMAAAGyTGF2YzUyLjg3LjQGAQIAAAAYc3R0cwAAAAAAAAABAAAAAQAAAAAAAAAcc3RzYwAAAAAAAAABAAAAAQAAAAEAAAABAAAAFHN0c3oAAAAAAAAAEwAAAAEAAAAUc3RjbwAAAAAAAAABAAAALAAAAGB1ZHRhAAAAWG1ldGEAAAAAAAAAIWhkbHIAAAAAAAAAAG1kaXJhcHBsAAAAAAAAAAAAAAAAK2lsc3QAAAAjqXRvbwAAABtkYXRhAAAAAQAAAABMYXZmNTIuNzguMw=='));

    window.video.play();
}

const AnimatedGallery = () => {
    const [randomImage] = useRandomImage('midjourney');
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const { getValue, setValue } = useIndexDB();

    // Check if there is a url query paramater chromeless
    const urlParams = new URLSearchParams(window.location.search);
    const chromeless = urlParams.get('chromeless');
    const [model, setModel] = React.useState(urlParams.get('model') ?? "");
    const [q, setQuery] = React.useState(urlParams.get("q") ?? "");
    const [limit, setLimit] = React.useState(urlParams.get("limit") ?? 1000);
    const [images, setImages] = React.useState([]);

    const fullscreenRef = useRef(null);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            fullscreenRef.current.requestFullscreen().catch(err => {
                toast.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
            });
        } else {
            document.exitFullscreen();
        }
    };

    React.useEffect(() => {
        keepDisplayAlive();
    }, []);

    async function fetchAndUpdateImages() {
        const cachedImages = await getValue("animatedgallerycache", undefined);
        if(cachedImages) {
            console.log("Using cached images...");
            setImages(cachedImages);
        }

        window.fetching = true;
        //const {response: modelImages, error: imagesError} = usePollingArtApi(60 * 1000, "art", "q[]=model=" + model, "limit=" + limit, "sort=timestamp", "order=desc", "s=" + encodeURI(q));
        const modelImages = await artApiFetchAsync("art",
            "q[]=model=" + model,
            "limit=" + limit,
            "sort=timestamp",
            "order=desc",
            "s=" + encodeURI(q),
            "ultrafast=true");
        if("error" in modelImages) {
            console.log("Error fetching images: ", modelImages.error);
        } else {
            setValue("animatedgallerycache", modelImages);
            setImages(modelImages);
        }
        window.fetching = false;
    }

    useInterval(() => {
        async function fetchImages() {
            if(window.fetching) return;
            await fetchAndUpdateImages();
        }

        fetchImages();
    }, 10 * 60 * 1000, true);

    return (<div style={{
        position: "fixed",
        width: windowWidth,
        height: windowHeight,
        left: 0,
        top: 0,
        overflow: "hidden"}
    }>
        {chromeless ?
            <div ref={fullscreenRef} style={{
                position: "fixed",
                width: windowWidth,
                height: windowHeight,
                left: 0,
                top: 0,
                overflow: "hidden"
            }}>
                <ImageGrid images={images} width={windowWidth} height={windowHeight} shuffleTime={60}
                           onClickImage={() => {
                               toggleFullScreen()
                               return false;
                           }}
                />

                <button onClick={toggleFullScreen} style={{
                    zIndex: 10000,
                    position: 'absolute',
                    width: 50,
                    height: 50,
                    cursor: 'pointer'
                }} aria-label="Toggle Fullscreen">
                    <i className="fas fa-expand-arrows-alt"></i></button>
            </div> :

            <PageHeader image={randomImage} title={"Animated Gallery"}
                                            description={"View images in an animated gallery"}
                                            breadcrumb={[
                                                ["Home", "/"],
                                                ["Animated Gallery", "/animated-gallery"]
                                            ]}>
        <ImageGrid images={images} width={windowWidth} height={windowHeight} shuffleTime={15}/>
    </PageHeader>
}
</div>)
    ;
}

export default AnimatedGallery;