import React, {useEffect} from 'react';
import ImageGallery from "../components/ImageGallery";
import Footer from '../components/footer';
import useArtApi from "../../hooks/artapi";
import {Helmet} from "react-helmet";
import useWindowDimensions from "../../hooks/WindowDimensions";


const ImageGrid = () => {
    const [q, setQuery] = React.useState("");
    const [headerImage, setHeaderImage] = React.useState('/img/headers/tags.png');
    const {response: tags, error} = useArtApi("tags");
    // Get screen width
    const {height, width} = useWindowDimensions();
    const tag = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
    const [images, setImages] = React.useState([{
        src: "/img/midjourney-square.png",
        alt: "Waiting for images...",
        caption: "Waiting for images....",
        width: 500,
        height: 500
    }]);
    const [loading, setLoading] = React.useState(false);
    const query = new URLSearchParams(window.location.search);
    const columns = query.get("columns") ? query.get("columns") : Math.floor(width / 300);
    const limitStep = Math.floor(250 / columns);
    const [limit, setLimit] = React.useState(limitStep * 10);
    const segments = window.location.pathname.split('/').splice(2);
    console.log("Segments: ", segments);
    const id = segments[0];
    const index = segments[1];

    console.log("ID: ", id);

    const {response: modelImages, error: imagesError} =
        useArtApi("art", {limit: 1, id: id});

    useEffect(() => {
        function loadImages(modelImages) {
            const preppedImages = [];
            const ids = [];
            modelImages.map(image => {
                if( image.url.startsWith("https://media.discordapp.net/attachment")) {
                    image.url = image.url.replace("https://media.discordapp.net/attachment", "https://cdn.discordapp.com/attachments");
                }

                const imageData = {
                    src: image.url,
                    preview: image.preview ?? image.url,
                    alt: image.alt ?? image.parameters.prompt ?? "",
                    caption: (image.title?.toLowerCase() !== tag.toLowerCase()) ? image.title ?? "" : "",
                    title: image.title ?? "",
                    width: image.width ?? 500,
                    height: image.height ?? 500,
                    prompt: image.parameters.prompt ?? "",
                    id: image.id
                };

                const gridWidth = image.source.gridWidth;
                const gridHeight = image.source.gridHeight;
                var index = 0;

                for(var i = 0; i < gridWidth; i++) {
                    for(var j = 0; j < gridHeight; j++) {
                        var src = `https://api.aiart.doubtech.com/image?grid=${index}@${gridWidth}x${gridHeight}&url=${image.url}`;
                        console.log("SRC: ", src);
                        const id = {...imageData};
                        id.src = src;
                        id.preview = src;
                        // Replace id.id's trailing ::0 with the grid index
                        id.id = id.id.substring(0, id.id.lastIndexOf(':')) + ":" + index;
                        ids.push(image.id);
                        preppedImages.push(id);

                        index++;
                    }
                }
            });

            if(preppedImages.length > 0) {
                if(preppedImages[0].source && preppedImages[0].source.gridWidth > 0) {
                    var src = preppedImages[0].src;
                    var gridWidth = preppedImages[0].source.gridWidth;
                    var gridHeight = preppedImages[0].source.gridHeight;
                    src = "https://api.aiart.doubtech.com/image?grid=0@" + gridWidth + "x" + gridHeight + "&url=" + src;
                    setHeaderImage(src);
                } else {
                    setHeaderImage(preppedImages[0].src);
                }
            }

            setImages(preppedImages);
            setLoading(false);
        }

        if(modelImages && modelImages.length > 0) {
            loadImages(modelImages);
        }
    }, [modelImages]);

    return (
  <div>
      <Helmet>
          <meta property="og:title"
                content={`AI Art | Image | ${id.toUpperCase()}`}/>
          <meta property="og:description"
                content={"A collection of AI and manually generated CCG cards."}/>
          <meta property="og:image" content={headerImage}/>
          <title>{`AI Art | Tags | ${id.toUpperCase()}`}</title>
      </Helmet>

      <section className='container-fluid black_more'>
          <div className='row m-10-hor'>
              <div className='col-12'>
                <ImageGallery images={images} columns={2}/>
              </div>
          </div>
      </section>

    <Footer />

  </div>
);
}

export default ImageGrid;