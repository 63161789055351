import React, {useEffect} from 'react';
import {Card, Dropdown, DropdownButton, ListGroup} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import {
    artApiFetchAuthAsyncWithToast,
    artApiPostAuthAsync,
    artApiPostAuthAsyncWithToast,
    useAuthenticatedArtApi
} from "../../hooks/artapi";
import {toast} from "react-toastify";
import {useCookies} from "react-cookie";
import {MdSend} from "react-icons/md";
import {toastRequest, useEndpoint} from "../../hooks/api";


export default function MJJobSubmitter({onClick, getPrompt, selected, setSubmitCallback, onClickHeader=() =>{}, useApplyAspectRatio=true, useApplyVersion=true}) {
    const [ settingsExpanded, setSettingsExpanded ] = React.useState(false);
    const { response: channels, error: channelsError } = useAuthenticatedArtApi("midjourney-channels");
    const [channel, setChannel] = React.useState(null);
    const [aspectRatio, setAspectRatio] = React.useState(null);
    const [version, setVersion] = React.useState(null);
    const [savedState, setCookie, removeCookie] = useCookies(['mjjobsubmitter']);
    const {postAuth, postBody} = useEndpoint("jobs-v2/submit");

    useEffect(() => {
        if(savedState.channel) setChannel(savedState.channel);
        if(savedState.aspectRatio) setAspectRatio(savedState.aspectRatio);
        if(savedState.version) setVersion(savedState.version);
    }, ['savedState']);

    function applyChannel(channel) {
        setChannel(channel);
        setCookie('channel', channel);
    }

    function applyAspectRatio(aspectRatio) {
        setAspectRatio(aspectRatio);
        setCookie('aspectRatio', aspectRatio);
    }

    function applyVersion(version) {
        setVersion(version);
        setCookie('version', version);
    }


    if(setSubmitCallback) setSubmitCallback(submitPrompt);

    async function submitPrompt(prompt=undefined, showToast=true) {
        if(!prompt || prompt.length == 0) {
            toast("Please enter a prompt.", {autoClose: true});
            return;
        }
        if(useApplyAspectRatio && aspectRatio) prompt += ' ' + aspectRatio;
        if(useApplyVersion && version) prompt += ' ' + version;

        // If the prompt includes --width 1223 --height 3221 then convert those values into --ar
        const widthMatch = prompt.match(/--width (\d+)/);
        const heightMatch = prompt.match(/--height (\d+)/);
        if(widthMatch && heightMatch) {
            const width = parseInt(widthMatch[1]);
            const height = parseInt(heightMatch[1]);
            prompt = prompt.replace(/--width (\d+)/, "");
            prompt = prompt.replace(/--height (\d+)/, "");
            prompt += ` --ar ${width}:${height}`;
        }

        var uri = '/imagine?prompt=' + encodeURI(prompt);
        if(channel) {
            uri += '&channel=' + encodeURI(channel);
        }

        return await toastRequest(postAuth({
                "request": uri,
                "type": "midjourney"
            }),
            {
                startingMessage: `Submitting Midjourney Prompt to ${channel}...`,
                createSuccessMessage: r => `Submitted Midjourney job: ${prompt}`,
            });
    }

    async function submitPrompts(prompts=undefined, showToast=true) {
        if(!prompts || prompts.length == 0) {
            toast("Please enter a prompt.", {autoClose: true});
            return;
        }

        console.log(`Submitting ${prompts.length} prompts`);
        var uri = '/imagine?channel=' + encodeURI(channel);
        console.log("Channel: ", channel);
        console.log("URI: ", uri);
        if(showToast) {
            return await toastRequest(postBody({"prompts": prompts}, {
                "request": uri,
                "type": "midjourney"
            }),{
                startingMessage: `Submitting Midjourney Prompts to ${channel}...`,
                createSuccessMessage: r => `Submitted ${prompts.length} Midjourney jobs.`
            });
        } else {
            return await postBody({"prompts": prompts}, {
                "request": uri,
                "type": "midjourney"
            });
        }
    }

    function submitJob() {
        var prompt = getPrompt();
        if(!prompt || prompt.length == 0) {
            toast("Please enter a prompt.", {autoClose: true, error: true});
            return;
        }
        // if prompt is an array
        if(Array.isArray(prompt)) {
            submitPrompts(prompt, channel, false);
            if(prompt.length == 1) {
                toast(`Submitted Midjourney job: ${prompt[0]}`, {autoClose: true});
            } else {
                toast(`Submitted Midjourney ${prompt.length} jobs.`, {autoClose: true});
            }
        } else {
            submitPrompt(prompt);
        }
    }

    return (
        <Card>
            <Card.Header onClick={() => onClickHeader()}>
                <div className={"create-card-flex-between-container"}>
                    <span style={{cursor: "pointer", fontWeight: selected ? "bold" : "normal"}} onClick={() => {
                        setSettingsExpanded(!settingsExpanded);
                    }}>Midjourney</span>
                    <MdSend className={"mdicon"} onClick={() => onClick ? onClick() : submitJob()} />
                </div>
            </Card.Header>
            {settingsExpanded && <ListGroup variant="flush">
                <ListGroup.Item>
                    <h6>Channel</h6>{channel}
                    <DropdownButton id="dropdown-item-button" title={channel ?? "Default"}>
                        <Dropdown.Item as="button" onClick={() => applyChannel(null)}>Default</Dropdown.Item>
                        {channels && Object.entries(channels).map(([key, channel]) => (
                            <Dropdown.Item key={key} as="button" onClick={() => applyChannel(channel)}>{channel}</Dropdown.Item>
                        ))}
                    </DropdownButton>
                </ListGroup.Item>
                <ListGroup.Item>
                    <h6>Aspect Ratio</h6>
                    <DropdownButton id="dropdown-item-button" title={aspectRatio ?? "Default"}>
                        <Dropdown.Item as="button" onClick={() => applyAspectRatio(null)}>Default</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyAspectRatio("--ar 3:4")}>3:4</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyAspectRatio("--ar 4:3")}>4:3</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyAspectRatio("--ar 16:9")}>16:9</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyAspectRatio("--ar 21:9")}>21:9</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyAspectRatio("--ar 9:16")}>9:16</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyAspectRatio("--ar 1:2")}>1:2</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyAspectRatio("--ar 2:1")}>2:1</Dropdown.Item>
                    </DropdownButton>
                </ListGroup.Item>
                <ListGroup.Item>
                    <h6>Version</h6>
                    <DropdownButton id="dropdown-item-button" title={version ?? "Default"}>
                        <Dropdown.Item as="button" onClick={() => applyVersion(null)}>Default</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyVersion("--v 5")}>5</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyVersion("--v 4")}>4</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyVersion("--v 3")}>3</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyVersion("--v 2")}>2</Dropdown.Item>
                        <Dropdown.Item as="button" onClick={() => applyVersion("--v 1")}>1</Dropdown.Item>
                    </DropdownButton>
                </ListGroup.Item>
            </ListGroup>}
        </Card>)
}