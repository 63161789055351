import React, {useEffect} from 'react';
import {Table} from 'react-bootstrap';
import {artApiFetchAuthAsync} from "../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import PageHeader from "../components/PageHeader/pageheadercontrol";


const UsersPage = () => {
    const headerImage = '/img/headers/users.png'
    const {user, getAccessTokenSilently} = useAuth0();
    const [users, setUsers] = React.useState(null);

    useEffect(() => {
        async function fetchUsers() {
            const token = await getAccessTokenSilently();
            const response = await artApiFetchAuthAsync(token, "admin/list-users");
            setUsers(response);
            console.log("Users: ", response);
        }
        fetchUsers();
    }, []);

    return (<PageHeader image={headerImage} title={"Users"}
                        description={"Manage users and entitlements"}
                        breadcrumb={[
                            ["Home", "/"],
                            ["Admin", "/admin"],
                            ["Users", "/users"]
                        ]} >

        <section className='black_more'>
            <div className='container-fluid'>
                <div className='row m-10-hor'>
                    <div className='col-md-12'>
                        <Table striped bordered hover variant="dark">
                            <thead>
                            <tr>
                                <th></th>
                                <th>User</th>
                                <th>Display Name</th>
                                <th>Last Visited</th>
                                <th>Entitlements</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users && users.map((user, index) => (
                            <tr key={user.uid}>
                                <td><img width="75px" src={`https://api.aiart.doubtech.com/profile-image?user=${user.uid}`} alt={user.displayname} /></td>
                                <td>{user.uid}</td>
                                <td>{user.displayname}</td>
                                <td>{user.lastvisited}</td>
                                <td>
                                    <ul>
                                        {user.entitlements.map((entitlement, index) => (
                                            <li key={index}>{entitlement}</li>
                                        ))}
                                    </ul>
                                </td>
                            </tr>))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </section>
    </PageHeader>);
}

export default UsersPage;