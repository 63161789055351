import React, {useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {Tab} from "react-bootstrap";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import {useLoading} from "../../components/loading";
import {useError} from "../../components/error";
import {artApiFetchAsync, artApiFetchAuthAsync} from "../../../hooks/artapi";
import {IconButton} from "../../components/styledbutton";
import {MdAdd} from "react-icons/all";
import PageHeader from "../../components/PageHeader/pageheadercontrol";


const CCGDecks = () => {
    const headerImage = '/img/headers/ccg.png'
    const {user, isAuthenticated, getAccessTokenSilently} = useAuth0();
    const [collection, setCollection] = React.useState("mtg");
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const {loading, setLoading} = useLoading();
    const {error, setError} = useError();
    const [decks, setDecks] = React.useState([]);
    const navigate = useNavigate();

    console.log("Getting decks...")
    useEffect(() => {
        async function getCardData() {
            if(isAuthenticated) {
                const token = await getAccessTokenSilently();
                const loadedDecks = await artApiFetchAuthAsync(token, "ccg/decks/list");
                setDecks(loadedDecks);
            } else {
                const loadedDecks = await artApiFetchAsync("ccg/decks/list");
                setDecks(loadedDecks);
            }
        }

        if(collection) getCardData();
    }, [user, isAuthenticated]);

    function renderTab(eventKey, title, content) {
        return (
            <Tab eventKey={eventKey} title={title} style={{color: "#b29175"}}>
                <div  style={{height: windowHeight - 80 - 25, overflow: "auto", paddingTop: 8, paddingLeft: 8, paddingRight: 8}}>
                    {content}
                </div>
            </Tab>
        );
    }

    function renderDeck(deck) {
        console.log("Preview: " + deck);
        return (
        <div key={deck.id} onClick={() => {
            navigate("/ccg/deck/" + deck.id)
            }} style={{height: 256, backgroundColor: "#2c2c2c", overflow: "hidden"}}>
            <div className={"create-card-flex-between-container"}>
                <div className={"create-card-flex-start-grow"}/>
                <h4 style={{color: '#ffffff'}}>{deck.name}</h4>
                <div className={"create-card-flex-start-grow"}/>
            </div>
            <img src={deck.preview} width="100%"/>
        </div>);
    }

    const current = (
        <PageHeader image={headerImage} title={"Decks"}
                    description={"A collection of AI and manually generated decks of printable CCG cards."}
                    breadcrumb={[
                        ["Home", "/"],
                        ["CCG", "/ccg"],
                        ["Decks", "/ccg/decks"]
                    ]} >
            <div className={"create-card-flex-between-container"}>
                <div className={"create-card-flex-between-grow"}/>
                <IconButton icon={MdAdd} onClick={() => {
                    navigate("/ccg/deck")
                    }} />
            </div>
            <section>
                <div className={"img-grid"} style={{gridTemplateColumns: "1fr"}}>
                    {decks && decks.map((deck) => (renderDeck(deck)))}
                </div>
            </section>
        </PageHeader>
    )

    return current;
}

export default CCGDecks;