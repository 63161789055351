import React from 'react';
import {Alert} from "react-bootstrap";
import Uploader from "./ccg/progressbar";

const UploadBlob = ({types = ['application/json'], collection="/", label, onUploaded=null}) => {
    const [files, setFiles] = React.useState(null);
    const [error, setError] = React.useState("");
    const hiddenFileInput = React.useRef(null);

    const changeHandler = (e) => {
        let selected = [];
        console.log("Target: ", e.target.files);
        Array.from(e.target.files).forEach((file) => {
            if(file && types.includes(file.type)) {
                selected.push(file);
            }
        })
        console.log(selected);
        if(selected.length > 0) {
            setFiles(selected);
        } else {
            setFiles(null);
            console.log("Upload error: ", selected);
            setError("No valid file types in selection.")
        }
    }

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    return (
        <form className="m-3">
            {files && <Uploader files={files} setFiles={setFiles} collection={collection} onUploaded={onUploaded} />}

            <div className="btn" onClick={handleClick}>
                {!files && <input type="file" ref={hiddenFileInput} onChange={changeHandler} multiple style={{display: 'none'}} />}
                <span>{label}</span>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
        </form>
    )
}

export default UploadBlob;