import React, {useEffect} from 'react';
import useAgents from "../../../../pages/gpt/agenthook";
import {Dropdown, DropdownButton, Form} from "react-bootstrap";

function NamespaceDropdown({value, onChange}) {
    const { namespaces } = useAgents();
    const [newAgentNamespace, setNewAgentNamespace] = React.useState("");
    const [defaultValue, setDefaultValue] = React.useState(value);

    useEffect(() => {
        if(!value && namespaces && namespaces.length > 0) {
            setDefaultValue(namespaces[0].namespace);
        }
    }, [namespaces]);

    return (
        <Form.Group controlId="content">
            <Form.Label>Namespace</Form.Label>
            <Form.Control value={newAgentNamespace} onChange={(event) => {
                setNewAgentNamespace(event.target.value);
                setDefaultValue(event.target.value);
            }} />
            <DropdownButton id="dropdown-item-button" title={"Existing Namespaces"}
                            defaultValue={defaultValue}>
                {namespaces && namespaces.map((namespace, index) =>
                    <Dropdown.Item key={index} as="button"
                                   onClick={() => {
                        setNewAgentNamespace(namespace.namespace);
                        onChange(namespace.namespace);
                    }}>{namespace.namespace}</Dropdown.Item>
                )}
            </DropdownButton>
        </Form.Group>
    );
}

export default NamespaceDropdown;