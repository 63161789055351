import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Container, Form, Row, Spinner} from 'react-bootstrap';
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from 'react-icons/md';
import {AnimatePresence, motion} from 'framer-motion';
import ReactMarkdown from "react-markdown";
import {useCookies} from "react-cookie";
import {useLocalData} from "../../../hooks/artapi";
import {useIndexDB} from "../../providers/indexdb";

export function FloatingFormHeader({ children }) {
    return (
        <div className="floating-form-header">
            {children}
        </div>
    );
}

export const FieldHistoryItem = function({onClick, value}) {
    const style = {
        backgroundColor: '#202b34', // Slightly darker, bluish color
        borderRadius: '10px', // Rounded corners
        padding: '8px', // Some padding
        cursor: 'pointer', // Show the click icon when hovering
        color: '#ccc', // White text color
        margin: '0 auto', // Center the div
        textAlign: 'center', // Center the text
        marginBottom: '8px', // Add some space between items
    };

    return (
        <div style={style} onClick={onClick}>
            <div>{value[0]}</div>
            <div style={{
                textAlign: "left",
                fontSize: '0.8em',
                color: '#aaa',
                marginLeft: '8px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            }}>{value[1]}</div>
        </div>
    );
}

function FloatingForm({ label, height, onFormSubmit, outputValue, promptRows = 1,
                          closeOnSubmit = false, processingLabel = "Generating...",
                          isProcessing=false, children}) {
    const [inputValue, setInputValue] = useState('');
    const [collapsed, setCollapsed] = useState(true);

    // Get the window size
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const [savedState, setCookie, removeCookie] = useCookies(['floating-form-history']);
    const [history, setHistory] = useState([]);
    const [output, setOutput] = useState(outputValue);
    //const [data, saveData] = useLocalData('floating-form::' + label, undefined);
    const {getValue, setValue} = useIndexDB();
    const [data, setData] = useState(undefined);

    const header = React.Children.toArray(children).find(child => child.type === FloatingFormHeader);

    const DATA_KEY = 'floating-form::' + label;

    useEffect(() => {
        async function init() {
            const data = await getValue(DATA_KEY, undefined);
            if(data) {
                setData(data);
            }
        }
    }, []);

    useEffect(() => {
        setOutput(outputValue);
        if(outputValue == processingLabel) return;
        // Find the last history entry index with "Requesting..." in the output
        var lastRequestingIndex = -1;
        for(var i = history.length - 1; i >= 0; i--) {
            if(history[i][1] === processingLabel || !history[i][1] && history[i][0] === inputValue) {
                lastRequestingIndex = i;
                break;
            }
            if(history[i][1] === processingLabel) history[i][1] = "";
        }
        // If there is a history entry with "Requesting..." in the output, replace it with the new output
        if(lastRequestingIndex >= 0) {
            history[i][1] = outputValue;
            setHistory(history);
            saveCookie();
        }
    }, [outputValue]);

    useEffect(() => {}, [isProcessing]);

    useEffect(() => {
        console.log("data", data);
        if(data) {
            setInputValue(data.inputValue);
            setHistory(data.history ?? []);
            if(!output && data.outputValue) {
                setOutput(data.outputValue);
            }
        } else {
            setHistory([]);
            setInputValue('');
            setOutput('');
            setCollapsed(collapsed);
        }
    }, [data]);

    const saveCookie = () => {
        const data = {
            collapsed: collapsed,
            inputValue: inputValue,
            outputValue: output,
            history: history};
        setValue(DATA_KEY, data);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
        saveCookie();
    };

    const handleSubmit = (event) => {
        onFormSubmit(inputValue);
        var item = [inputValue, '']
        history.push(item);
        setHistory(history);
        if (closeOnSubmit) {
            setCollapsed(true);
        }
        saveCookie();
    };

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
        saveCookie();
    };

    const arrowIcon = collapsed ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />;

    const cardVariants = {
        collapsed: { height: '50px' },
        expanded: { height },
    };

    return (
        <Container fluid className="fixed-bottom">
            <AnimatePresence>
                <motion.div
                    key="card"
                    initial="expanded"
                    animate={collapsed ? 'collapsed' : 'expanded'}
                    exit="collapsed"
                    variants={cardVariants}
                    transition={{ duration: 0.3 }}
                >
                    <Card style={{ overflow: 'hidden', height: height }}>
                        <Card.Header onClick={toggleCollapse}>
                            <div className={"create-card-flex-between-container"}>
                                <div className={"create-card-flex-between-items-grow"}>
                                {label}
                                </div>
                                <div>
                                    {!collapsed && header}
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <div className={"create-card-flex-between-container"}>
                                <div className={"create-card-flex-between-items-grow"} style={{maxWidth: windowWidth * 2/3.0}}>
                                    <Form.Group as={Row}>
                                        <Col xs={9}>
                                            <Form.Control
                                                as="textarea"
                                                rows={promptRows}
                                                placeholder="Enter some text"
                                                value={inputValue}
                                                onChange={handleInputChange}
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <Button variant="primary" type="submit" onClick={handleSubmit}>
                                                Submit
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                    <div className="output-panel" style={{overflow: "auto", marginRight: 16, maxHeight: height - 125}} >
                                        {isProcessing ?
                                            <Spinner animation="border" variant="primary" title={processingLabel} /> :
                                            <ReactMarkdown children={output} />
                                        }
                                    </div>
                                </div>
                                <div style={{width: windowWidth / 3}}>
                                    <h5>History</h5>
                                    <div style={{overflow: "auto", maxHeight: height - 150}}>
                                    {history?.length ? [...history].reverse().map((item, index) => (<div key={index}>
                                        <FieldHistoryItem value={item} onClick={() => {
                                            setInputValue(item[0]);
                                            setOutput(item[1])
                                        }}/>
                                    </div>)) : "No history"}
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </motion.div>
            </AnimatePresence>
        </Container>
    );
}

export default FloatingForm;
