// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getFirestore, serverTimestamp} from "firebase/firestore";
import {getStorage} from "firebase/storage";
import {getAuth, signInWithEmailAndPassword} from 'firebase/auth'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCNNzo2d6A_VUqOoePuGMX4kwnElplgwgA",
    authDomain: "ccg-builder.firebaseapp.com",
    projectId: "ccg-builder",
    storageBucket: "ccg-builder.appspot.com",
    messagingSenderId: "807851268807",
    appId: "1:807851268807:web:ba9f7c84469c7112b1fce2",
    measurementId: "G-K75GHT829S"
};

// Initialize Firebase
const ccgapp = initializeApp(firebaseConfig, 'ccg');
const analytics = getAnalytics(ccgapp);
const ccgFirestore = getFirestore(ccgapp);
const ccgStorage = getStorage(ccgapp);
const timestamp = serverTimestamp;
const auth = getAuth(ccgapp);

signInWithEmailAndPassword(auth, 'firebasejobqueue@doubtech.com', firebaseConfig.apiKey).then((userCredential) => {}).catch((error) => {
    console.error(error);
});

export { ccgFirestore, ccgStorage, timestamp }