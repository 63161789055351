import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
const TermsOfServiceModal = ({ show, handleClose }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Terms of Service</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>By creating an API key, you acknowledge that the key is only visible and accessible to you,
                the creator of the key. You may use this key for the services provided by our website.
                However, please note that the source token associated with this key will not be shared or exposed.</p>

                <p>Should you choose to create a sharable api key for one of our services, the use of that key may use your provided api key on the backend, but your service API key will not be shared in the process.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    I Agree
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default TermsOfServiceModal;