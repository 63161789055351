import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import { Button, Card } from 'react-bootstrap';
import { JsonEditor } from 'jsoneditor-react18';
import {MdContentCopy, MdDelete, MdRefresh} from 'react-icons/md';
import { toast } from 'react-toastify';
import Message from './message';

const isJson = (str) => {
    try {
        JSON.parse(str);
        return true;
    } catch {
        return false;
    }
};

const ChatBubble = ({ message, user, onResend = (message) => {}, onDelete = (message) => {} }) => {
    const { content, sentTime, sender, direction, error, resetHistory } = message;

    const [contextMenu, setContextMenu] = useState(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (contextMenu !== null) {
                setContextMenu(null);
            }
        };

        if (contextMenu !== null) {
            window.addEventListener('click', handleClickOutside);
        } else {
            window.removeEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [contextMenu]);

    const containerStyle = {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
        width: '100%',
        justifyContent: direction === 'incoming' ? 'flex-start' : 'flex-end',
    };

    const innerContainerStyle = {
        display: 'flex',
        alignItems: 'flex-end',
        width: '100%',
        flexDirection: direction === 'incoming' ? 'row' : 'row-reverse',
    };

    const cardStyle = {
        borderRadius: '20px',
        width: isJson(content) ? '75%' : 'fit-content',
    };

    const senderInfoStyle = {
        fontSize: '0.8em',
        marginTop: '15px',
        marginBottom: '-10px',
        textAlign: direction === 'incoming' ? 'left' : 'right',
    };

    const formatSentTime = (sentTime) => {
        const currentDate = new Date();
        const sentDate = new Date(sentTime);

        const differenceInMinutes = Math.floor((currentDate - sentDate) / (1000 * 60));

        if (differenceInMinutes < 60) {
            return `${differenceInMinutes} minutes ago`;
        } else {
            return sentDate.toLocaleString();
        }
    };

    const handleCopyToClipboard = () => {
        const textToCopy = isJson(content) ? JSON.stringify(JSON.parse(content), null, 4) : content;
        navigator.clipboard.writeText(textToCopy);
        toast.success('Copied to clipboard!');
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX,
                    mouseY: event.clientY,
                }
                : null,
        );
    };

    const handleCloseContextMenu = () => {
        setContextMenu(null);
    };

    const handleResend = () => {
        onResend(message);
        handleCloseContextMenu();
    };

    const handleDelete = () => {
        onDelete(message);
        handleCloseContextMenu();
    };

    return (
        <div style={containerStyle} onContextMenu={handleContextMenu}>
            <div style={innerContainerStyle}>
                <Avatar src={user.avatar} alt={sender} style={{ marginRight: '10px' }} />
                <Card style={cardStyle}>
                    <Card.Body>
                        {!content && resetHistory ? (
                            'Chat history reset'
                        ) : (
                            <Message content={content} />
                        )}

                        {error && <div style={{ color: 'red', fontSize: 8 }}>{error}</div>}
                        <div style={senderInfoStyle} className={'create-card-flex-between-container'}>
                            {direction === 'incoming' ? (
                                <>
                                    {sender} - {formatSentTime(sentTime)}
                                    <span className={'create-card-flex-between-items-grow'} />
                                    <MdContentCopy className={'mdicon'} onClick={handleCopyToClipboard} style={{ marginLeft: 32, marginTop: 0, fontSize: 14 }} />
                                </>
                            ) : (
                                <>
                                    {content && (
                                        <MdContentCopy className={'mdicon'} onClick={handleCopyToClipboard} style={{ marginRight: 32, marginTop: 0, fontSize: 14 }} />
                                    )}
                                    <span className={'create-card-flex-between-items-grow'} />
                                    {sender} - {formatSentTime(sentTime)}
                                </>
                            )}
                        </div>
                    </Card.Body>
                </Card>
            </div>
            {contextMenu !== null && (
                <div
                    style={{
                        position: 'fixed',
                        top: contextMenu.mouseY,
                        left: contextMenu.mouseX,
                        backgroundColor: '#333',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '5px',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.5)',
                        zIndex: 1000,
                    }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <ul style={{listStyle: 'none', margin: 0, padding: 0}}>
                        <li style={{padding: '5px 0', cursor: 'pointer'}} onClick={handleCopyToClipboard}>
                            <MdContentCopy className={'mdicon'}/> Copy
                        </li>
                        <li style={{padding: '5px 0', cursor: 'pointer'}} onClick={handleResend}>
                            <MdRefresh className={'mdicon'}/> Resend
                        </li>
                        <li style={{padding: '5px 0', cursor: 'pointer'}} onClick={handleDelete}>
                            <MdDelete className={'mdicon'}/> Delete
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ChatBubble;
