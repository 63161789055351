import React from 'react';
import {CollapsibleCard, CollapsibleCardContent} from "../CollapsableCard";

function CategorizedSet({ dataset, onDrawData }) {
    const [groupedDataSets, setGroupedDataSets] = React.useState({});

    React.useEffect(() => {
        const groupedDataSets = {};
        if(dataset) {
            Object.values(dataset).forEach((d) => {
                const category = d.category ?? "Uncategorized";
                if(!groupedDataSets[category]) {
                    groupedDataSets[category] = {};
                }
                groupedDataSets[category][d.name] = d;
            });
        }
        setGroupedDataSets(groupedDataSets);
    }, [dataset]);

    return <>
        {Object.keys(groupedDataSets).map((groupName, index) => {
            const group = groupedDataSets[groupName];
            return <CollapsibleCard title={groupName} key={index}>
                <CollapsibleCardContent>
                    {Object.keys(group).map((dataSet, index) => {
                        return <div key={index}>{onDrawData(group[dataSet])}</div>;
                    })}
                </CollapsibleCardContent>
            </CollapsibleCard>
        })}
    </>
}

export default CategorizedSet;

