import React, {useEffect} from 'react';
import Footer from '../components/footer';
import {Unity, useUnityContext} from "react-unity-webgl";
import useWindowDimensions from "../../hooks/WindowDimensions";
//import Slider from 'react-animated-slider';
import {useAuth0} from "@auth0/auth0-react";
import {ProgressBar} from "react-bootstrap";
import {Helmet} from "react-helmet";


export default () => {
  const headerImage =  'img/headers/webgl.jpg';

  /*
            <Unity
                unityProvider={unityProvider}
                style={{ display: isLoaded ? "block" : "none" }}
            />
   */

  const {height, width} = useWindowDimensions();
  const glheight = height;
  const { getAccessTokenSilently, user } = useAuth0();


  const content = [
    {
      title: "WebGL Gallery",
      description:
          "The WebGL gallery is loading. It should get started soon.",
      button: "SEE MORE",
      link: "/model/midjourney",
      image: "/img/header/webg.jpg"
    }
  ];

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
    requestFullscreen,
    takeScreenshot,
    unload,
  } = useUnityContext({
    loaderUrl: "/Build/AiArt.loader.js",
    dataUrl: "/Build/AiArt.data.gz",
    frameworkUrl: "/Build/AiArt.framework.js.gz",
    codeUrl: "/Build/AiArt.wasm.gz",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    }
  });

  addEventListener("Login", sendToken);

  useEffect(() => {
    console.log("Sending token: ", user);
    sendToken();
  }, [user, isLoaded]);

  async function sendToken()
  {
    function until(conditionFunction) {

      const poll = resolve => {
        if(conditionFunction()) resolve();
        else setTimeout(_ => poll(resolve), 400);
      }

      return new Promise(poll);
    }

    const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
    await until(() => user && isLoaded);
    console.log("Sending token: ", token);
    sendMessage("WebGLAuth", "SetToken", token);
    sendMessage("WebGLAuth", "SetEmail", user?.email);
    sendMessage("WebGLAuth", "SetDisplayName", user?.displayName);
    sendMessage("WebGLAuth", "SetUserPicture", user?.picture);
    sendMessage("WebGLAuth", "OnLogin")
  }

  return (<div className='wraperitem'>
        <Helmet>
          <meta property="og:title"
                content={"AI Art | WebGL Gallery"}/>
          <meta property="og:description"
                content={"A 3d art gallery made with Unity"}/>
          <meta property="og:image" content={headerImage}/>
          <title>{"AI Art | WebGL Gallery"}</title>
        </Helmet>
        {!isLoaded && <div className="slider-wrapper" autoplay={6000}>
          <div
              className="slider-content"
              style={{ background: `url('${headerImage}') no-repeat center center` }}
          >
            <div className="inner">
              <h1>WebGL Gallery</h1>
              <p>The WebGL gallery is loading.</p>
              <ProgressBar now={loadingProgression * 100} />
            </div>
          </div>
        </div>}
        <section className='container-fluid p-0'>
          <div className='row'>
                <Unity
                    unityProvider={unityProvider}
                    className="imgslickz"
                    style={{maxWidth: 2000, maxHeight: glheight, padding: 10, margin: 10}}
                />

          </div>
        </section>

        <Footer />
      </div>
  )
}
