import React, {useEffect, useRef, useState} from 'react';
import useWindowDimensions from "../../../hooks/WindowDimensions";
import {getLayerData, getTemplateData, useCCGData} from "../../../hooks/ccg/ccgdata";
import drawCard from "./carddrawer";
import {useAuth0} from "@auth0/auth0-react";

const CCGTemplate = ({exposedMethods, collection, cardData = null, onDrawCard = undefined, width=undefined, height=undefined}) => {
    const {user, getAccessTokenSilently} = useAuth0();
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const cardCanvas = useRef();
    const [imageCount, setImageCount] = useState(0);
    const [template, setTemplate] = useState(null);
    const [card, setCard] = useState(null);
    const [templates, setTemplates] = React.useState({});
    const [layers, setLayers] = React.useState({});
    const [redrawIteration, setRedrawIteration] = useState(0);

    if(width && height) {
        // do nothing.
    } else if(width) {
        height = width * 3.5/2.5;
    } else if(height) {
        width = height * 2.5 / 3.5;
    } else {
        height = windowHeight * .75;
        width = height * (2.5 / 3.5);
    }
    useEffect(() => {
        async function getCardData() {
            const token = await getAccessTokenSilently();

            const loadedTemplates = await getTemplateData(token, collection);
            setTemplates(loadedTemplates);

            const loadedLayers = await getLayerData(token, collection);
            setLayers(loadedLayers);
        }

        if(collection) getCardData();
    }, [collection]);

    async function redraw(cardData, w = -1, h = -1) {
        if(!cardData) {
            console.error("No card data provided.");
            return cardCanvas.current;
        }

        if(!layers) {
            console.error("No layers loaded.");
            return cardCanvas.current;
        }

        if(!templates) {
            console.error("No templates loaded.");
            return cardCanvas.current;
        }

        const card = {
            template: cardData.template,
            collection: cardData.collection,
            properties: cardData.properties,
            variables: cardData.variables,
            image: cardData.image,
            fonts: {},
            selfRedraw: function () {
                //drawCard(cardCanvas, card, w === -1 ? width : w, h === -1 ? height : h);
                setRedrawIteration(redrawIteration + 1);
            }
        };
        setCard(card);

        if(!card) {
            console.log("Card not ready yet.", card);
            return cardCanvas.current;
        }

        await drawCard(cardCanvas, card, w === -1 ? width : w, h === -1 ? height : h);
        onDrawCard && onDrawCard(cardCanvas.current);

        return cardCanvas.current;
    }

    exposedMethods && (exposedMethods.redraw = redraw);
    exposedMethods && (exposedMethods.getCanvas = () => cardCanvas.current);

    useEffect(() => {
        if(cardData) {
            redraw(cardData);
        }
    },[redrawIteration]);

    useEffect(() => {
        console.log("redraw from card data, height, or width change.");
        redraw(cardData);
    }, [cardData, width, height]);

    return (
        <div style={{width: width, height: height}}>
            <canvas id='card-canvas' ref={cardCanvas} width={width} height={height}/>
            <canvas id='mask-canvas' width={width} height={height} style={{display: "none"}}/>
        </div>
    );
}

export default CCGTemplate;