import React, {useEffect, useState} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {getPreviewUrl} from "./ImageData";
import {openDB} from 'idb';
import {toast} from "react-toastify";
import useInterval from "../components/components/interval";
import {useEndpoint, useToken} from "./api";

export function artApiFetch(endpoint, ...params) {
    return startSession().then((session) => {
        const uri = `https://api.aiart.doubtech.com/${endpoint}?session=${session}&${params.join("&")}`;
        return fetch(uri,
            {
                mode: 'cors',
                method: 'GET',
                cache: "no-cache"
            })
            .then((result) => {
                try {
                    return result.json();
                } catch (e) {
                    console.log(e, result);
                }
            });
    });
}

export async function artApiFetchAsync(endpoint, ...params) {
    const session = await startSession();
    const uri = `https://api.aiart.doubtech.com/${endpoint}?session=${session}&${params.join("&")}`;
console.log("URI: ", uri);
    // Check if caches API is available
    if ('caches' in window) {
        const cache = await caches.open('art-api-cache');
        const cachedResponse = await cache.match(uri);

        // If a cached response is available, return it immediately.
        if (cachedResponse) {
            fetchAndUpdateCache(uri, cache);  // Fetch new data in the background
            return cachedResponse.json();     // Return cached data immediately
        }
    }

    // If no cached response, fetch the data normally.
    const result = await fetch(uri, {
        mode: 'cors',
        method: "GET",
        cache: "no-cache"
    });

    try {
        return result.json();
    } catch (e) {
        console.log(e, result);
    }
}

// Helper function to fetch new data and update the cache
async function fetchAndUpdateCache(uri, cache) {
    const response = await fetch(uri, {
        mode: 'cors',
        method: "GET",
        cache: "no-cache"
    });
    cache.put(uri, response.clone());  // Update the cache with the new response
}


const defaultOptions = {
    processingText: "Processing...",
    defaultSuccessText: "Success!",
    defaultErrorText: undefined
};

export async function artApiFetchAuthAsyncWithToast(token, endpoint,
                                                    options = {}, ...params) {
    options = {...defaultOptions, ...options};
    if(!options.createErrorMessage) {
        options.createErrorMessage = (response) => options.defaultErrorText ?? "Error: " + response.error;
    }
    if(!options.createSuccessMessage) {
        options.createSuccessMessage = (response) => options.defaultSuccessText;
    }
    const generation = toast(options.processingText, {type: "info"});
    toast.update(generation, {autoClose: false, closeButton: true, closeOnClick: false, draggable: false, hideProgressBar: true, pauseOnHover: false, progress: undefined, type: "info"});
    const messageResponse = await artApiFetchAuthAsync(token, endpoint, ...params);
    if("error" in messageResponse)
    {
        toast.update(generation, {autoClose: 3000, closeButton: true, closeOnClick: true, draggable: true, hideProgressBar: false, pauseOnHover: true, progress: undefined, type: "error", render: options.createErrorMessage(messageResponse)});
        return null;
    }
    var successText = options.createSuccessMessage(messageResponse.content);

    toast.update(generation, {
        render: successText,
        autoClose: 3000,
        closeButton: true,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        type: "success"});
    return messageResponse.content;
}

export async function artApiPostAuthAsyncWithToast(token, endpoint, body,
                                                    options = {}, ...params) {
    options = {...defaultOptions, ...options};
    if(!options.createErrorMessage) {
        options.createErrorMessage = (response) => options.defaultErrorText ?? "Error: " + response.error;
    }
    if(!options.createSuccessMessage) {
        options.createSuccessMessage = (response) => options.defaultSuccessText;
    }
    const generation = toast(options.processingText, {type: "info"});
    toast.update(generation, {autoClose: false, closeButton: true, closeOnClick: false, draggable: false, hideProgressBar: true, pauseOnHover: false, progress: undefined, type: "info"});
    const messageResponse = await artApiPostAuthAsync(token, endpoint, body, ...params);
    if("error" in messageResponse)
    {
        toast.update(generation, {autoClose: 3000, closeButton: true, closeOnClick: true, draggable: true, hideProgressBar: false, pauseOnHover: true, progress: undefined, type: "error", render: options.createErrorMessage(messageResponse)});
        return null;
    }
    var successText = options.createSuccessMessage(messageResponse.content);

    toast.update(generation, {
        render: successText,
        autoClose: 3000,
        closeButton: true,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        type: "success"});
    return messageResponse.content;
}

export async function artApiPostAsyncWithToast(endpoint, body,
                                                   options = {}, ...params) {
    options = {...defaultOptions, ...options};
    if(!options.createErrorMessage) {
        options.createErrorMessage = (response) => options.defaultErrorText ?? "Error: " + response.error;
    }
    if(!options.createSuccessMessage) {
        options.createSuccessMessage = (response) => options.defaultSuccessText;
    }
    const generation = toast(options.processingText, {type: "info"});
    toast.update(generation, {autoClose: false, closeButton: true, closeOnClick: false, draggable: false, hideProgressBar: true, pauseOnHover: false, progress: undefined, type: "info"});
    const messageResponse = await artApiPostAsync(endpoint, body, ...params);
    if("error" in messageResponse)
    {
        toast.update(generation, {autoClose: 3000, closeButton: true, closeOnClick: true, draggable: true, hideProgressBar: false, pauseOnHover: true, progress: undefined, type: "error", render: options.createErrorMessage(messageResponse)});
        return null;
    }
    var successText = options.createSuccessMessage(messageResponse.content);

    toast.update(generation, {
        render: successText,
        autoClose: 3000,
        closeButton: true,
        closeOnClick: true,
        draggable: true,
        hideProgressBar: false,
        pauseOnHover: true,
        progress: undefined,
        type: "success"});
    return messageResponse.content;
}

export async function artApiFetchAuthAsync(token, endpoint, ...params) {
    const session = await startSession();
    const uri = `https://api.aiart.doubtech.com/${endpoint}?session=${session}&${params.join("&")}`;

    try {
        const result = await fetch(uri,
            {
                mode: 'cors',
                method: "GET",
                cache: "no-cache",
                Authorization: `Bearer ${token}`
            });
        try {
            return await result.json();
        } catch (e) {
            console.error(e, result);
            return {"error": e.toString()}
        }
    } catch (e) {
        console.error(e);
        return {"error": e.toString()}
    }
}

export async function artApiPostAsync(endpoint, body, ...params) {
    const session = await startSession();
    const uri = `https://api.aiart.doubtech.com/${endpoint}?session=${session}&${params.join("&")}`;
    try {
        await startSession();

        // If the body is an object convert it to a json string
        if(typeof body === "object") {
            body = JSON.stringify(body);
        }

        const result = await fetch(uri,
            {
                mode: 'cors',
                method: "POST",
                cache: "no-cache",
                body: body
            });
        try {
            return await result.json();
        } catch (e) {
            console.error(e, result);
            return {"error": "Failed to parse response"}
        }
    } catch (e) {
        console.error(e)
        return {"error": e.toString()}
    }
}

export async function artApiPostAuthAsync(token, endpoint, body, ...params) {
    const session = await startSession();
    const uri = `https://api.aiart.doubtech.com/${endpoint}?session=${session}&token=${token}&${params.join("&")}`;
    try {
        await startSession();

        // If the body is an object convert it to a json string
        if(typeof body === "object") {
            body = JSON.stringify(body);
        }

        const result = await fetch(uri,
            {
                mode: 'cors',
                method: "POST",
                cache: "no-cache",
                body: body,
                Authorization: `Bearer ${token}`
            });
        try {
            return result.json();
        } catch (e) {
            console.error(e, result);
            return {"error": "Failed to parse response"}
        }
    } catch (e) {
        console.error(e)
        return {"error": e.toString()}
    }
}

function getCookieVariable(variableName) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(variableName + '=')) {
            return decodeURIComponent(cookie.substring(variableName.length + 1));
        }
    }
    return null;
}

function setCookieVariable(variableName, variableValue, expirationDays) {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + expirationDays);
    const cookieValue = encodeURIComponent(variableValue) + '; expires=' + expirationDate.toUTCString() + '; path=/';
    document.cookie = variableName + '=' + cookieValue;
}

export async function startSession() {
    var session = getCookieVariable("api.aiart.doubtech.com::session");


    if(!session) {
        const response = await fetch("https://api.aiart.doubtech.com/start-session");
        const body = await response.json();
        window.aaasession = body.session;
        setCookieVariable("api.aiart.doubtech.com::session", body.session, 7);
    }
    window.aaasession = session;
    return session;
}

export const useAuthenticatedArtApi = (endpoint, params={}) => {
    const [response, setResponse] = useState(undefined);
    const [error, setError] = useState(null);
    const {fetchAuth, postAuth} = useEndpoint(endpoint);

    useEffect(() => {
        try {
            async function fetchProtected() {
                const response = await fetchAuth(params);
                if ('error' in response) {
                    setError(response.error);
                }
                setResponse(response);
            }
            fetchProtected();
        } catch (error) {
            setError(error);
        }
    }, [endpoint])
    return {response, error};
};

const useArtApi = (endpoint, params={}) => {
    const {user} = useToken();
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const {fetch, postAuth} = useEndpoint(endpoint);

    useEffect(() => {
        if(endpoint?.length > 0) {
            fetch(params)
                .then((r) => {
                    setResponse(r);
                    if("error" in r) {
                        setError(r['error'])
                    } else {
                        setError(null);
                    }
                })
                .catch(setError);
        }
    }, [endpoint, user?.email]);

    return { response, error };
}

export function prepImages(modelImages) {
    const preppedImages = [];
    modelImages.map(image => {
        if(!image.url) return;

        if( image.url.startsWith("https://media.discordapp.net/attachment")) {
            image.url = image.url.replace("https://media.discordapp.net/attachment", "https://cdn.discordapp.com/attachments");
        }

        const imageData = {
            src: image.url,
            preview: image.preview ?? image.url,
            alt: image.alt ?? image.parameters.prompt ?? "",
            caption: image.title ?? "",
            width: image.width ?? 500,
            height: image.height ?? 500,
            prompt: image.parameters.prompt ?? "",
            id: image.id,
            source: image.source
        };

        image.preview = getPreviewUrl(image);
        preppedImages.push(imageData);
    });

    return preppedImages;
}

export const usePollingArtApi = (pollRate, endpoint, params = {}) => {
    const {user} = useAuth0();
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const {fetch, postAuth} = useEndpoint(endpoint);

    function fetchArt() {
        fetch({...params, user: user?.email})
            .then((r) => {
                setResponse(r);
                setError(null);
            })
            .catch(setError);
    }

    useEffect(() => {
        fetchArt();
    }, [endpoint, user?.email]);

    useInterval(fetchArt, pollRate);

    return { response, error };
}

export const useArtApiWithRefresh = (refreshKey, endpoint, ...params) => {
    const {user} = useAuth0();
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const uri = `https://api.aiart.doubtech.com/${endpoint}?session=${window.aaasession}&${params.join("&")}`;
    useEffect(() => {
        artApiFetch(endpoint, ...params, `user=${user?.email}`)
            .then((r) => {
                setResponse(r);
                setError(null);
            })
            .catch(setError);
    }, [endpoint,uri,user?.email, refreshKey]);

    return { response, error };
}

export const useLocalData = (dataStoreKey, defaultValue) => {
    const [data, setData] = useState(defaultValue);

    const dbPromise = openDB('doubtech.ai::' + dataStoreKey, 2, {
        upgrade(db) {
            if (!db.objectStoreNames.contains('data')) {
                db.createObjectStore('data');
            }
        },
    });

    async function load() {
        try {
            if (data) return data;

            const db = await dbPromise;
            if (!db.objectStoreNames.contains('data')) {
                console.error(`Data store ${'data'} does not exist`);
                return;
            }

            const tx = db.transaction('data', 'readonly');
            const store = tx.objectStore('data');
            console.log("Store: ", store);
            const val = await store.get('data');
            console.log("Loaded data: ", val);
            setData(val)
            return val;
        } catch (e) {
            console.error(e);
            return data;
        }
    }

    async function saveAsync(key, val) {
        const db = await dbPromise;
        const tx = db.transaction('data', 'readwrite');
        const store = tx.objectStore('data');
        store.put(val, key);
        await tx.done;
    }

    async function getValue(key, defaultValue) {
        const val = await load();
        console.log("Getting data: ", val, key, defaultValue, data);
        if(val) {
            return val[key] ?? defaultValue;
        }
        return defaultValue;
    }

    async function setValue(key, value) {
        setData(prevState => ({ ...prevState, [key]: value }));
        await saveAsync(key, value);
    }

    return { getValue, setValue };
}


export default useArtApi;