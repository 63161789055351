import React, {useEffect} from 'react';
import {Card as BootstrapCard, OverlayTrigger, Tooltip} from 'react-bootstrap';
import SetTag from "./SetTag";
import {useNavigate} from "react-router-dom";

function SetCard({ data }) {
    const navigate = useNavigate();
    function clickCard() {
        navigate("/set/" + data.name)
    }
    const renderTooltip = (props) => (
        <Tooltip {...props}>{data.prompt}</Tooltip>
    );

    const renderTagsTooltip = (props) => (
        <Tooltip {...props}>{data.tags.join(", ")}</Tooltip>
    );
    return (
        <BootstrapCard style={{ width: '18rem', marginBottom: '20px', breakInside: "avoid" }} onClick={clickCard}>
            <BootstrapCard.Img variant="top" src={data.url}
                               style={{height: '300px', objectFit: 'cover'}} />
            <BootstrapCard.Body>
                <BootstrapCard.Title>{data.name}</BootstrapCard.Title>
                <BootstrapCard.Text className={"ellipsis"}>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <span>{data.prompt}</span>
                    </OverlayTrigger>
                </BootstrapCard.Text>
                <div className={"ellipsis"}>
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTagsTooltip}
                    ><span>{data.tags.map(tag => (
                        <SetTag key={tag} label={tag} />
                    ))}</span></OverlayTrigger>
                </div>
            </BootstrapCard.Body>
        </BootstrapCard>
    );
}

export default SetCard;