import React, {useEffect} from 'react';
import ImageGallery from "../components/ImageGallery";
import Footer from '../components/footer';
import {getPreviewUrl} from "../../hooks/ImageData";
import {Form, InputGroup, ProgressBar} from "react-bootstrap";
import useArtApi, {usePollingArtApi} from "../../hooks/artapi";
import {Link, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import useWindowDimensions from "../../hooks/WindowDimensions";
import TagList from "../components/taglist";
import TagEditor from "../tageditor";
import {MdEdit} from "react-icons/md";
import ArtGallery from "../components/artgallery/ArtGallery";
import ArtPage from "../components/artgallery/ArtPage";


const TagGallery = () => {
    // Get screen width
    const [images, setImages] = React.useState([{
        src: "/img/midjourney-square.png",
        alt: "Waiting for images...",
        caption: "Waiting for images....",
        width: 500,
        height: 500
    }]);
    const tagNames = window.location.pathname.split('/')
        .map(s => decodeURI(s))
        .filter(segment => segment !== '').slice(1);
    const [showTagEditor, setShowTagEditor] = React.useState(false);
    const [childTagList, setChildTagList] = React.useState([]);
    const navigate = useNavigate();
    const [lastTags, setLastTags] = React.useState("");

    function refreshTags(images) {
        const tags = images.flatMap(image => image.tags);
        // Sort unique tags
        tags.sort((a, b) => a.localeCompare(b));
        const tagStr = tags.join(',');
        if (tagStr === lastTags) return;
        setLastTags(tagStr);
        // Remove duplicates and
        const tagSet = {}
        // Restructure tags to this strcture [{name: tag, count: 1}, ...]
        tags.forEach(tag => {
            if (tagSet[tag]) {
                tagSet[tag].count++;
            } else {
                tagSet[tag] = {name: tag.trim(), count: 1};
            }
        });
        setChildTagList(Object.values(tagSet));
    }

    return (<ArtPage bigHeader={false} allowUltrafast={false} refreshRate={600} fields={{tags: tagNames}}
                     title={tagNames ? tagNames.join(' | ') : ''}
                     contentDescription={`AI Art tagged with ${tagNames ? tagNames.join(' | ') : ''}.`}
                     onImagesUpdated={refreshTags}
                     breadcrumbs={tagNames ? tagNames.map((tag, index) => {
                         const path = tagNames.slice(0, index + 1).join('/');
                         return [tag, `/tags/${path}`];
                     }) : []}
        >
            {childTagList?.length > 0 && <div className='col-12' style={{textAlign: "justify"}}>
                <TagList className={"mt-5"} tags={childTagList}/>
                <MdEdit onClick={() => setShowTagEditor(!showTagEditor)} style={{cursor: "pointer"}}/>
            </div>}

            {showTagEditor && <TagEditor images={images}/>}
        </ArtPage>
    );
}

export default TagGallery;