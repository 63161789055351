// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBO4hX_QNbBpj28SknyRJGFPEk5NhIvFMs",
    authDomain: "stable-diffusion-database.firebaseapp.com",
    databaseURL: "https://stable-diffusion-database-default-rtdb.firebaseio.com",
    projectId: "stable-diffusion-database",
    storageBucket: "stable-diffusion-database.appspot.com",
    messagingSenderId: "485296813383",
    appId: "1:485296813383:web:0200363de365587866c862"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export { app }