import React from 'react';

const DeckPreview = ({deck, previewWidth, previewHeight, onRedrawn, childCount}) => {
    const deckCanvas = React.useRef(null);
    const headingImage = React.useRef();

    const [state, setState] = React.useState("");

    React.useEffect(() => {
        console.log("Redrawing deck preview..." + childCount);
        setState(deck.toString());
        renderDeckCanvas(deck.cards);
    }, [childCount]);

    function renderDeckCanvas(cards) {
        if(!deckCanvas.current) return;
        if(cards.length === 0) return;

        const ctx = deckCanvas.current.getContext("2d");
        const canvases = document.querySelectorAll('canvas[id="card-canvas"]');

        deckCanvas.current.height = previewHeight/previewWidth * deckCanvas.current.width;
        const width = deckCanvas.current.width;
        const height = deckCanvas.current.height;
        ctx.width = width;
        ctx.height = height;

        const cardHeight = ctx.height * .75;
        const cardWidth = cardHeight * (2.5 / 3.5);
        

        for(let index = 0; index < canvases.length / 2; index++) {
            const leftIndex = index;
            const rightIndex = canvases.length - index - 1;
            const leftCanvas = canvases[index];
            const rightCanvas = canvases[rightIndex];

            const rad = width/2;
            // Calculate the angle for the current point
            const angle = 180/cards.length * index;
            const xIncrement = width / (cards.length + 1);
            const yIncrement = (previewHeight-cardHeight);

            // Calculate the y coordinate for the current point
            let y = yIncrement - yIncrement * Math.sin(leftIndex / cards.length * Math.PI);
            ctx.drawImage(leftCanvas, xIncrement * index, y, cardWidth, cardHeight);

            y = yIncrement - yIncrement * Math.sin(rightIndex / cards.length * Math.PI);
            ctx.drawImage(rightCanvas, xIncrement * rightIndex, y, cardWidth, cardHeight);
        }

        if(onRedrawn) onRedrawn(deckCanvas.current.toDataURL());
        console.log("Finished rendering...");
    }

    return (
        <div>
            <canvas style={{display: "none"}} ref={deckCanvas} width={previewWidth} height={previewHeight}/>
            <img src={deck.preview}/>
        </div>
    );
}

export default DeckPreview;