import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';
import './dark-select.css'; // Adjust the path to where your actual CSS file is located

function AgentPromptForm({ initialPostData, onPostDataChanged }) {
    const [postData, setPostData] = useState(initialPostData ?? {
        prompt: ''
    });

    const [iteration, setIteration] = useState(0);

    useEffect(() => {
        onPostDataChanged(postData);
    }, [postData, onPostDataChanged, iteration]);

    const updateData = (updatedData) => {
        setPostData(updatedData);
        setIteration(iteration + 1);
    }

    const handlePromptChange = (event) => {
        const updatedData = { ...postData, prompt: event.target.value };
        updateData(updatedData);
    };

    const handleMessageChange = (index, event) => {
        const newMessages = postData.messages.map((message, msgIndex) => {
            if (index !== msgIndex) return message;
            return { ...message, [event.target.name]: event.target.value };
        });

        const updatedData = { ...postData, messages: newMessages };
        updateData(updatedData);
    };

    const handleAddMessage = () => {
        const updatedData = {
            ...postData,
            messages: [...postData.messages, { role: 'assistant', content: '' }]
        };
        updateData(updatedData);
    };

    return (
        <Container>
            <Form>
                <Form.Group as={Row}>
                    <Form.Label column sm={2}>
                        Prompt
                    </Form.Label>
                    <Col sm={10}>
                        <Form.Control
                            type="text"
                            value={postData.prompt}
                            onChange={handlePromptChange}
                        />
                    </Col>
                </Form.Group>

                {postData.messages?.map((message, index) => (
                    <Row key={index}>
                        <Col>
                            <Form.Select
                                className="dark-select"
                                name="role"
                                value={message.role}
                                onChange={(e) => handleMessageChange(index, e)}
                            >
                                <option value="assistant">Assistant</option>
                                <option value="system">System</option>
                                <option value="user">User</option>
                            </Form.Select>
                        </Col>
                        <Col  sm={10}>
                            <Form.Control
                                as="textarea"
                                rows={1}
                                name="content"
                                value={message.content}
                                onChange={(e) => handleMessageChange(index, e)}
                            />
                        </Col>
                    </Row>
                ))}

                <Button variant="primary" onClick={handleAddMessage}>
                    Add Message
                </Button>
            </Form>
        </Container>
    );
}

export default AgentPromptForm;
