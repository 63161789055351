// src/auth/auth0-provider-with-history.js

import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import {useNavigate} from "react-router-dom";

const Auth0ProviderWithHistory = ({ children }) => {
    const domain = "jaxns.auth0.com";
    const clientId = "l7YCPslm4Js3W4CJTLqgxsyldG2F9P9K";
    const navigate = useNavigate();


    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            audience="https://api.aiart.doubtech.com"
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;