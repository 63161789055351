import React, {createContext, useEffect, useState} from 'react'
import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updateEmail,
    updatePassword,
    updateProfile
} from "firebase/auth";

import {auth} from '../firebase/auth'

const AuthContext = createContext();

export function useAuth() {
  return React.useContext(AuthContext)
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState();

    function signup(email, password) {
        return createUserWithEmailAndPassword(auth, email, password)
    }

    function login(email, password) {
        return signInWithEmailAndPassword(auth, email, password)
    }

    function logout() {
        return signOut(auth);
    }

    function resetPassword(email)
    {
        return sendPasswordResetEmail(auth, email);
    }

    function updateUserEmail(email)
    {
        return updateEmail(auth.currentUser, email);
    }

    function updateUserPassword(password)
    {
        return updatePassword(auth.currentUser, password);
    }

    function updateUserProfile(displayName) {
         return updateProfile(auth.currentUser, {displayName});
    }

    useEffect(() => {
        const unsub = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
        });

        return unsub;
    }, [])


    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword,
        updateUserPassword,
        updateUserProfile,
        updateUserEmail

    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}