import React from 'react';
import Slider from '../components/sliderhome';
import Footer from '../components/footer';


export default () => (
  <div>
      <section className="jumbotron jumbomain">
          <Slider />
          <div className="icon-scroll-wraper">
            <div className="icon-scroll">
              <div className="icon-scroll-screen"></div>
            </div>
          </div>
      </section>

    <Footer />

  </div>
);
