import React, {useEffect} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Link, useNavigate} from "react-router-dom";
import UploadBlob from "../../components/uploadblob";
import {getLayerData, getTemplateData, saveLayer, saveTemplate} from "../../../hooks/ccg/ccgdata";
import {JsonEditor as Editor} from "jsoneditor-react18";
import CCGTemplate from "../../components/ccg/ccgtemplate";
import {Dropdown, ProgressBar} from "react-bootstrap";
import {IconButton} from "../../components/styledbutton";
import {MdSave} from "react-icons/all";
import {MdContentPaste} from "react-icons/md";
import PageHeader from "../../components/PageHeader/pageheadercontrol";


const CCGTemplates = () => {
    const {user, getAccessTokenSilently} = useAuth0();
    const [q, setQuery] = React.useState("");
    //const {docs: ccgTemplates} = useCCGData("templates");
    //const {docs: ccgLayers} = useCCGData("layers");
    const templateName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    const editorRef = React.useRef(null);
    const collection = "mtg";
    const navigate = useNavigate();

    const [templates, setTemplates] = React.useState({});
    const [template, setTemplate] = React.useState(null);
    const [layers, setLayers] = React.useState({});
    const [cardConfig, setCardConfig] = React.useState({properties: {}});
    const [saving, setSaving] = React.useState(false);

    const headerImage = '/img/headers/ccg.png'

    const users = {};
    useEffect(() => {
        async function getCardData() {
            console.log("Loading templates...");
            const token = await getAccessTokenSilently();

            const loadedTemplates = await getTemplateData(token, collection);
            setTemplates(loadedTemplates);

            const loadedLayers = await getLayerData(token, collection);
            setLayers(loadedLayers);

            if(templateName) {
                setTemplate(loadedTemplates[templateName]);
            }

            setCardConfig({
                variables: {
                    "name": "Test Card",
                    "type": "Creature",
                    "text": "This is a test card.",
                    "power": 'X',
                    "toughness": 'Y',
                },
                collection: collection,
                template: templateName,
                properties: {}
            });
        }

        getCardData();
    }, []);

    const templateData = templates && templates[template];
    const templateOwner = users && users[template]?.email;

    function onTemplateChanged(template) {
        console.log("Template changed: ", editorRef.current.jsonEditor.get());
        setTemplate(template);
    }

    async function onSaveTemplate(template) {
        if(template) {
            setSaving(true);
            const token = await getAccessTokenSilently();
            await saveTemplate(token, template.name, collection, template);
            setSaving(false);
        }
    }

    async function onSaveLayer(layer) {
        if(layer) {
            setSaving(true);
            const token = await getAccessTokenSilently();
            await saveLayer(token, layer.name, collection, layer);
            setSaving(false)
        }
    }

    function pasteJson() {
        navigator.clipboard.readText().then((text) => {
            const json = JSON.parse(text);
            editorRef.current.jsonEditor.set({
                ...editorRef.current.jsonEditor.get(),
                ...json
            });
        });
    }

    return (

        <PageHeader image={headerImage} title={"Templates"}
                    description={"Create and design card templates for your CCG."}
                    breadcrumb={[
                        ["Home", "/"],
                        ["CCG", "/ccg"],
                        ["Templates", "/ccg/templates"]
                    ]} >

      <section className='container-fluid' id="service">
          <div className='row m-10-hor'>
              <div className='col-md-8'>
                  <div className='left'>
                      {!template && (<div className='content'>
                          <div className='title'>
                              Template Design
                          </div>
                          <div className='desc'>
                              <p>Templates control the layers that are shown in a single card. Each template consists of a basic configuration and a collection of its layers.</p>
                              <p>To get started, upload a template or select one from the right.</p>
                          </div>
                      </div>)}
                      {template && (<div className='content'>
                          <div className='create-card-flex-between-container'>
                              <span>{template.name.toUpperCase()}</span>
                              <div className={'create-card-flex-between-items-grow'}/>
                              <IconButton icon={MdContentPaste} onClick={() => {pasteJson()}}/>
                              <IconButton icon={MdSave} onClick={() => onSaveTemplate(template)} />
                          </div>

                          {saving ? (<ProgressBar label="Saving..."/>) : (<Editor ref={editorRef} value={template}
                                  theme="ace/theme/github" onChange={onTemplateChanged} />)}


                      </div>)}
                  </div>
              </div>
              <div className='col-md-4'>
                  <div className='right'>
                      {template && templates && (<div className="desc" style={{position: "relative", marginTop: "70px"}}>

                          <div className='content'>
                              <CCGTemplate template={template} collection={collection} cardData={cardConfig} width="250" />
                          </div>

                          <div className='content' style={{position:"relative", width: "250px", marginTop: "32px"}}>
                            <div style={{zIndex: 10, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                              <Dropdown>
                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                      <span>{template.name.toUpperCase()}</span>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu className='scrollable-menu' style={{maxHeight: "200px", overflowY: "auto"}}>
                                      {templates && Object.entries(templates).map(([key, doc]) => (<Dropdown.Item key={doc.name} onClick={() => {
                                          navigate('/ccg/templates/' + doc.name);
                                          }}>{doc.name.toUpperCase()}</Dropdown.Item>))}
                                  </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                      </div>)}

                      {!template && (<div style={{marginTop: 16}}>
                          <h3>Templates</h3>
                          {user && <UploadBlob types={['application/json']} collection={"templates"} label="Upload Templates" />}
                          <ul className='services-list'>
                              {templates && Object.entries(templates).map(([key, doc]) => (<li key={key}><Link to={'/ccg/templates/' + key}>{doc.title ?? key.toUpperCase()}</Link></li>))}
                          </ul>
                      </div>)}
                  </div>
              </div>
          </div>
      </section>

  </PageHeader>
);
}

export default CCGTemplates;