import React from 'react';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/vertical.css';
import useRandomImage from "../../hooks/RandomImage";
import useWindowDimensions from "../../hooks/WindowDimensions";


const SliderHome = () => {
  const [dobutechImage] = useRandomImage('midjourney');
  const [midjourneyImage] = useRandomImage('midjourney');
  const [stableDiffusionImage] = useRandomImage('stable-diffusion');
  const {height: windowHeight, width: windowWidth} = useWindowDimensions();
  const maxLogoWidth = windowWidth * 0.25;

  const content = [
    {
      title: "DOUBTECH.AI",
      description:
          "Welcome to DoubTech.AI, a platform for exploring the creative potential of AI.",
      image: dobutechImage,
      logo: "/img/logo.png",
    },
    {
      title: "Midjourney",
      description:
          "Midjourney is an independent research lab exploring new mediums of thought and expanding the imaginative powers of the human species.",
      button: "SEE MORE",
      link: "/model/midjourney",
      image: midjourneyImage
    },
    {
      title: "Stable Diffusion",
      description:
          "Stable Diffusion is a machine learning, text-to-image model developed by StabilityAI.",
      button: "SEE MORE",
      link: "/model/stable-diffusion",
      image: stableDiffusionImage
    },
    {
      title: "Collectable Cards",
      description:
          "Design custom collectable card decks for CCGs like Magic the Gathering or a game of your own design.",
      button: "SEE MORE",
      link: "/ccg/cards",
      image: "/img/headers/ccg-banner.png"
    },
    {
      title: "GPT Agents",
      description:
          "Design reusable GPT agents to generate images, text, and more.",
      button: "SEE MORE",
      link: "/gptinfo",
      image: "/img/headers/agent-banner.png"
    }
  ];

  return (
      <Slider className="slider-wrapper" autoplay={6000}>
        {content.map((item, index) => (
            <div
                key={index}
                className="slider-content"
                style={{background: `url('${item.image}') no-repeat center center`}}
            >
              <div className="inner">
                <h1>{item.title}</h1>
                <p>{item.description}</p>
                {item.button && <button onClick={() => window.open(item.link, "_self")}>
                  <span className="shine"></span>
                  <span>
                      {item.button}
                    </span>
                </button>}
                {item.logo && <img src={item.logo} alt="logo" className="logo" style={{position: "fixed", top: "50%", width: maxLogoWidth, right: 0, transform: "translate(-50%, -50%)"}}/>}
              </div>

            </div>
        ))}
      </Slider>
  );
}

export default SliderHome;
