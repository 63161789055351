import {useEffect, useState} from "react";
import {ccgFirestore, ccgStorage, timestamp} from '../../firebase/ccg/ccg_config';
import {useAuth0} from "@auth0/auth0-react";
import {doc, setDoc} from "firebase/firestore";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";

const useCCGStorage = (files, path='/', collectionName) => {
    const {isAuthenticated,user} = useAuth0();
    const [progress, setProgress] = useState([]);
    const [error, setError] = useState(null);
    const [url, setUrl] = useState(null);

    console.log("Path: ", path);
    console.log("Collection: ", collectionName)
    console.log("File: ", files);

    useEffect(() => {
        console.log("Files: ", files);
        const percentages = [];
        if(files) {
            for (let i = 0; i < files.length; i++) {
                try {
                    const file = files[i];
                    const index = i;
                    console.log("Uploading: " + file.name)
                    if (!isAuthenticated) {
                        setError("You must log in to upload files.");
                        return;
                    }
                    percentages.push(0)
                    const docName = user.email + "::" + file.name.replace(/\.[^/.]+$/, "");
                    const storageRef = ref(ccgStorage, path + "/" + user.email + "/" + file.name);
                    const collectionRef = doc(ccgFirestore, collectionName, docName);

                    console.log("file.type: ", file.type);
                    if(file.type === "application/json")
                    {
                        var reader = new FileReader();
                        reader.onload = async function(e) {
                            const contents = JSON.parse(e.target.result);
                            await setDoc(collectionRef, {url, createdAt: timestamp(), user: user, ...contents});
                            setUrl('collection://' + collectionName + '/' + docName);
                        };
                        reader.readAsText(file);
                    } else {
                        console.log("Uploading: ", file.fullPath);
                        const uploadTask = uploadBytesResumable(storageRef, file)
                        uploadTask.on('state_changed', (snap) => {
                            let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
                            percentages[index] = percentage;
                            setProgress(percentages);
                            console.log(`${file}: ${percentage}%`);
                        }, (err) => {
                            console.error(err);
                            setError(err);
                        }, async () => {
                            const url = await getDownloadURL(uploadTask.snapshot.ref);
                            await setDoc(collectionRef, {url, createdAt: timestamp(), user: user});
                            setUrl(url);
                        });
                    }
                } catch(err) {
                    console.error(err);
                    setError(err);
                }
            }
        }
    }, [files]);

    return {progress, url, error};
}

export default useCCGStorage;