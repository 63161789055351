import React, {useEffect, useRef, useState} from 'react';
import {Cell, Grid} from "./grid";
import images from "../ImageGallery/images";
import useInterval from "../interval";
import AnimatedImage from "./animatedimage";

class GridTracker {
    constructor(gridWidth, gridHeight) {
        this.gridWidth = gridWidth;
        this.gridHeight = gridHeight;
        this.gridTracker = Array.from({ length: gridHeight }, () => Array(gridWidth).fill(0));
    }

    occupyCells(x, y, cellWidth, cellHeight) {
        for (let i = y; i < y + cellHeight; i++) {
            for (let j = x; j < x + cellWidth; j++) {
                this.gridTracker[i][j] = 1;
            }
        }
    }

    findLargestUnoccupiedSpace() {
        let maxArea = 0;
        let bestFit = { cellWidth: 0, cellHeight: 0, x: -1, y: -1 };

        for (let i = 0; i < this.gridHeight; i++) {
            for (let j = 0; j < this.gridWidth; j++) {
                if (this.gridTracker[i][j] === 0) {
                    // Attempt to find the largest rectangle starting from this point
                    let width = 0, height = 0;
                    while (j + width < this.gridWidth && this.gridTracker[i][j + width] === 0) {
                        width++;
                    }

                    while (i + height < this.gridHeight && this.gridTracker[i + height][j] === 0) {
                        let valid = true;
                        for (let w = 0; w < width; w++) {
                            if (this.gridTracker[i + height][j + w] === 1) {
                                valid = false;
                                break;
                            }
                        }
                        if (valid) height++;
                        else break;
                    }

                    if (width * height > maxArea) {
                        maxArea = width * height;
                        bestFit = { cellWidth: width, cellHeight: height, x: j, y: i };
                    }
                }
            }
        }

        return bestFit;
    }
}


const GridImage = ({ image, x, y, width = 1, height = 1, onClick, ...props }) => {
    return (
        <Cell x={x} y={y} cellWidth={width} cellHeight={height}>
            <AnimatedImage
                onClick={onClick}
                src={image.url}
                alt={image.alt}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            />
            {props.children}
        </Cell>
    );
};

const ImageGrid = ({ images, width, height, shuffleTime = -1, onClickImage = () => false }) => {
    const gridTracker = new GridTracker(10, 10);
    const gridRef = useRef(null);
    const [imageAspects, setImageAspects] = useState({});
    const [allocatedImages, setAllocatedImages] = useState([]);
    const [immediate, setImmediate] = useState(true);

    useEffect(() => {
        const imageAspects = {};
        // Add all of the images to imageAspects by aspect ratio
        images.forEach(image => {
            const ar = Math.floor(image.aspect_ratio * 1000);
            if(!imageAspects[ar]) {
                imageAspects[ar] = [];
            }
            imageAspects[ar].push(image);
        });
        setImageAspects(imageAspects);
    }, [images, width, height]);

    async function shuffleImages() {
        if(images.length === 0) return;
        if(Object.keys(imageAspects).length === 0) return;
        if(window.isShuffling) return;

        window.isShuffling = true;

        gridRef.current.clear();

        const allocatedImages = [];
        const keys = Object.keys(imageAspects);

        // Get a random aspect ratio to start with
        const ar = keys[Math.floor(Math.random() * keys.length)];
        const image = imageAspects[ar][Math.floor(Math.random() * imageAspects[ar].length)];
        let dimen = gridRef.current.getBestDimensionsForAspectRatio(image.aspect_ratio);
        gridRef.current.reserve(dimen.x, dimen.y, dimen.width, dimen.height);
        allocatedImages.push({
            image: image,
            x: dimen.x,
            y: dimen.y,
            width: dimen.width,
            height: dimen.height
        });

        for(let limit = 0; limit < width * height && gridRef.current.hasAvailableSpace(); limit++) {
            let best = undefined;
            let bestSize = 0;
            let bestDimen = 0;
            for(let i = 0; i < keys.length; i++) {
                const ar = keys[i];
                const aspect = ar / 1000.0;
                const dimen = gridRef.current.getBestDimensionsForAspectRatio(aspect);
                const area = dimen.width * dimen.height;
                if(area > bestSize) {
                    bestDimen = dimen;
                    bestSize = area;
                    best = ar;
                }
            }

            // Get a random image from the best aspect ratio
            const image = imageAspects[best][Math.floor(Math.random() * imageAspects[best].length)];
            gridRef.current.reserve(bestDimen.x, bestDimen.y, bestDimen.width, bestDimen.height);
            allocatedImages.push({
                image: image,
                x: bestDimen.x,
                y: bestDimen.y,
                width: bestDimen.width,
                height: bestDimen.height
            });
        }

        const delay = Math.min(5, shuffleTime / 2) / allocatedImages.length;
        const shownImages = []
        for(let i = 0; i < allocatedImages.length; i++) {
            console.log(`Showing: ${i} with delay ${delay}`)
            shownImages.push(allocatedImages[i]);
            setAllocatedImages(shownImages);
            await new Promise(r => setTimeout(r, delay * 1000));
        }
        window.isShuffling = false;
    }

    useEffect(() => {
        shuffleImages();
    }, [imageAspects]);


    useInterval(() => {
        setImmediate(false);
        shuffleImages();
    }, shuffleTime < 0 ? 1000000 : shuffleTime * 1000, immediate);

    return (
        <Grid ref={gridRef} width={width} height={height} onClick={() => {
            if(!onClickImage()) shuffleImages();
        }}>
            {allocatedImages.map((image, index) => {

                return <GridImage key={index} image={image.image} x={image.x} y={image.y} width={image.width} height={image.height} onClick={() => {
                    if(!onClickImage()) shuffleImages();
                }} />
            })}
        </Grid>
    );
};

export default ImageGrid;
