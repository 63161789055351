import React, {useRef, useState} from 'react';
import {Alert, Button, Card, Form} from 'react-bootstrap';
import {useAuth} from "../../hooks/authcontext";
import {PrivateComponent} from "../../hooks/privatecomponent";
import {Link} from "react-router-dom";

export default function UpdateProfile() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const displayNameRef = useRef();
    const { currentUser, updateUserEmail, updateUserPassword, updateUserProfile } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError("");
            setLoading(true);
            if(emailRef.current.value != currentUser.email) {
                await updateUserEmail(emailRef.current.value);
            }
            if(passwordRef.current.value && passwordConfirmRef.current.value) {
                if(passwordRef.current.value != passwordConfirmRef.current.value) {
                    return setError("Passwords do not match");
                }
                await updateUserPassword(passwordRef.current.value);
            }
            if(displayNameRef.current.value != currentUser.displayName) {
                await updateUserProfile(displayNameRef.current.value);
            }
        } catch (err) {
            console.log(err);
            setError('Failed to update account: ' + err);
        }
        setLoading(false);
    }

    return (
        <PrivateComponent>
            <Card className="bg-dark m-10-hor col-md-10">
                <Card.Body>
                    <h2 className="text-center mb-4">Update Profile</h2>
                </Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required defaultValue={currentUser.email} />
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} placeholder="leave blank to keep the same" />
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control type="password" ref={passwordConfirmRef} placeholder="leave blank to keep the same" />
                    </Form.Group>
                    <Form.Group id="display-name">
                        <Form.Label>Display Name</Form.Label>
                        <Form.Control type="text" ref={displayNameRef} required placeholder={"Display Name"} />
                    </Form.Group>
                    <Button disabled={loading} className="w-100" type="submit">Update</Button>
                </Form>
                <br/>
            </Card>
            <div className="w-100 text-center mt-2">
                <Link to={'/profile'}>Cancel</Link>
            </div>
        </PrivateComponent>
    )
}