import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Table} from 'react-bootstrap';
import StyledButton from "../styledbutton";
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import {MdContentPaste, MdDelete} from "react-icons/md";

const EditDataSet = ({ showModal, setShowModal, id, defaultData, onDataUpdated }) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [newData, setNewData] = useState([]);
    const [data, setData] = useState(defaultData);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    const handleInputChange = (index, value) => {
        newData[index] = value;
        setNewData(newData);
        console.log("New data: ", newData);
    };

    async function fetchData() {
        var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const messageResponse = await artApiFetchAuthAsync(token, "prompt-data/get-dataset",
            "dataset=" + encodeURIComponent(id));
        // if error in response, show error
        if("error" in messageResponse) {
            toast.error(`Failed to fetch datasets.\n${messageResponse.error}`);
        } else {
            setData(messageResponse);
            onDataUpdated();
        }
    }

    useEffect(() => {
        if(id) {
            fetchData();
        }
    }, [id])

    async function submitNewData(newData) {
        var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const messageResponse = await artApiFetchAuthAsync(token, "prompt-data/add-data",
            "dataset=" + encodeURIComponent(id),
            "data=" + encodeURIComponent(JSON.stringify(newData)));
        // if error in response, show error
        if("error" in messageResponse) {
            toast.error(`Failed to fetch datasets.\n${messageResponse.error}`);
        } else {
            fetchData(id);
        }
    }

    const handleAddData = () => {
        submitNewData(newData);
    };

    const [columnCount, setColumnCount] = useState(Math.max(1, data && data.data && data.data.length > 0 ? data.data[0].length : 1));

    const handleAddColumn = () => {
        setColumnCount(columnCount + 1);
    };

    const handleRemoveColumn = () => {
        if (columnCount > 1) {
            setColumnCount(columnCount - 1);
        }
    };

    const renderTableHeader = () => {
        return (
            <thead>
            <tr>
                {[...Array(columnCount)].map((_, index) => (
                    <th key={index}>Header {index + 1}</th>
                ))}
            </tr>
            </thead>
        );
    };

    function drawRow(row) {
        // If it is an object
        if (row?.data) {
            if(Array.isArray(row.data)) {
                return row.data.map((value, rowIndex) => (
                    <td key={rowIndex}>{value}</td>
                ));
            } else {
                return <td>{row.data}</td>
            }
        } else if (Array.isArray(row)) {
            return row.map((value, rowIndex) => (
                <td key={rowIndex}>{value.data}</td>
            ));
        } else {
            return <td>{row}</td>
        }
    }

    async function deleteData(dataSet, data) {
        var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
        const messageResponse = await artApiFetchAuthAsync(token, "prompt-data/delete-data",
            "dataset=" + encodeURIComponent(dataSet),
            "id=" + encodeURIComponent(data));
        // if error in response, show error
        if("error" in messageResponse) {
            toast.error(`Failed to fetch datasets.\n${messageResponse.error}`);
        } else {
            fetchData();
        }
    }

    function pasteContent() {
        navigator.clipboard.readText().then((text) => {
            console.log("paste: ", text);
            // If text is a json array, parse it
            try {
                const parsed = JSON.parse(text);
                if (Array.isArray(parsed)) {
                    console.log("parsed len: ", parsed.length);
                    if(parsed.length === columnCount || !data || data.length === 0) {
                        submitNewData(parsed);
                    } else {
                        parsed.map((row) => {
                            if(Array.isArray(row) && row.length === columnCount) {
                                submitNewData(row);
                            } else if(!Array.isArray(row) && columnCount === 1) {
                                console.log("Submitting row value: ", row, " as array")
                                submitNewData([row]);
                            }
                        });
                    }
                }
            } catch (e) {
                console.log("Error parsing json: ", e);
                text.split('\n').map((row) => {
                    if(row.split('\t').length === columnCount) {
                        submitNewData(row.split('\t'));
                    }
                });
            }
        });
    }

    const renderTableBody = () => {
        return (
            <tbody>
            {data && data.data && data.data.length > 0 && data.data.map((row, index) => (

                <tr key={index}>
                    {drawRow(row)}
                    {data.id && <td>
                        <MdDelete className={"icon"} onClick={() => {
                            console.log("Delete row: ", row.id);
                            deleteData(data.id, row.id);
                        }} />
                    </td>}
                </tr>
            ))}
            {data.id && <tr>
                {[...Array(columnCount)].map((_, index) => (
                    <td key={index}>
                        <Form.Control name={`column${index}`} value={newData[`column${index}`]} onChange={ev => handleInputChange(index, ev.target.value)} />
                    </td>
                ))}
            </tr>}

            {data.id && <tr><td>
                    <StyledButton label={"Add"} onClick={handleAddData} />
                <MdContentPaste className={"icon"} onClick={() => {pasteContent()}}/>
            </td></tr>}
            </tbody>
        );
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Add Data
            </Button>

            <Modal show={showModal} onHide={handleClose} >
                <Modal.Header>
                    <Modal.Title>Edit Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data?.id && <div className={"create-card-flex-between-container"}>
                        <div className={"create-card-flex-between-items-grow"}/>
                        <Button variant="secondary" onClick={handleAddColumn} className="mr-2">
                            +
                        </Button>
                        <Button variant="secondary" onClick={handleRemoveColumn}>
                            -
                        </Button>
                    </div>}
                    <div style={{overflow: "auto"}}>
                        <Table striped bordered hover variant="dark">
                            {renderTableBody()}
                        </Table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default EditDataSet;
