import {useEffect, useState} from "react";
import {fs} from "../firebase/firestore_config"
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";

/*const useImageData = (model) => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        let q = query(collection(fs, 'art'),
            orderBy("timestamp", "desc"),
            where("model", "==", model),
            where("parameters.parameters.upscaled", "==", false));
        if(l > 0) {
            q = query(q, limit(l))
        }
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const images = [];
            querySnapshot.forEach((snap) => {
                snap.forEach((doc) => {
                    images.push({
                        id: doc.id,
                        ...doc.data()
                    });
                })
                setImages(images);
            });
            setImages(images);
        });
        return () => unsubscribe();
    }, [model])


    return { images };
}

export default useImageData;*/

export function getPreviewUrl({url, width, height}) {
    if(!url) return url;

    if(width <= 512 && height <= 512) {
        return url;
    }

    if(url.startsWith("https://doubtech-aiart.s3.amazonaws.com/")) {
        const basename = url.substring("https://doubtech-aiart.s3.amazonaws.com/".length);
        return `https://ik.imagekit.io/doubtech/${basename}?tr=.25`;
    } else {
        return `https://ik.imagekit.io/doubtech/proxy/tr:.25,h-auto/${url}`;
    }
}

const useImageData = (model) => {
    const [images, setImages] = useState([]);

    console.log("Tracking model: " + model);

    useEffect(() => {
        console.log("Setting up snap...")
        const c = query(collection(fs, 'art'),
            orderBy("timestamp", "desc"),
            where("model", "==", model));

        getDocs(c).then((snap) => {
          console.log("Got docs. ", snap);
        }).catch((err) => console.log(err));
        /*const unsub = onSnapshot(c, snap => {
            console.log("Got snap...")
                let documents = [];
                snap.forEach(doc => {
                    documents.push({...doc.data(), id: doc.id});
                });
                console.log("Documents: ", documents);
                setImages(documents);
            });

        return () => unsub();*/
        // this is a cleanup function that react will run when
        // a component using the hook unmounts
    }, []);

    return { images };
}

export default useImageData;