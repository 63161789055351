import React, {useEffect} from 'react';
import {Dropdown, DropdownButton} from "react-bootstrap";
import {useEndpoint} from "../../../../../hooks/api";

function ServiceDropdown({value, onChange}) {
    const [services, setServices] = React.useState([]);
    const { fetch: listServices } = useEndpoint("gpt/list-services");

    async function fetchServices() {
        const services = await listServices();
        if(services) {
            setServices(services);
        }
    }

    useEffect(() => {
        fetchServices();
    }, []);

    return (
        <DropdownButton id="dropdown-item-button" title={value ?? "Select Service"}>
            {services && services.map(service =>
                <Dropdown.Item key={service} as="button"
                               onClick={() => onChange(service)}>{service}</Dropdown.Item>
            )}
        </DropdownButton>
    );
}

export default ServiceDropdown;