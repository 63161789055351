import React, {useEffect, useState} from "react";
import {ccgFirestore} from "../../firebase/ccg/ccg_config";
import {collection, doc, getDocs, onSnapshot, setDoc} from "firebase/firestore";
import {artApiFetchAuthAsync, artApiPostAuthAsync} from "../artapi";
import {useAuth0} from "@auth0/auth0-react";
import {getMyProfileInfoAsync} from "../profileinfo";

export function clearCacheValue(key) {
    if(window.ccgcache && window.ccgcache[key]) {
        delete window.ccgcache[key];
    }
}

export function setCacheValue(key, value, lifespan = -1) {
    if(!window.ccgcache) {
        window.ccgcache = {};
    }

    window.ccgcache[key] = {timestamp: Date.now(), value: value, lifespan: lifespan};
}

export function hasCacheValue(key) {
    return window.ccgcache && window.ccgcache[key] && (window.ccgcache[key].lifespan === -1 || Date.now() - window.ccgcache[key].timestamp < window.ccgcache[key].lifespan);
}

export function getCacheValue(key, defaultValue) {
    return hasCacheValue(key) ? window.ccgcache[key].value : defaultValue;
}

export function getCollections() {
    const collections = getCacheValue(`collections`, []);
    if(null == collections) {
        console.log("Collections not found in cache.");
    }
    return collections ?? [];
}

export function updateLayer(collection, layer) {
    getCacheValue(`layers.${collection}`, {})[layer.name] = layer;
}

export function getLayer(collection, layer) {
    const layers = getCacheValue(`layers.${collection}`, null);
    if(null == layers) {
        console.log("Layer not found in cache: ", collection, layer);
    }
    return layers && layers[layer] ? layers[layer] : null;
}

export function updateTemplate(collection, template) {
    getCacheValue(`templates.${collection}`, {})[template.name] = template;
}

export function getTemplate(collection, layer) {
    const layers = getCacheValue(`templates.${collection}`, null);
    return layers && layers[layer] ? layers[layer] : null;
}


export const saveFirestoreCCGLayer = (layer) => {
    const c = doc(ccgFirestore, "layers", layer.id);
    setDoc(c, layer).then(() => {
        console.log("Saved layer: ", layer);
    });
}
export const saveFirestoreCCGData = (collectionName, collection) => {
    const c = doc(ccgFirestore, collectionName, collection.id);
    setDoc(c, collection).then(() => {
        console.log("Saved layer: ", collection.name);
    });
}

export const saveTemplate = async (token, layerName, collection, template) => {
    await artApiPostAuthAsync(token, "ccg/templates/edit", JSON.stringify(template),
        "name=" + encodeURIComponent(layerName),
        "collection=" + collection
    ).then(data => {
        console.log("Data: ", data);
    });
}

export const saveLayer = async (token, layerName, collection, layer) => {
    await artApiPostAuthAsync(token, "ccg/layers/edit", JSON.stringify(layer),
        "name=" + encodeURIComponent(layerName),
        "collection=" + collection
    ).then(data => {
        console.log("Data: ", data);
    });
}

export const useFirestoreCCGData = (collectionName, subscribe=true) => {
    const [docs, setDocs] = useState([]);

    useEffect(() => {
        const c = collection(ccgFirestore, collectionName)
        if(subscribe) {
            onSnapshot(c, (snap) => {
                let documents = [];
                snap.forEach(doc => {
                    documents.push({...doc.data(), id: doc.id});
                });
                setDocs(documents);
            });
        } else {
            getDocs(c)
                .then((snap) => {
                    let documents = [];
                    snap.forEach(doc => {
                        documents.push({...doc.data(), id: doc.id});
                    });
                    setDocs(documents);
                })
                .catch((err) => console.error(err));
        }
    }, [collection])

    return { docs };
}

export const getCollectionsData = async (token, owner=null) => {
    if(hasCacheValue(`collections`)) {
        return getCacheValue(`collections`, {});
    }

    const collections = {};
    const result = owner ?
        await artApiFetchAuthAsync(token, "ccg/collections/list", "owner=" + owner) :
        await artApiFetchAuthAsync(token, "ccg/collections/list");
    result.map((collection) => {
        if(collection) {
            collections[collection.name] = collection;
        }
    });
    setCacheValue(`collections`, collections);

    return collections;
}

export const getLayerData = async (token, collection) => {
    if(hasCacheValue(`layers.${collection}`)) {
        return getCacheValue(`layers.${collection}`, {});
    }

    const loadedLayers = {};
    const result = await artApiFetchAuthAsync(token, "ccg/layers/list", "collection=" + collection);
    result.map((layer) => {
        const l = layer.layer;
        if(l) {
            loadedLayers[layer.name.toLowerCase()] = l;
            if (!loadedLayers[l.name.toLowerCase()].name) {
                loadedLayers[l.name.toLowerCase()].name = layer.name.toLowerCase();
            }
        }
    });
    setCacheValue(`layers.${collection}`, loadedLayers);

    return loadedLayers;
}

export const useCCGCollections = (owner=null) => {
    const {user, getAccessTokenSilently} = useAuth0();
    const [collections, setCollections] = useState(getCacheValue(`collections`, {}));
    setCacheValue('user', user);
    useEffect(() => {
        setCacheValue('user', user);
        async function getCardData() {
            const token = await getAccessTokenSilently();
            await getMyProfileInfoAsync(token);
            const c = await getCollectionsData(token, owner);
            setCollections(c);
        }

        if(user) {
            getCardData();
        }
    }, [user, owner]);

    return { collections };
}

export const useCCGLayers = (collection) => {
    const {user, getAccessTokenSilently} = useAuth0();
    const [layers, setLayers] = useState(getCacheValue(`layers.${collection}`, {}));
    setCacheValue('user', user);
    useEffect(() => {
        setCacheValue('user', user);
        async function getCardData() {
            const token = await getAccessTokenSilently();
            await getMyProfileInfoAsync(token);
            const layers = await getLayerData(token, collection);
            setLayers(layers);
        }

        if(user) {
            getCardData();
        }
    }, [user, collection]);

    return { layers };
}

export const getTemplateData = async (token, collection) => {
    if(hasCacheValue(`templates.${collection}`)) {
        return getCacheValue(`templates.${collection}`, {});
    }

    let result = await artApiFetchAuthAsync(token, "ccg/templates/list", "collection=" + collection);
    const loadedTemplates = {};
    result.map((template) => {
        loadedTemplates[template.name] = template.template;
        if(!loadedTemplates[template.name].name) {
            loadedTemplates[template.name].name = template.name;
        }
    });
    setCacheValue(`templates.${collection}`, loadedTemplates);
    return loadedTemplates;
}

export const useCCGTemplates = (collection) => {
    const {user, getAccessTokenSilently} = useAuth0();
    const [templates, setTemplates] = React.useState(getCacheValue(`templates.${collection}`, {}));

    setCacheValue('user', user);
    useEffect(() => {
        setCacheValue('user', user);
        async function getCardData() {
            const token = await getAccessTokenSilently();
            await getMyProfileInfoAsync(token);
            const loadedTemplates = await getTemplateData(token, collection);
            setTemplates(loadedTemplates);
        }

        if(collection) getCardData();
    }, [user, collection]);

    return { templates };
}

export const ccgDataReady = ({templates, layers}) => {
    if(!layers || Object.keys(layers).length === 0) return false;
    if(!templates || Object.keys(templates).length === 0) return false;

    return true;
}

export const useCCGData = (collection) => {
    const {user, getAccessTokenSilently} = useAuth0();
    const [templates, setTemplates] = React.useState(getCacheValue(`templates.${collection}`, {}));
    const [layers, setLayers] = React.useState(getCacheValue(`layers.${collection}`, {}));

    setCacheValue('user', user);
    useEffect(() => {
        async function getCardData() {
            setCacheValue('user', user);
            try {
                const token = await getAccessTokenSilently();
                await getMyProfileInfoAsync(token);
                const loadedTemplates = await getTemplateData(token, collection);
                setTemplates(loadedTemplates);

                const loadedLayers = await getLayerData(token, collection);
                setLayers(loadedLayers);
            } catch (e) {
                console.error(e);
            }
        }

        if(user && collection) getCardData();
    }, [user, collection]);

    return { templates, layers };
}

export default useCCGData;