// src/PresetGrid.js
import React, {useEffect, useState} from 'react';
import './chatgrid.css';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate} from "react-router-dom";

function ChatGrid({ agents }) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [filterableAgents, setFilterableAgents] = useState([]);

    useEffect(() => {
        // Get all agents from the fields combine all of the arrays in agents object values
        let allAgents = [];
        Object.values(agents).forEach((agentArray) => {
            allAgents = allAgents.concat(agentArray);
        });

        const filteredPresets = allAgents.filter(preset =>
            preset.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilterableAgents(filteredPresets);

        console.log("Presets: ", agents);
        console.log("Filterable presets: ", filterableAgents);
    }, [searchTerm, agents]);

    if(!agents) return <></>;

    function handleClick(preset) {
        if(preset.link) navigate(preset.link);
        if(preset.onClick) preset.onClick();
    }

    return (
        <div className="agent-grid-grid">
            <Form.Control
                type="text"
                placeholder="Search agents..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="mb-3"  // Bootstrap margin-bottom class
            />
            <div className="grid">
                {filterableAgents.map((preset, index) => (
                    <div key={index} className="agent-grid-box" onClick={() => handleClick(preset)}>
                        <div className={"agent-grid-box-content"}>
                            <h3 className="agent-grid-title">{preset.name}</h3>
                            <p className="agent-grid-description">{preset.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ChatGrid;
