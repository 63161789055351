import React from 'react';
import emailjs from 'emailjs-com';
import {Link} from "react-router-dom";
import Footer from '../components/footer';
import Signup from "../components/signup";


export default function() {

  function sendEmail(e) {

    const success = document.getElementById("success");
    const button = document.getElementById("buttonsent");
    const failed = document.getElementById("failed");
    e.preventDefault();

    emailjs.sendForm('gmail', 'template_csfdEZiA', e.target, 'user_zu7p2b3lDibMCDutH5hif')
      .then((result) => {
          console.log(result.text);
          success.classList.add('show');
          button.classList.add('show');
          failed.classList.remove('show');
      }, (error) => {
          console.log(error.text);
          failed.classList.add('show');
      });
  }

  return (
    <div>
    <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/headers/portal.png'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Sign Up</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <Link className='link' to="/home">Home</Link>
                  <span className='dash'>/</span>
                  <span>Sign Up</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
           <div className="text-side">
             <h2>Sign Up</h2>
              <p>Want to create your own art or rate the art created by the AI? This is the place to login so you can!</p>
           </div>
          </div>
          <div className='col-md-6'>
            <div className="form-side">
              <Signup />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
