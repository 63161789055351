import {Dropdown} from "react-bootstrap";
import React from "react";
import {useCCGTemplates} from "../../../hooks/ccg/ccgdata";

const CCGTemplateChooser = ({collection, selected, onSelected}) => {
    const {templates} = useCCGTemplates(collection);
    const [template, setTemplate] = React.useState(selected);

    function handleTemplateSelection(doc) {
        setTemplate(doc);
        if(onSelected) {
            onSelected(doc);
        }
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{flexGrow: 1}}>
                <span>{template && template.toString().toUpperCase() ? template.toString().toUpperCase() : 'Select a template'}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className='scrollable-menu' style={{maxHeight: "200px", overflowY: "auto"}}>
                {templates && Object.entries(templates).map(([key, doc]) => (
                    <Dropdown.Item key={key} onClick={() => handleTemplateSelection(key)}>
                        {key.toUpperCase()}
                    </Dropdown.Item>))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default CCGTemplateChooser;