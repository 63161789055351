import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import {artApiFetch} from "./artapi";
import {getPreviewUrl} from "./ImageData";

export const galleryApiFetch = (imageProps = {defaultImages: [], maxWidth: -1, maxHeight: -1}, endpoint, ...params) => {
    return artApiFetch(endpoint, ...params)
        .then((modelImages) => {
            const images = [];
            if (modelImages && modelImages.length > 0) {
                function calculateScale(image) {
                    const width = image.width ?? 500;
                    const height = image.height ?? 500;
                    const scale = Math.min(imageProps.maxWidth / width, imageProps.maxHeight / height);
                    return scale < 1 ? scale : 1;
                }

                function calcWidth(image) {
                    const width = image.width ?? 500;
                    return width * calculateScale(image);
                }

                function calcHeight(image) {
                    const height = image.height ?? 500;
                    return height * calculateScale(image);
                }

                modelImages.map(image => {
                    const imageData = {
                        src: image.url,
                        preview : image.preview ?? getPreviewUrl(image.url),
                        alt: image.parameters.prompt ?? "",
                        caption: "",
                        width: image.width,
                        height: image.height,
                        prompt: image.parameters.prompt ?? "",
                        id: image.id
                    };
                    if (image.width === -1) {
                        const img = new Image();
                        img.src = image.url;
                        img.onload = () => {
                            image.width = img.width;
                            image.height = img.height;
                        }
                    }
                    imageData.width = calcWidth(image);
                    imageData.height = calcHeight(image);
                    images.push(imageData)
                });
            }
            return images.length > 0 ? images : imageProps.defaultImages;
        })
        .catch((e) => {
            console.error(e);
        });
}

export const useGalleryRequest = (imageProps = {defaultImages: [], maxWidth: -1, maxHeight: -1}, endpoint, ...params) => {
    const {user} = useAuth0();
    const defaultImages = {
        src: "/img/midjourney-square.png",
        alt: "Waiting for images...",
        caption: "Waiting for images....",
        width: 500,
        height: 500
    };
    const [error, setError] = useState("");
    const [images, setImages] = useState([defaultImages]);

    useEffect(() => {
        if(endpoint != "") {
            galleryApiFetch(imageProps, endpoint, `user=${user?.email}`, ...params)
                .then((images) => {
                    if (images.length > 0) {
                        console.log(`Loaded ${images.length} images`, images);
                        setImages(images);
                    }
                })
                .catch(setError);
        }
    }, [endpoint, user?.email, ...params]);

    return { images, error };
}

export const usePollingGalleryApi = (imageProps = {defaultImages: undefined, maxWidth: -1, maxHeight: -1}, pollRate, endpoint, ...params) => {
    const {user} = useAuth0();
    const defaultImages = imageProps.defaultImages ? imageProps.defaultImages : {
        src: "/img/gallery/1.jpg",
        alt: "Waiting for images...",
        caption: "Waiting for images....",
        width: 500,
        height: 500
    };
    const [images, setImages] = useState([defaultImages]);
    const [error, setError] = useState(null);
    const uri = `https://api.aiart.doubtech.com/${endpoint}?${params.join("&")}`;
    console.log("polling uri: ", uri);
    useEffect(() => {
        function fetch() {
            galleryApiFetch(imageProps, endpoint, `user=${user?.email}`, ...params)
                .then((r) => {
                    if(r.length > 0) {
                        setImages(r);
                    }
                    setError(null);
                })
                .catch(setError);
        }
        if(endpoint.length > 0) {
            const interval = setInterval(() => {
                fetch();
            }, pollRate);
            fetch();
        }
    }, [endpoint,uri,user?.email, ...params]);

    return { images, error };
}

export default useGalleryRequest;