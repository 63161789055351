import React, {useEffect, useRef, useState} from 'react';
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import Table from "react-bootstrap/Table";
import {useImageSelection} from "../../providers/selection";
import {CollapsibleCard, CollapsibleCardContent} from "../../components/CollapsableCard";
import {Button, Form, Modal} from "react-bootstrap";
import {
    MdCastForEducation,
    MdCreate,
    MdCreateNewFolder,
    MdOutlineNewLabel,
    MdOutlineNewReleases
} from "react-icons/all";
import {artApiPostAuthAsync, useAuthenticatedArtApi} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import {useAuth0} from "@auth0/auth0-react";
import {MdDelete} from "react-icons/md";
import {useIndexDB} from "../../providers/indexdb";
import {centerCrop, makeAspectCrop, ReactCrop} from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css'
import StyledButton from "../../components/styledbutton";
import ImageUploader from "../../components/imageuploader";


const LoraTraining = () => {
    const { getAccessTokenSilently, user } = useAuth0();

    const { getValue, setValue } = useIndexDB();
    const headerImage = '/img/headers/training.png'
    const {images, addSelectedImage, removeSelectedImage, updateSelectedImages, addSelectedImages} = useImageSelection();
    const [url, setUrl] = useState(''); // <-- state for URL input
    const [prompt, setPrompt] = useState(''); // <-- state for prompt input
    const [loraName, setLoraName] = useState(''); // <-- state for Lora name
    const [instancePrompt, setInstancePrompt] = useState(''); // <-- state for instance prompt
    const [classPrompt, setClassPrompt] = useState('person'); // <-- state for class prompt
    const [trainingImageCount, setTrainingImageCount] = useState(40); // <-- state for training image count
    const [regularisationCount, setRegularisationCount] = useState(1); // <-- state for regularisation image count
    const [tempPrompts, setTempPrompts] = useState({}); // New state for temporary prompts
    const [crop, setCrop] = useState({ unit: '%', }); // State for the crop box
    const [selectedImage, setSelectedImage] = useState(null); // State for the currently selected image
    const [showModal, setShowModal] = useState(false); // State to control the modal visibility
    const imageRef = useRef(null);
    const [croppedImageBase64, setCroppedImageBase64] = useState(null);
    const { response: entitlements, error: entitlementError } = useAuthenticatedArtApi("entitlements-list");

    // Get the window size
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        async function fetchCachedData() {
            setUrl(await getValue('lora::url', ''));
            setPrompt(await getValue('lora::prompt', ''));
            setLoraName(await getValue('lora::loraName', ''));
            setInstancePrompt(await getValue('lora::instancePrompt', ''));
            setClassPrompt(await getValue('lora::classPrompt', ''));
            setTrainingImageCount(await getValue('lora::trainingImageCount', 40));
            setRegularisationCount(await getValue('lora::regularisationCount', 1));
        }

        fetchCachedData();
    }, []);

    const handleImagesUpload = (files) => {
        // print all files tothe console
        console.log(files);
        //addSelectedImages(files.map(file => {{"src": file}}); fix this
        addSelectedImages(files.map(file => {
            return {src: file}
        }));
    };

    function handleImageClick(index) {
        console.log("handleImageClick");
        setSelectedImage(index);
        const src = images[index];
        if(src.crop) setCrop(src.crop);
        setShowModal(true);
    }

    function handleCloseModal() {
        console.log("handleCloseModal");
        setShowModal(false);
        setSelectedImage(null);
    }

    function handleUrlChange(e) {
        console.log("handleUrlChange");

        setUrl(e.target.value);
        setValue('lora::url', e.target.value);
    }

    function handlePromptChange(e) {
        console.log("handlePromptChange");
        setPrompt(e.target.value);
        setValue('lora::prompt', e.target.value);
    }

    function handleAdd() {
        console.log("handleAdd");
        if (url && prompt) {
            addSelectedImage({ src: url, prompt: prompt });
            setUrl('');
            setPrompt('');
            setValue('lora::url', '');
            setValue('lora::prompt', '');
        }
    }
    function handleLoraNameChange(e) {
        console.log("handleLoraNameChange");
        setLoraName(e.target.value);
        setValue('lora::loraName', e.target.value);
    }

    function handlePromptEdit(index, value) {
        console.log("handlePromptEdit");

        // Update the temporary value of the prompt
        setTempPrompts(prev => ({ ...prev, [index]: value }));
    }

    function handlePromptSave(index) {
        console.log("handlePromptSave");
        // Save the edited value to the images array
        if (tempPrompts[index] && tempPrompts[index] !== images[index].prompt) {
            const updatedImages = [...images];
            updatedImages[index].prompt = tempPrompts[index];
            updateSelectedImages(updatedImages);
            setTempPrompts(prev => {
                const updated = { ...prev };
                delete updated[index];
                return updated;
            });
        }
    }

    function saveCrop(crop) {
        console.log("onCropComplete: " ,imageRef, crop);
        const croppedImage = getCroppedImage(
            imageRef.current,
            crop
        );
        return croppedImage;
    }

    function getCroppedImage(image, crop) {
        console.log("getCroppedImage");
        const canvas = document.createElement('canvas');
        const width = canvas.width = image.naturalWidth * crop.width / 100.0;
        const height = canvas.height = image.naturalHeight * crop.height / 100.0;
        const ctx = canvas.getContext('2d');

        console.log(`canvas size: ${width}x${height}`);

        ctx.drawImage(
            image,
            crop.x * image.naturalWidth / 100.0,
            crop.y * image.naturalHeight / 100.0,
            crop.width * image.naturalWidth / 100.0,
            crop.height * image.naturalHeight / 100.0,
            0,
            0,
            width,
            height
        );

        return canvas.toDataURL('image/png');
    }

    function drawLoraSettingsForm() {
        return (
            <Form>
                <Form.Group>
                    <Form.Label>Lora Name</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Lora Name"
                        value={loraName}
                        onChange={handleLoraNameChange}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Instance Prompt</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={loraName ? loraName : "Enter Instance Prompt"}
                        value={instancePrompt}
                        onChange={e => {
                            setInstancePrompt(e.target.value);
                            setValue('lora::instancePrompt', e.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Class Prompt</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter Class Prompt"
                        value={classPrompt}
                        onChange={e => {
                            setClassPrompt(e.target.value);
                            setValue('lora::classPrompt', e.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Training Image Repeats</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter Training Image Repeats"
                        value={trainingImageCount}
                        onChange={e => {
                            setTrainingImageCount(e.target.value);
                            setValue('lora::trainingImageCount', e.target.value);
                        }}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Regularisation Image Repeats</Form.Label>
                    <Form.Control
                        type="number"
                        placeholder="Enter Regularisation Image Repeats"
                        value={regularisationCount}
                        onChange={e => {setRegularisationCount(e.target.value);
                            setValue('lora::regularisationCount', e.target.value);
                        }}
                    />
                </Form.Group>
                {/* You can add more fields for Lora settings here in the future */}
            </Form>
        );
    }
    function drawAddImageForm() {
        return (<Form>
            <Form.Group>
                <Form.Label>Image URL</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Enter Image URL"
                    value={url}
                    onChange={handleUrlChange}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Prompt</Form.Label>
                <Form.Control
                    as="textarea"  // Set as textarea
                    rows={3}      // Set to show 3 lines
                    placeholder="Enter Prompt"
                    value={prompt}
                    onChange={handlePromptChange}
                />
            </Form.Group>
            <Button variant="primary" onClick={() => handleAdd()} style={{ marginTop: '10px' }}>Add</Button>
        </Form>);
    }

    function content() {
        return (
            <Table striped bordered hover variant="dark">
                <thead>
                <tr>
                    <th style={{width: 100}}>Image</th>
                    <th>Caption</th>
                </tr>
                </thead>
                <tbody>
                {
                    images.map((image, index) => {
                        console.log("Image: ", image);
                        return (
                        <tr key={index}>
                            <td style={{width: 100}}>
                                <img
                                    src={image.cropped ?? image.src}
                                    alt={image.alt}
                                    style={{width: 100, cursor: 'pointer'}}
                                    onClick={() => handleImageClick(index)}
                                />
                            </td>
                            <td style={{ position: 'relative' }}>
                                <Form.Control
                                    as="textarea"
                                    value={tempPrompts[index] !== undefined ? tempPrompts[index] : image.prompt}
                                    onChange={(e) => handlePromptEdit(index, e.target.value)}
                                    onBlur={() => handlePromptSave(index)}
                                    style={{width: '100%', height: '100%', border: 'none', background: 'transparent'}}
                                />
                                <Button
                                    variant="danger"
                                    size="sm"
                                    style={{ position: 'absolute', bottom: 0, right: 0 }}
                                    onClick={() => {
                                        // Functionality to remove the image from the list
                                        removeSelectedImage(image);
                                    }}
                                >
                                    <MdDelete />
                                </Button>
                            </td>
                        </tr>
                    )})
                }
                <tr>
                    <td>
                        {url && <img src={url} style={{width: 100}}/>}
                    </td>
                    <td>
                        {drawAddImageForm()}
                    </td>
                </tr>
                </tbody>
            </Table>
        );
    }

    function rightColumn() {
        return (<>
            <CollapsibleCard title={"Add Image"}>
                <CollapsibleCardContent>
                    <div style={{ display: 'flex', alignItems: 'center' }}> {/* Flexbox container */}

                        <div style={{marginRight: 16}}>
                            {drawAddImageForm()}
                        </div>
                        {url && <div style={{width: 100}} >
                            <img width={100} src={url} alt={prompt} />
                        </div>}
                    </div>
                </CollapsibleCardContent>
            </CollapsibleCard>
            <CollapsibleCard title={"Upload Images"}>
                <CollapsibleCardContent>
                    <ImageUploader onImagesUpload={handleImagesUpload} />
                </CollapsibleCardContent>
            </CollapsibleCard>
            <CollapsibleCard title={"Settings"} expand={true} className={"mt-5"}>
                <CollapsibleCardContent>
                    {drawLoraSettingsForm()} {/* Render Lora settings form */}
                </CollapsibleCardContent>
            </CollapsibleCard>
        </>);

    }

    async function createLora() {
        try {
            const jobBody = {
                images: images.map(image => {
                    return {src: image.src, prompt: image.prompt, crop: image.crop}
                }),
                name: loraName,
                instancePrompt: instancePrompt ? instancePrompt : loraName,
                classPrompt: classPrompt,
                trainingImageRepeats: trainingImageCount,
                regularisationRepeats: regularisationCount,
            }
            const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const job = await artApiPostAuthAsync(token, "jobs-v2/submit", JSON.stringify(jobBody),
                "request=" + encodeURIComponent("/lora"),
                "type=lora");
            if("error" in job) {
                toast.error("Error creating Lora: " + job.error);
            } else {
                toast.success(`Submitted ${loraName} for training with ${images.length} images.`);
            }
        } catch (e) {
            console.log("Error creating Lora: ", e);
            toast.error("Error creating Lora: " + e);
        }
    }

    const rightmenu = images && entitlements && entitlements.includes('lora') ? [
        {
            icon: MdCastForEducation,
            label: "Create Lora",
            onClick: () => {
                createLora();
            }
        }
    ] : [];

    const lefttmenu = images ? [
        {
            icon: MdCreateNewFolder,
            label: "Clear Selection",
            onClick: () => {
                updateSelectedImages([]);
            }
        }
    ] : [];

    return (
        <PageHeader image={headerImage} title={loraName ? loraName : "Lora Training"}
                    description={"Train a new Lora based on selected images."}
                    breadcrumb={[
                        ["Home", "/"],
                        ["Training", "/training"],
                        ["Lora", "/training/lora"]
                    ]}

                    menuright={rightmenu}
                    menuleft={lefttmenu}
        >
            <div className='row'>
                <div className='col-md-8 flex-fill'>
                    {content()}
                </div>
                <div className='col-md-4'>
                    <div className='right'>
                        {rightColumn()}
                    </div>
                </div>
            </div>

            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: `${windowHeight * 3/4.0 - 50}px`, maxWidth: `${windowWidth * 3/4.0}px` }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                        <ReactCrop
                            src={images[selectedImage]?.src}
                            crop={crop}
                            onChange={(newCrop, pc) => setCrop(pc)}
                            onComplete={(c, pc) => setCrop(pc)}
                            aspect={1}
                        >
                            <img
                                src={images[selectedImage]?.src}
                                crossOrigin={"anonymous"}

                                style={{ maxHeight: `${windowHeight * 3/4.0 - 75}px`, maxWidth: `100%` }}
                                ref={imageRef}
                                onLoad={e => {
                                    if(images[selectedImage]?.crop) return;

                                    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
                                    const crop = centerCrop(
                                        makeAspectCrop(
                                            {
                                                unit: '%',
                                                width: 100,
                                            },
                                            1,
                                            width,
                                            height
                                        ),
                                        width,
                                        height,
                                    );
                                    crop.unit = '%';
                                    setCrop(crop);
                                }}
                            />
                        </ReactCrop>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        // Update the cropped image data
                        const updatedImages = [...images];
                        updatedImages[selectedImage].cropped = saveCrop(crop);
                        updatedImages[selectedImage].crop = crop;

                        // Assuming your addSelectedImage function can handle an array.
                        // If not, create a new function to update the images array.
                        updateSelectedImages(updatedImages);
                        console.log("Updated images: ", updatedImages);
                        handleCloseModal();
                    }}>
                        Save Crop
                    </Button>
                </Modal.Footer>
            </Modal>
        </PageHeader>
    );
}

export default LoraTraining;