import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import ArtPage from "../components/artgallery/ArtPage";


const SetHome = () => {
    const { set } = useParams();

    return (<ArtPage
        fields={{"set": set}}
        title={set.toUpperCase()}
        contentDescription={`AI Art from the ${set} set.`}
        refreshRate={300}
        allowUltrafast={false}
    />);
}

export default SetHome