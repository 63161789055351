import {useEffect, useState} from "react";
import {useEndpoint} from "./api";

export const useUserInfo = (userId = undefined) => {
    const [ userInfo, setUserInfo ] = useState({id: userId});
    const { fetchAuth: fetchUserInfo} = useEndpoint("user-info");

    async function fetchUser() {
        const response = await fetchUserInfo({"id": userId});
        if(response) {
            setUserInfo(response);
        }
    }

    useEffect(() => {
        fetchUser();
    }, [userId]);

    return {userInfo, fetchUserInfo: fetchUser};
}