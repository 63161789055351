import React, {useState} from 'react';
import {Link} from "react-router-dom";
import Footer from '../components/footer';
import ForgotPassword from "../components/forgotpassword";
import {useAuth} from "../../hooks/authcontext";

export default function() {
    const {currentUser, logout} = useAuth();
    const [error, setError] = useState("");


  return (
    <div>
    <section className='jumbotron breadcumb' style={{backgroundImage: `url(${'./img/headers/portal.png'})`}}>
        <div className='mainbreadcumb'>
          <div className='container-fluid'>
            <div className='row m-10-hor'>
              <div className='col-md-6'>
                <h1>Forgot Password</h1>
              </div>
              <div className='col-md-6'>
                <div className='list'>
                  <Link className='link' to="/home">Home</Link>
                  <span className='dash'>/</span>
                  <span>Forgot Password</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='container-fluid black_more'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
           <div className="text-side">

           </div>
          </div>
          <div className='col-md-6'>
            <div className="form-side">
                <ForgotPassword />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}
