// LoadingContext.js
import React, {createContext, useContext, useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";
import {useAuth0} from "@auth0/auth0-react";
import {startSession} from "../../hooks/artapi";
import {useError} from "./error";

const LoadingContext = createContext({
    loading: false,
    authComplete: false,
    setLoading: (loading) => {},
    setLoadingImage: (loadingImage) => {},
    setBlockInput: (blockInput) => {},
    setFatalError: (error) => {}
});

export function LoadingProvider({ children }) {
    const [loading, setLoading] = useState(false);
    const [loadingImage, setLoadingImage] = useState(null);
    const [blockInput, setBlockInput] = useState(false);
    const [authComplete, setAuthComplete] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const {setFatalError} = useError();
    function handleLoading(loading) {
        if(loading === undefined) {
            console.error("Loading state must be set to true or false.");
            return;
        }
        setLoading(loading)
    }
    const value = { authComplete, loading, isAuthenticated, setAuthComplete, setIsAuthenticated, setLoading: handleLoading, setLoadingImage, setBlockInput, setFatalError };

    function isLoading() {
        return loading && !authComplete;
    }

    useEffect(() => {
        setBlockInput(loading);
        if(loading) {
            setTimeout(() => {
                setBlockInput(false);
            }, 1500);
        }
    }, [loading]);

    return (
        <LoadingContext.Provider value={value}>
            {children}
            {isLoading() && blockInput && <div style={{position: "fixed", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0)", zIndex: 1000, display: loading ? "block" : "none"}}>
                &nbsp;
            </div>}
            {isLoading() && !blockInput && <div style={{position: "fixed", width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.81)", zIndex: 1000, display: loading ? "block" : "none"}}>
                <Spinner animation={"border"} role={"status"} style={{position: "fixed", top: "50%", left: "50%", zIndex: 1000, display: loading ? "block" : "none"}}/>
            </div>}
        </LoadingContext.Provider>
    );
}

export function useLoading(initialLoading = undefined) {
    const [initialLoadingState] = useState(initialLoading);
    const context = useContext(LoadingContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }

    const { setLoading, ...rest } = context;

    // Set the initial loading state if specified
    useEffect(() => {
        if (initialLoadingState !== undefined && initialLoadingState !== null) {
            setLoading(initialLoadingState);
        }
    }, [initialLoadingState]);

    return context;
}