import React from 'react';
import {useAuth0} from '@auth0/auth0-react';
import StyledButton from "./styledbutton";

const Auth0LogoutButton = () => {
    const { logout } = useAuth0();
    return (
        <StyledButton label="Log Out"
            onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
            }
        >
            Log Out
        </StyledButton>
    );
};

export default Auth0LogoutButton;