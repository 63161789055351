import React, {useEffect} from 'react';
import {Accordion} from 'react-bootstrap';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import Message from "./message";

const MessageList = ({ messages, onAddMessage, onDeleteMessage, onContentChange, onDragEnd }) => {
    useEffect(() => {
    }, [messages]);

    const handleAddMessage = () => {
        onAddMessage();
    };

    const renderMessage = (message, index) => {
        return message && message.id ? (
            <Draggable key={index} draggableId={message.id.toString()} index={index}>
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        {message && <Message message={message} onDeleteMessage={onDeleteMessage} onContentChange={onContentChange} />}
                    </div>
                )}
            </Draggable>
        ) : <div key={index}></div>;
    };


    return (
        <div className="message-list-container">
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="messages">
                    {(provided) => (
                        <Accordion defaultActiveKey="" {...provided.droppableProps} ref={provided.innerRef}>
                            {messages.filter(m => m && m.id).map(renderMessage)}
                            {provided.placeholder}
                        </Accordion>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default MessageList;
