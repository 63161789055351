import SideNav, {NavIcon, NavItem, NavText} from './styledsidenav';
import React, {useEffect} from "react";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import useProfileInfo, {checkEntitlement} from "../../hooks/profileinfo";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

const SecureNavItem = props => {
    const {isAuthenticated} = useAuth0();
    return isAuthenticated && (<NavItem {...props} >{props.children}</NavItem>);
}

const NavLink = (props) => {
    return (<NavItem>
        <NavText>{props.children}</NavText>
    </NavItem>);
}

export default function Sidebar(props) {
    const navigate = useNavigate();
    const {isAuthenticated, loginWithRedirect, logout} = useAuth0();
    const {profileInfo} = useProfileInfo();


    useEffect(() => {}, [profileInfo]);

    function onSelected (selected) {
        if(selected === "login") loginWithRedirect({returnTo: window.location});
        else if (selected === "logout") logout({returnTo: window.location.origin});
        else if(selected) navigate(selected);
    }

    return <SideNav onSelect={onSelected} className={"sidebar"}>
        <SideNav.Nav defaultSelected="home">
            <NavItem eventKey="/">
                <NavIcon><img
                    src="/img/logo.png"
                    className="img-fluid"
                    alt="Home"
                    style={{ padding: 16 }}
                /></NavIcon>
                <NavText>Home</NavText>
            </NavItem>
            <NavItem eventKey="galleries">
                <NavIcon><i className="fa fa-fw fa-images" style={{ fontSize: '1.75em' }} /></NavIcon>
                <NavText>Galleries</NavText>
                <NavItem eventKey="/model/midjourney">
                    <NavText>Midjourney</NavText>
                </NavItem>
                <NavItem eventKey="/model/stable-diffusion">
                    <NavText>Stable Diffusion</NavText>
                </NavItem>
                <NavItem eventKey="/tags">
                    <NavText>Tags</NavText>
                </NavItem>
                <NavItem eventKey="/sets">
                    <NavText>Sets</NavText>
                </NavItem>
                <NavItem eventKey={"/gallery"}>
                    <NavText>2D Gallery</NavText>
                </NavItem>
                <NavItem eventKey="/webgl">
                    <NavText>3D Gallery</NavText>
                </NavItem>
            </NavItem>

            {isAuthenticated && <NavItem eventKey="/dream">
                <NavIcon><i className="fa fa-fw fa-palette" style={{ fontSize: '1.75em' }} /></NavIcon>
                <NavText>Create</NavText>
                <NavItem eventKey="/dream">
                    <NavText>Dream</NavText>
                </NavItem>
                <NavItem eventKey="/prompt-design">
                    <NavText>Prompt Design</NavText>
                </NavItem>
                <NavItem eventKey="/presets">
                    <NavText>Presets</NavText>
                </NavItem>
                <NavItem eventKey="/workflow-viewer">
                    <NavText>Workflow Viewer</NavText>
                </NavItem>
                <NavItem eventKey="/jobs">
                    <NavText>Jobs</NavText>
                </NavItem>
                <NavItem eventKey="/training/lora">
                    <NavText>Lora Training</NavText>
                </NavItem>
            </NavItem>}

            <NavItem eventKey="">
                <NavIcon><i className="fa-brands fa-wizards-of-the-coast" style={{ fontSize: '1.75em' }}></i></NavIcon>
                <NavText>Collectable Cards</NavText>
                <NavItem eventKey="/ccg/cards">
                    <NavText>Cards</NavText>
                </NavItem>
                <NavItem eventKey="/ccg/decks">
                    <NavText>Decks</NavText>
                </NavItem>
                {isAuthenticated && <NavItem eventKey="/ccg/templates"><NavText>Templates</NavText></NavItem>}
                {isAuthenticated && <NavItem eventKey="/ccg/layers"><NavText>Layers</NavText></NavItem>}
                {isAuthenticated && <NavItem eventKey="/ccg/create-card"><NavText>Create Card</NavText></NavItem>}
                {isAuthenticated && <NavItem eventKey="/ccg/deck"><NavText>Create Deck</NavText></NavItem>}
                {isAuthenticated && <NavItem eventKey="/ccg/card-printer"><NavText>Print Cards</NavText></NavItem>}
            </NavItem>

            {isAuthenticated && <NavItem eventKey="/gptinfo">
                <NavIcon><i className="fa fa-fw fa-user-secret" style={{ fontSize: '1.75em' }} /></NavIcon>
                <NavText>Agents</NavText>
                <NavItem eventKey="/gpt/agent">
                    <NavText>Design</NavText>
                </NavItem>
                <NavItem eventKey="/gpt/chat">
                    <NavText>Chat</NavText>
                </NavItem>
                <NavItem eventKey="/gpt/databuilder">
                    <NavText>Data Builder</NavText>
                </NavItem>
            </NavItem>}
            {isAuthenticated && <NavItem eventKey="/hive">
                <NavIcon><img src="/img/noun-106249-FFFFFF.png" width={32} style={{ fontSize: '1.75em' }} alt="Hive Icon" /></NavIcon>
                <NavText>Hive</NavText>
                <NavItem eventKey="/hive/jobs">
                    <NavText>Jobs</NavText>
                </NavItem>
                <NavItem eventKey="/hive/runpod">
                    <NavText>Runpod</NavText>
                </NavItem>
            </NavItem>}

            {isAuthenticated ? (
                <NavItem eventKey="/profile">
                    <NavIcon><i className="fa fa-fw fa-user" style={{ fontSize: '1.75em' }}></i></NavIcon>
                    <NavText>Account</NavText>
                    <NavItem eventKey="/profile">
                        <NavText>Manage Profile</NavText>
                    </NavItem>
                    <NavItem eventKey="logout">
                        <NavText>Log Out</NavText>
                    </NavItem>
                </NavItem>
            ) : (
                <NavItem eventKey="login">
                    <NavIcon><i className="fa fa-fw fa-user" style={{ fontSize: '1.75em' }}></i></NavIcon>
                    <NavText>Account</NavText>
                    <NavItem eventKey="login">
                        <NavText>Log In</NavText>
                    </NavItem>
                </NavItem>
            )}
            {checkEntitlement('admin') && <NavItem eventKey="admin">
                <NavIcon><i className="fa fa-fw fa-toolbox" style={{ fontSize: '1.75em' }}></i></NavIcon>
                <NavText>Admin</NavText>
                <NavItem eventKey="/users">
                    <NavText>Users</NavText>
                </NavItem>
                <NavItem eventKey="/admin/proxies">
                    <NavText>Proxies</NavText>
                </NavItem>
                <NavItem eventKey="/admin/downloads">
                    <NavText>Downloads</NavText>
                </NavItem>
            </NavItem>}
        </SideNav.Nav>
    </SideNav>
}