// src/PresetGrid.js
import React, { useState } from 'react';
import './presetgrid.css';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate} from "react-router-dom";

function PresetGrid({ presets }) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    const filteredPresets = presets.filter(preset =>
        preset.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    function handleClick(preset) {
        if(preset.link) navigate(preset.link);
        if(preset.onClick) preset.onClick();
    }

    return (
        <div className="preset-grid">
            <Form.Control
                type="text"
                placeholder="Search presets..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="mb-3"  // Bootstrap margin-bottom class
            />
            <div className="grid">
                {filteredPresets.map((preset, index) => (
                    <div key={index} className="preset-box" onClick={() => handleClick(preset)}>
                        <div className={"preset-box-content"}>
                            <h3 className="preset-title">{preset.title}</h3>
                            <p className="preset-description">{preset.description}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default PresetGrid;
