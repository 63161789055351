import React from 'react';
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import DataTable from "../../components/table/table";


const DownloadAdminPage = () => {
    const headerImage = '/img/headers/admin.png'

    return (<PageHeader image={headerImage} title={"Admin"}
                        description={"Perform administrative tasks"}
                        breadcrumb={[
                            ["Home", "/"],
                            ["Admin", "/admin"],
                            ["Downloads", "/admin/downloads"]
                        ]} >
        <section>
            <DataTable
                listEndpoint={"downloads/list"}
                deleteEndpoint={"downloads/delete"}
                infoEndpoint={"downloads/info"}
                addEndpoint={"downloads/add"}
                modifyEndpoint={"downloads/modify"}
                fields={[
                    "type", "display_name", "description", "link", "download_link"
                ]}
            />
        </section>
    </PageHeader>);
}

export default DownloadAdminPage;