import React, {useState} from "react";
import Lightbox from "./components/CoolLightbox";
import ImageMosaic from "./components/ImageMosaic";
import GalleryFooter from "./components/CoolLightbox/components/GalleryFooter";

const ImageGallery = ({images, columns = -1, onSelectImage = (image, index) => false}) => {
    const [isOpen, setOpen] = useState(false);
    const [currentImageIndex, setCurrentIndex] = useState();
    const index = currentImageIndex === undefined ? 0 : currentImageIndex;


    if (images === undefined) images = []

    return !images || images.length === 0 ? (<span>No images.</span>) : (
        <div style={{zIndex: 2000}}>
            {/* react-photo-gallery */}
            <ImageMosaic
                images={images}
                columns={columns}
                handleClick={(e, {index}) => {
                    if (onSelectImage && onSelectImage(images[index], index)) return;

                    setCurrentIndex(index);
                    setOpen(true);
                }}
            />
            {/* react-spring-lightbox */}
            <Lightbox
                currentImageIndex={index}
                setCurrentIndex={setCurrentIndex}
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                images={images}
                renderFooter={() => (<GalleryFooter image={images[index]}/>)}
            />
        </div>
    );
};

export default ImageGallery;
