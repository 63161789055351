import React, {useEffect} from 'react';
import {Card} from "react-bootstrap";
import {artApiFetchAsync} from "../../hooks/artapi";

//

export default function PromptExample({prompt, onClick}) {
    const [image, setImage] = React.useState(undefined);

    async function fetchImage() {
        const images = await artApiFetchAsync("art", "limit=1","sort=timestamp", "order=desc",  "s=" + prompt);
        if (images.length > 0) {
            setImage(images[0]['url']);
        }
    }

    useEffect(() => {
        fetchImage();
    }, [prompt]);

    return <Card key={prompt} className="bg-dark"  style={{cursor: "pointer"}} onClick={onClick}>
        <Card.Body className={"create-card-flex-between-container"}>
            <div className={"create-card-flex-between-items-grow"}>
                {prompt}
            </div>
            {image && <img src={image} height={96} />}
        </Card.Body>
    </Card>
}