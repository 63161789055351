import {Dropdown} from "react-bootstrap";
import React from "react";
import {useCCGCollections} from "../../../hooks/ccg/ccgdata";

const CCGCollectionChooser = ({owner=null, selected, onSelected}) => {
    const {collections} = useCCGCollections(owner);
    const [collection, setCollection] = React.useState(selected);

    function handleCollectionSelection(doc) {
        setCollection(doc);
        if(onSelected) {
            onSelected(doc);
        }
    }

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                <span>{collection && collection.toString().toUpperCase() ? collection.toString().toUpperCase() : 'Select a collection'}</span>
            </Dropdown.Toggle>

            <Dropdown.Menu className='scrollable-menu' style={{maxHeight: "200px", overflowY: "auto"}}>
                {collections && Object.entries(collections).map(([key, doc]) => (
                    <Dropdown.Item key={key} onClick={() => handleCollectionSelection(key)}>
                        {key.toUpperCase()}
                    </Dropdown.Item>))}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default CCGCollectionChooser;