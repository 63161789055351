import React, {useEffect} from 'react';
import {motion} from 'framer-motion';
import {artApiFetchAsync} from "../../hooks/artapi";

const ServerActiveJobImage = ({node}) => {
    const [ image, setImage ] = React.useState("/img/sdplaceholder.jpeg");

    useEffect(() => {
        async function fetchImage() {
            const result = await artApiFetchAsync("jobs-v2/last-job-image", "node=" + node);
            if(null == result) {
                console.log("Error: null result");
            } else if(result.error) {
                console.log("Error: ", result.error);
            } else if(result.url) {
                setImage(result.url);
            }
        }

        fetchImage();
    }, []);

    useEffect(() => {
        console.log("Image: ", image);
    }, [image]);

    return (
        <motion.img src={image} alt="uploaded pic"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                    whileHover={{ opacity: 1 }}
                    className="imgslickz"
                    style={{height: "60vh"}}
        />
    )
}

export default ServerActiveJobImage;