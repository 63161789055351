import React from 'react';

export default ({microfooter=false, sticky=false}) => {

  function drawFooter() {
    return <>
        {!microfooter && !sticky && <div className='bg-footer'>
            <img src='/img/footer-img.jpg' alt='bg-footer'/>
        </div>}
      {!microfooter && <div className='row m-10-hor'>
        <div className='col-md-4'>
          <div className='footer-col'>
            <div className='heading'>
              <h2 className='font-weight-bold '>About Us</h2>
            </div>
            <div className='content'>
              <p>DoubTech AI is an advanced platform engineered around state-of-the-art generative artificial intelligence. Our objective is to seamlessly integrate AI-driven art generation, large language models (LLMs), and other emerging AI technologies to provide a sophisticated yet user-friendly tool for professionals and enthusiasts alike.</p>
            </div>
            <div className='link-call' onClick={()=> window.open("mailto:support@doubtech.com", "_self")}>Email at. support@doubtech.com</div>
          </div>
        </div>
        <div className='col-md-2'>
          <div className='footer-col'>
            <div className='heading'>
              Useful Links
            </div>
            <div className='content'>
              <div className='link' onClick={()=> window.open("https://www.midjourney.com/home/", "_blank")}>Midjourney</div>
              <div className='link' onClick={()=> window.open("https://github.com/CompVis/stable-diffusion", "_blank")}>Stable Diffusion</div>
              <div className='link' onClick={()=> window.open("https://openai.com/dall-e-2/", "_blank")}>Dalle-2</div>
              <div className='link' onClick={()=> window.open("https://openai.com/api/", "_blank")}>Open AI</div>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='footer-col'>
            <div className='heading'>
              Follow Us
            </div>
            <div className='content'>
              <p>Coming soon to social media</p>
              <div className='socialicon'>
                <span className="shine"></span>
                <i className="fa fa-facebook-f"></i>
              </div>
              <div className='socialicon'>
                <span className="shine"></span>
                <i className="fa fa-linkedin"></i>
              </div>
              <div className='socialicon'>
                <span className="shine"></span>
                <i className="fa fa-twitter"></i>
              </div>
              <div className='socialicon'>
                <span className="shine"></span>
                <i className="fa  fa-instagram"></i>
              </div>
            </div>
          </div>
        </div>
      </div>}
      <div className='subfooter'>
        <div className='row m-10-hor'>
          <div className='col-md-6'>
            <div className='content'>© Copyrights 2023 <span className='font-weight-bold'>Doubling Technologies</span> All rights reserved.</div>
          </div>
        </div>
      </div></>
  }

  return (sticky ? <div  style={sticky?{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: -1,
      }:{}}>{drawFooter()}</div> :
      <footer>{drawFooter()}</footer>
  );
}
