import React from 'react';
import {Link} from "react-router-dom";


export const IconButton = function(props) {
    return <div className="btn" onClick={props.onClick} style={{ display: 'flex', alignItems: 'center' }}>
        <span className={"mdicon"} style={{fontSize: "24"}}>{props.icon && React.createElement(props.icon)}</span>{props.label && <span>&nbsp;{props.label}</span>}
    </div>
};

export function StyledLinkButton({label, to=""}) {
    return (<Link className='btn' to={to}>
        <span className="shine"></span>
        <span>{label}</span>
    </Link>)
}

export default function StyledButton({label, link="", onClick=null, ...props}) {
    function handleClick() {
        if (null != onClick) {
            onClick();
        } else {
            window.open(link, "_self");
        }
    }

    return (<div className="btn" onClick={handleClick} >
        <span className="shine"></span>
        <span>{label}{props.children}</span>
    </div>)
}