import React, { useRef, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { JsonEditor } from 'jsoneditor-react18';
import 'jsoneditor-react18/es/editor.min.css';
import PageHeader from "../components/PageHeader/pageheadercontrol";
import StyledButton from "../components/styledbutton";
import PresetLoader from "../components/PresetLoader";
import { MdCopyAll, MdDownload } from "react-icons/md";

const WorkflowViewer = () => {
    const presetLoaderRef = useRef(null);
    const editorRef = useRef(null);
    const [jsonData, setJsonData] = useState({});
    const headerImage = '/img/headers/workflow-viewer.png';

    // Utility function to copy JSON data (workflow/prompt) to clipboard
    const copyToClipboard = (key) => {
        const json = editorRef.current?.jsonEditor.get();
        if (json && json[key]) {
            navigator.clipboard.writeText(JSON.stringify(json[key], null, 2))
                .then(() => alert(`${key.charAt(0).toUpperCase() + key.slice(1)} copied to clipboard!`))
                .catch(err => console.error(`Failed to copy ${key}: `, err));
        }
    };

    // Utility function to download JSON data (workflow/prompt) as a file
    const downloadJson = (key) => {
        const json = editorRef.current?.jsonEditor.get();
        if (json && json[key]) {
            const element = document.createElement("a");
            const file = new Blob([JSON.stringify(json[key], null, 2)], { type: 'application/json' });
            element.href = URL.createObjectURL(file);
            element.download = `${key}.json`;
            document.body.appendChild(element);
            element.click();
        }
    };

    const applyJsonData = (json) => {
        // Parse strings if necessary before applying JSON data
        if (typeof json.prompt === 'string') {
            json.prompt = JSON.parse(json.prompt);
        }
        if (typeof json.workflow === 'string') {
            json.workflow = JSON.parse(json.workflow);
        }
        editorRef.current.jsonEditor.set(json);
        setJsonData(json);
    };

    const onImport = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            try {
                const json = JSON.parse(e.target.result);
                applyJsonData(json);
            } catch (error) {
                console.error('Invalid JSON file');
            }
        };
        reader.readAsText(file);
    };

    const onPngImport = (metadata) => {
        console.log('PNG metadata loaded:', metadata);
        applyJsonData(metadata);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && presetLoaderRef.current) {
            presetLoaderRef.current.loadDroppedImage(file);
        }
    };

    const handleFileUpload = () => {
        if (presetLoaderRef.current) {
            presetLoaderRef.current.loadJson();
        }
    };

    const content = () => (
        <Container fluid>
            <Row>
                <Col md={6}>
                    <Card className="p-3" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} style={{ backgroundColor: '#333', color: '#fff' }}>
                        <Card.Body>
                            <h5>Upload or Drop a Picture or Workflow Json</h5>
                            <StyledButton onClick={handleFileUpload} label="Choose File" />
                            <div className="drop-area" style={{ marginTop: '20px', padding: '10px', border: '2px dashed #aaa', backgroundColor: '#222' }}>
                                <p>Drop an image here</p>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={6}>
                    <Card className="p-3" style={{ backgroundColor: '#333', color: '#fff' }}>
                        <Card.Body>
                            <h5>JSON Viewer</h5>
                            <JsonEditor ref={editorRef} value={jsonData} mode="view" />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <PresetLoader ref={presetLoaderRef} onFileLoaded={onImport} onPngMetadataLoaded={onPngImport} />
        </Container>
    );

    return (
        <PageHeader
            image={headerImage}
            title="Workflow Viewer"
            description="A tool to open exported images and workflow JSON files"
            breadcrumb={[
                ["Home", "/"],
                ["Workflow Viewer", "/workflow-viewer"]
            ]}
            menuleft={[
                { icon: MdCopyAll, label: "Copy Workflow", onClick: () => copyToClipboard('workflow') },
                { icon: MdDownload, label: "Download Workflow", onClick: () => downloadJson('workflow') },
                { icon: MdCopyAll, label: "Copy Prompt", onClick: () => copyToClipboard('prompt') },
                { icon: MdDownload, label: "Download Prompt", onClick: () => downloadJson('prompt') }
            ]}
        >
            {content()}
        </PageHeader>
    );
};

export default WorkflowViewer;
