import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsyncWithToast} from "../hooks/artapi";

const TagEditor = ({ images }) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [tagEdits, setTagEdits] = useState(0);

    const removeTag = (image, tag) => {
        // logic to remove the tag from the image
        console.log(`Removing ${tag} from ${image.id}`);
        // Filter tag from image.tags
        image.tags = image.tags.filter(t => t !== tag);
        setTagEdits(tagEdits + 1);

        async function removeTagFromImage(imageId, tag) {
            const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            await artApiFetchAuthAsyncWithToast(token, "tag/remove", {
                processingText: `Removing ${tag} from image.`,
                successText: `Removed ${tag} from image.`,
            }, "image=" + imageId, "tag=" + tag);
        }

        removeTagFromImage(image.id, tag)
    };

    useEffect(() => {}, [tagEdits]);

    return (
        <Table striped>
            <thead>
            <tr>
                <th>Image</th>
                <th>Tags</th>
            </tr>
            </thead>
            <tbody>
            {images.map((image, index) => (
                <tr key={index}>
                    <td>
                        <Image
                            src={image.src}
                            alt={image.id}
                            style={{ height: "100px", objectFit: "cover" }}
                            thumbnail
                        />
                    </td>
                    <td>
                        {image.tags?.map((tag, idx) => (
                            <Badge
                                className={"tag"}
                                key={idx}
                                pill
                                onClick={() => removeTag(image, tag)}
                                style={{ cursor: "pointer", marginRight: "5px" }}
                            >
                                {tag}
                            </Badge>
                        ))}
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
    );
};

export default TagEditor;
