import React from 'react';
import LiteGraphComponent from "../../../litegraph/litegraphcomponent";
import useWindowDimensions from "../../../../../hooks/WindowDimensions";

function GraphTab({agent, ...props}) {
    const { width: windowWidth, height: windowHeight } = useWindowDimensions();
    return (
        <LiteGraphComponent width={windowWidth - 200} height={windowHeight - 400} />
    );
}

export default GraphTab;