import React, { Component } from 'react';
import { toast } from "react-toastify";

export function getPngMetadata(file) {
    return new Promise((r) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const pngData = new Uint8Array(event.target.result);
            const dataView = new DataView(pngData.buffer);

            if (dataView.getUint32(0) !== 0x89504e47) {
                console.error("Not a valid PNG file");
                r();
                return;
            }

            let offset = 8;
            let txt_chunks = {};
            while (offset < pngData.length) {
                const length = dataView.getUint32(offset);
                const type = String.fromCharCode(...pngData.slice(offset + 4, offset + 8));
                if (type === "tEXt") {
                    let keyword_end = offset + 8;
                    while (pngData[keyword_end] !== 0) {
                        keyword_end++;
                    }
                    const keyword = String.fromCharCode(...pngData.slice(offset + 8, keyword_end));
                    const contentArraySegment = pngData.slice(keyword_end + 1, offset + 8 + length);
                    const contentJson = Array.from(contentArraySegment).map(s => String.fromCharCode(s)).join('');
                    txt_chunks[keyword] = contentJson;
                }
                offset += 12 + length;
            }
            r(txt_chunks);
        };

        reader.readAsArrayBuffer(file);
    });
}

class PresetLoader extends Component {
    fileInputRef = React.createRef();

    handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            try {
                const json = JSON.parse(e.target.result);
                this.props.onFileLoaded(json);
            } catch (error) {
                if (typeof this.props.onError === "function") this.props.onError(error);
                else toast.error(`Failed to load preset.\n${error}`);
            }
        };

        if (file.name.toLowerCase().endsWith(".png")) {
            getPngMetadata(file).then((txt_chunks) => {
                console.log(txt_chunks);
                this.props.onPngMetadataLoaded(txt_chunks);
            });
        } else {
            reader.readAsText(file);
        }
    }

    loadJson = () => {
        this.fileInputRef.current.click();
    }

    loadDroppedImage = (file) => {
        if (file.name.toLowerCase().endsWith(".png")) {
            getPngMetadata(file).then((txt_chunks) => {
                this.props.onPngMetadataLoaded(txt_chunks);
            });
        } else {
            const reader = new FileReader();
            reader.onload = (e) => {
                try {
                    const json = JSON.parse(e.target.result);
                    this.props.onFileLoaded(json);
                } catch (error) {
                    if (typeof this.props.onError === "function") this.props.onError(error);
                    else toast.error(`Failed to load preset.\n${error}`);
                }
            };
            reader.readAsText(file);
        }
    }

    render() {
        return (
            <input
                type="file"
                accept=".json,.png"
                ref={this.fileInputRef}
                onChange={this.handleFileChange}
                style={{ display: 'none' }}
            />
        );
    }
}

export default PresetLoader;
