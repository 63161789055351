import React, {useEffect, useRef} from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {Link} from "react-router-dom";
import UploadBlob from "../../components/uploadblob";
import {saveLayer, useCCGLayers} from "../../../hooks/ccg/ccgdata";
import {JsonEditor as Editor} from 'jsoneditor-react18';
import 'brace';
import 'brace/mode/json';
import 'brace/theme/github';
import {ProgressBar} from "react-bootstrap";
import PageHeader from "../../components/PageHeader/pageheadercontrol";

const CCGLayers = () => {
    const collection = "mtg";
    const {user, getAccessTokenSilently} = useAuth0();
    const {layers} = useCCGLayers(collection);
    const layerName = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)
    const [layer, setLayer] = React.useState(null);
    const [modCount, setModCount]= React.useState(0);
    const editorRef = useRef();
    const [saving, setSaving] = React.useState(false);


    const headerImage = '/img/headers/ccg.png'

    const users = {};


    console.log("Layer: ", layers[layer]);

    useEffect(() => {
        if(layers[layerName]) {
            setLayer(layers[layerName]);
        }
    }, [layerName, layers]);



    function onLayerChanged(layer, json) {
        layers[layer] = json;
        setLayer(json);
        console.log("Layer changed: ", layer, json);
        setModCount(modCount + 1);
    }

    function onNewLayerUploaded(url) {
        console.log("New layer uploaded: ", url);
        var index = url.lastIndexOf('%2F') + 3;
        var lastIndex = url.indexOf('?');
        var length = url.length - index;
        if(lastIndex !== -1) {
            length = lastIndex - index;
        }
        var name = url.substring(index, index + length);
        var extIndex = name.lastIndexOf('.');
        if(extIndex !== -1) {
            name = name.substring(0, extIndex);
        }
        console.log('name: ', name);

        onLayerChanged(name, {...layer, url: url, name: name});
    }

    function onImageUploaded(url) {
        console.log("Image uploaded: ", url);
        layer.url = url;
        layers[layer.id] = layer;
        editorRef.current.jsonEditor.set(layer);
        onLayerChanged(layer.id, layer);
    }

    function getName() {
        if(layer && layer.name) {
            return layer?.name?.toUpperCase();
        }

        return "UNNAMED"
    }

    async function onSaveLayer() {
        setSaving(true);
        console.log("Saving layer: ", layer);
        if(layer) {
            const token = await getAccessTokenSilently();
            await saveLayer(token, layer.name, collection, layer);
        }
        setSaving(false);
    }

    return (
        <PageHeader image={headerImage} title={"Layers"}
                    description={"Create layers to provide layered content that creates the structure of your cards."}
                    breadcrumb={[
                        ["Home", "/"],
                        ["CCG", "/ccg"],
                        ["Layers", "/ccg/layers"]
                    ]} >

      <section className='container-fluid' id="service">
          <div className='row m-10-hor'>
              <div className='col-md-8'>
                  <div className='left'>
                      {!layer && (<div className='content'>
                          <div className='title'>
                              Layer Design
                          </div>
                          <div className='desc'>
                              <p>Layers provide layered content that creates the structure of your cards.</p>
                              <p>To get started, upload a layer or select one from the right.</p>
                          </div>
                      </div>)}
                      {layer && (<div className='content'>
                          <div className='title'>
                                {getName()}
                              <button className='btn' onClick={() => onSaveLayer()}><span>Save</span></button>
                          </div>
                          <UploadBlob types={['image/png', 'image/jpeg']} collection={"images"} label="Upload Image" onUploaded={onImageUploaded} />

                          <div className='desc' style={{position: "relative"}}>
                              <div style={{position: "relative"}}>
                                  {saving ? (<ProgressBar label="Saving..."/>) : (<Editor ref={editorRef} value={layer}
                                                                                          theme="ace/theme/github"
                                                                                          onChange={json => onLayerChanged(layer, json)} />)}
                                  </div>
                              <div style={{position: "relative"}}>
                                <div style={{position: "absolute", top: "50%", left: "50%", margin: "8px", transform: "translate(-50%)"}}>
                                {layer.url && (<img width="300px" src={layer.url}/>)}
                                </div>
                              </div>
                          </div>
                      </div>)}
                  </div>
              </div>
              <div className='col-md-4'>
                  <div className='right'>
                      {user && <UploadBlob types={['application/json', 'image/png', 'image/jpeg']} collection={"layers"} label="Upload Layers" onUploaded={u => onNewLayerUploaded(u)} />}
                      <div>
                          <ul className='services-list'>
                              {layers && Object.entries(layers).map(([key, doc]) => (<li key={key}><Link to={'/ccg/layers/' + key}>{doc.name?.toUpperCase() ?? '* ' + key.replace('::', ' ')}</Link></li>))}
                          </ul>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  </PageHeader>
);
}

export default CCGLayers;