import React, {useRef, useState} from 'react';
import {Alert, Button, Card, Form} from 'react-bootstrap';
import {useAuth} from "../../hooks/authcontext";
import {Link} from "react-router-dom";

export default function Signup() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const { signup } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        if(passwordRef.current.value != passwordConfirmRef.current.value) {
            return setError("Passwords do not match");
        } else {
            try {
                setError("");
                setLoading(true);
                await signup(emailRef.current.value, passwordRef.current.value);
                //navigate("/", true);
            } catch (err) {
                console.log(err);
                setError('Failed to create an account: ' + err);
            }
        }
        setLoading(false);
    }

    return (
        <>
            <Card className="bg-dark m-10-hor col-md-10">
                <Card.Body>
                    <h2 className="text-center mb-4">Sign Up</h2>
                </Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Form.Group id="password">
                        <Form.Label>Password</Form.Label>
                        <Form.Control type="password" ref={passwordRef} required />
                    </Form.Group>
                    <Form.Group id="password-confirm">
                        <Form.Label>Password Confirmation</Form.Label>
                        <Form.Control type="password" ref={passwordConfirmRef} required />
                    </Form.Group>
                    <Button disabled={loading} className="w-100" type="submit">Sign Up</Button>
                </Form>
                <br/>
            </Card>
            <div className="w-100 text-center mt-2">
                Already have an account? <Link to={'/login'}>Login</Link>
            </div>
        </>
    )
}