import React from 'react';
import {Form} from "react-bootstrap";
import {toast} from "react-toastify";
import {useEndpoint} from "../../../../../hooks/api";
import ModalDialog from "../../../dialog/modaldialog";

function AddApiKeyDialog({show, onClose, agent, onApiKeyCreated, ...props}) {
    const {fetchAuth: createApiKeyRequest} = useEndpoint("gpt/keys/keygen");
    const [ apiKeyDescription, setApiKeyDescription ] = React.useState('');

    function handleCreateApiKey() {
        async function fetchResponse() {
            const messageResponse = await createApiKeyRequest({
                "id": agent.id,
                "description": apiKeyDescription
            });
            // if error in response, show error
            if("error" in messageResponse) {
                toast.error(`Failed to create API key for agent ${agent.name}.\n${messageResponse.error}`);
            } else {
                console.log(messageResponse);
                toast.success("API key created.");
                setApiKeyDescription('');
                onApiKeyCreated();
            }
        }

        fetchResponse();
    }

    return (
        <ModalDialog show={show} title={"Add API Key"} acceptLabel={"Create"} onAccept={handleCreateApiKey} onClose={onClose}>
            <Form onSubmit={handleCreateApiKey}>
                <Form.Group controlId="content">
                    <Form.Label>Name:</Form.Label>
                    <Form.Control value={apiKeyDescription} onChange={(event) => setApiKeyDescription(event.target.value)} />
                </Form.Group>
            </Form>
        </ModalDialog>
    );
}

export default AddApiKeyDialog;