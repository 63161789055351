import React, {useRef, useState} from 'react';
import {Alert, Button, Card, Form} from 'react-bootstrap';
import {useAuth} from "../../hooks/authcontext";
import {Link} from "react-router-dom";

export default function Signup() {
    const emailRef = useRef();
    const { resetPassword } = useAuth();
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setMessage("");
            setError("");
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage('Check your inbox for further instructions');
        } catch (err) {
            console.log(err);
            setError('Failed to reset password');
        }
        setLoading(false);
    }

    return (
        <>
            <Card className="bg-dark m-10-hor col-md-10">
                <Card.Body>
                    <h2 className="text-center mb-4">Reset Password</h2>
                </Card.Body>
                {error && <Alert variant="danger">{error}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" ref={emailRef} required />
                    </Form.Group>
                    <Button disabled={loading} className="w-100" type="submit">Reset Password</Button>
                </Form>
                <div className={"w-100 text-center mt-2"}>
                    <Link to={'/login'}>Login</Link>
                </div>
                <br/>
            </Card>
            <div className="w-100 text-center mt-2">
                Need an account? <Link to={'/signup'}>Sign Up</Link>
            </div>
        </>
    )
}