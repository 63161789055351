import React from 'react';
import {Dropdown, DropdownButton, Form} from "react-bootstrap";
import ModalDialog from "../../dialog/modaldialog";
import {artApiFetchAuthAsync} from "../../../../hooks/artapi";
import {toast} from "react-toastify";
import {useEndpoint} from "../../../../hooks/api";
import NamespaceDropdown from "./dropdowns/NamespaceDropdown";
import {useNavigate} from "react-router-dom";

function AddAgentDialog({show, onCreateAgent, onClose}) {
    const navigate = useNavigate();
    // Agent Name Fields
    const [newAgentName, setNewAgentName] = React.useState('');
    const [newAgentIsPrivate, setNewAgentIsPrivate] = React.useState(false);
    const [newAgentDescription, setNewAgentDescription] = React.useState('');
    const [newAgentNamespace, setNewAgentNamespace] = React.useState('');
    const { fetchAuth: createAgentRequest} = useEndpoint("gpt/create-agent");

    function handleCreateAgent() {
        async function fetchResponse() {
            const messageResponse = await createAgentRequest({
                "agent": newAgentName,
                "namespace": newAgentNamespace,
                "isPrivate": newAgentIsPrivate,
                "description": newAgentDescription
            });

            if("error" in messageResponse) {
                toast.error(`Failed to create agent ${newAgentName}.\n${messageResponse.error}`);
            } else {
                toast.success("Agent created.\n" + newAgentName);
                setNewAgentName('');
                onCreateAgent(messageResponse.id);

                // Navigate to the new agent
                navigate(`/gpt/agent/${messageResponse.id}`);
            }
        }

        fetchResponse();
    }

    return (
        <ModalDialog show={show} title={"Add Agent"} acceptLabel={"Create"} onAccept={handleCreateAgent} onClose={onClose}>
            <Form.Group controlId="content">
                <Form.Label>Name</Form.Label>
                <Form.Control value={newAgentName} onChange={(event) => setNewAgentName(event.target.value)} />
            </Form.Group>

            <NamespaceDropdown value={newAgentNamespace} onChange={(namespace) => setNewAgentNamespace(namespace)} />

            <Form.Group controlId="content">
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" value={newAgentDescription} onChange={(event) => setNewAgentDescription(event.target.value)} />
            </Form.Group>

            <Form.Group controlId="content">
                <Form.Check label={"Private"} type="checkbox" checked={newAgentIsPrivate} onChange={(event) => setNewAgentIsPrivate(event.target.checked)} />
            </Form.Group>
        </ModalDialog>
    );
}

export default AddAgentDialog;