import React, {useState} from 'react';
import MessageList from "../../../gpt/contentlist";
import {MdAddBox} from "react-icons/all";
import {useEndpoint} from "../../../../../hooks/api";
import {artApiFetchAuthAsync, artApiPostAuthAsync, useAuthenticatedArtApi} from "../../../../../hooks/artapi";
import {toast} from "react-toastify";
import AddContentDialog from "../AddContentDialog";

function AgentContentTab({agent, showAddContent}) {
    const { response: userInfo, error: userError } = useAuthenticatedArtApi("user-info");
    const [ messages, setMessages ] = React.useState([]);
    const { fetchAuth: listContent } = useEndpoint("gpt/list-content");
    const { postBodyAuth: updateContentPost } = useEndpoint("gpt/update-content");
    const { fetchAuth: deleteContentRequest } = useEndpoint("gpt/delete-content");
    const [showAddContentDialog, setShowAddContent] = useState(showAddContent);

    async function fetchMessages() {
        console.log("Fetching messages...");
        const messageResponse = await listContent({
            "agent": agent.name,
            "namespace": agent.namespace
        });
        if(messageResponse) {
            // sort the messageResponse by sort_order
            messageResponse.sort((a, b) => (a.sort_order > b.sort_order) ? 1 : -1);

            setMessages(messageResponse);
        }
    }

    React.useEffect(() => {
        fetchMessages();
    }, [agent]);

    const onDragEnd = (result) => {
        // Check if the item was dropped outside the list
        if (!result.destination) {
            return;
        }

        const newMessages = [...messages];
        const [reorderedItem] = newMessages.splice(result.source.index, 1);
        newMessages.splice(result.destination.index, 0, reorderedItem);

        // Update the sort_order property of the messages based on their new order
        for (let i = 0; i < newMessages.length; i++) {
            newMessages[i].sort_order = i + 1;
        }

        updateMessages(newMessages);

        setMessages(newMessages);
    };

    async function updateMessages(messages) {
        const messageResponse = updateContentPost(messages, {"id": agent.id});
        if(messageResponse) {
            await fetchMessages();
        }
    }

    function handleContentChange(id, message) {
        // Get message with id 7
        const index = messages.findIndex((message) => message.id === id);
        message = messages[index];
        // Send the message to the server
        async function fetchResponse() {
            const messageResponse = updateContentPost([message], {"id": agent.id});
            if("error" in messageResponse) {
                toast.error("Failed to update message", messageResponse.error);
                return;
            }
            if(messageResponse) {
                await fetchMessages();
            }
        }

        fetchResponse();
    }

    const handleDeleteMessage = (id) => {
        async function fetchResponse() {
            const messageResponse = await deleteContentRequest({
                "id": id,
            });
            if("error" in messageResponse) {
                toast.error(`Failed to delete message ${id}.\n${messageResponse.error}`);
                return;
            }
            if(messageResponse) {
                await fetchMessages();
            }
        }

        setMessages((prevMessages) => {
            const newMessages = prevMessages.filter((message) => message.id !== id);
            fetchResponse();
            return newMessages;
        });
    };

    return (
        <>
            {messages && <div>
                <MessageList
                    messages={messages}
                    onDragEnd={onDragEnd}
                    onContentChange={handleContentChange}
                    onDeleteMessage={handleDeleteMessage}
                />
                {userInfo && userInfo.email === agent.owner && <div className={"create-card-flex-between-container"}>
                    <div className={"create-card-flex-between-items-grow"}/>{/* Spacer */}
                    <MdAddBox className={"mdicon mt-2"} style={{cursor: "pointer"}} onClick={() => {setShowAddContent(true)} } />
                </div>}
            </div>}

            <AddContentDialog agent={agent} show={showAddContentDialog} onAddContent={fetchMessages} onClose={() => setShowAddContent(false)} />
        </>
    );
}

export default AgentContentTab;