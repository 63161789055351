import React, {useState} from 'react';
import {Button, Form, Modal} from 'react-bootstrap';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import {JsonEditor as Editor} from "jsoneditor-react18";
import './json-editor-dark.css'; // import custom CSS for dark theme


const SavePrompt = ({ showModal, onSave, onCancel }) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [category, setCategory] = useState("");
    const [name, setName] = useState("");

    const handleClose = () => onCancel();

    const handleSave = () => {
        async function save() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "prompt-formulas/create",
                "category=" + encodeURIComponent(category),
                "name=" + encodeURIComponent(name),
                "prompt=" + encodeURIComponent(JSON.stringify(showModal)));
            // if error in response, show error
            if("error" in messageResponse) {
                toast.error(`Failed to save prompt.\n${messageResponse.error}`);
            } else {
                toast.success("Saved prompt");
                onSave();
            }
        }

        save();
    }

    return (
        <>
            <Button variant="primary" onClick={() => handleSave()}>
                Save
            </Button>

            <Modal show={showModal !== undefined} onHide={handleClose} >
                <Modal.Header>
                    <Modal.Title>Save Prompt Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Editor value={showModal} theme={"dark"} />
                    <span>Name: <Form.Control value={name} onChange={ev => setName(ev.target.value)} /></span>
                    <span>Category: <Form.Control value={category} onChange={ev => setCategory(ev.target.value)} /></span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SavePrompt;
