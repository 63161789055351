import React from 'react';
import {Form} from "react-bootstrap";
import ModalDialog from "../../dialog/modaldialog";
import {useEndpoint} from "../../../../hooks/api";
import {toast} from "react-toastify";
import {useUserInfo} from "../../../../hooks/userinfo";

function AddContentDialog({agent, show, onAddContent, onClose}) {
    const { fetchAuth: addContentRequest} = useEndpoint("gpt/add-content");
    const [messageRole, setRole] = React.useState('user');
    const [messageContent, setContent] = React.useState('');
    const {userInfo} = useUserInfo();

    function addContent() {
        // The prompts you describe should all provide visual descriptions of everything seen as you would describe it to an artist.

        async function fetchResponse() {
            const messageResponse = await addContentRequest({
                "id": agent.id,
                "role": messageRole,
                "content": messageContent
            });
            // If error in response, show error
            if("error" in messageResponse) {
                toast.error(messageResponse.error);
                return;
            } else {
                onAddContent();
                setContent("");
            }
        }

        fetchResponse();
    }

    return (
        <ModalDialog show={show} title={"Add Content"} acceptLabel={"Add"} onAccept={addContent}
                     onClose={() => onClose()}>
            {agent && userInfo && userInfo.email == agent.owner ?
                <div>
                    <Form>
                        <Form.Group controlId="role">
                            <Form.Label>Role:</Form.Label>
                            <Form.Control as="select" value={messageRole}
                                          onChange={event => setRole(event.target.value)}>
                                <option value="user">User</option>
                                <option value="system">System</option>
                                <option value="assistant">Assistant</option>
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="content">
                            <Form.Label>Content:</Form.Label>
                            <Form.Control as="textarea" rows={3} value={messageContent}
                                          onChange={(event) => setContent(event.target.value)}/>
                        </Form.Group>
                    </Form>
                </div>
            : <div>Only the owner of this agent can add content.</div>}
        </ModalDialog>
    );
}

export default AddContentDialog;