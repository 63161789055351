import React, {useEffect} from 'react';
import useCCGStorage from "../../../hooks/ccg/ccgstorage";
import {ProgressBar} from "react-bootstrap";

const Uploader = ({files, setFiles, onUploaded, collection}) => {
    console.log("UploaderFiles: " + files);
    const {url, progress} = useCCGStorage(files, collection, collection);

    useEffect(() => {
        if(url) {
            setFiles(null);
            if(onUploaded) onUploaded(url);
        }
    }, [url, setFiles, onUploaded]);

    return (
        <ProgressBar>
            {progress && progress.map((p, index) => (<ProgressBar key={index} animated now={progress} />))}
        </ProgressBar>
    );
}

export default Uploader;