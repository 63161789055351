import React from 'react';
import AgentChatWindow from "../../../chat/agentChatWindow";
import useWindowDimensions from "../../../../../hooks/WindowDimensions";

function ChatTab({agent, ...props}) {
    const { height: windowHeight, width: windowWidth } = useWindowDimensions();

    return (
        <AgentChatWindow
            agent={agent}  scroll={true}
            showBackground={false}
            height={windowHeight - 575}
        />
    );
}

export default ChatTab;