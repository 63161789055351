import React from 'react';
import ImageMosaic from "../../components/ImageGallery/components/ImageMosaic";

const RightColumnImageGrid = ({images, setSelectedImg, columns = undefined}) => {
    return (
        <ImageMosaic

            handleClick={(e, { index }) => {
                setSelectedImg(images[index]);
            }}
            images={images} columns={columns} />
    );
}

export default RightColumnImageGrid;