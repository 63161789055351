import React, {useEffect, useState} from 'react';
import { Form, Button } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync, useAuthenticatedArtApi} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import {useEndpoint} from "../../../hooks/api";
import StyledButton from "../styledbutton";

const NewRunpodForm = ({ onCreate, createKeyEndpoint = "art-api/runpod/add"}) => {
    const { response: userInfo, error: userError } = useAuthenticatedArtApi("user-info");
    const { fetchAuth: createKey } = useEndpoint(createKeyEndpoint);

    const [name, setName] = useState(null);
    const [id, setId] = useState(null);
    const [description, setDescription] = useState(null);
    const [isPrivate, setIsPrivate] = useState(null);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleIdChange = (event) => {
        setId(event.target.value);
    };

    const handleDescirptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleSubmit = (event) => {
        if(!name) {
            toast.error("Name is required");
            return;
        }

        if(!id) {
            toast.error("Id is required");
            return;
        }

        async function handleCreateKey() {
            const key = await createKey({
                "name": name,
                "id": id,
                "description": description,
                "private": isPrivate
            });
            if('error' in key) {
                toast.error(key.error);
            } else {
                onCreate(key);

                setName('');
                setDescription('');
                setId('');
                setIsPrivate(true);
            }
        }

        handleCreateKey();
    };

    return (
        <Form inline onSubmit={handleSubmit}>
            <Form.Group controlId="formKeyName">
                <Form.Label>Id&nbsp;</Form.Label>{' '}
                <Form.Control
                    type="text"
                    placeholder="Enter id"
                    value={id}
                    onChange={handleIdChange}
                    required
                />
            </Form.Group>{' '}
            <Form.Group controlId="formKeyName">
                <Form.Label>Name&nbsp;</Form.Label>{' '}
                <Form.Control
                    type="text"
                    placeholder="Enter key name"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
            </Form.Group>{' '}
            <Form.Group controlId="formKeyName">
                <Form.Label>Description&nbsp;</Form.Label>{' '}
                <Form.Control
                    type="textarea"
                    placeholder="Enter description"
                    value={description}
                    onChange={handleDescirptionChange}
                    required
                />
            </Form.Group>{' '}
            <Form.Group controlId="formIsPrivate">
                <Form.Label>&nbsp;&nbsp;Private&nbsp;</Form.Label>{' '}
                <Form.Check
                    type="checkbox"
                    checked={isPrivate}
                    onChange={(e) => setIsPrivate(e.target.checked)}
                />
            </Form.Group>{' '}

            <StyledButton variant="success" onClick={handleSubmit}>
                <MdAdd /> Create
            </StyledButton>
        </Form>
    );
};

export default NewRunpodForm;
