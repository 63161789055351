import React from 'react';
import { useParams} from "react-router-dom";
import ArtPage from "../components/artgallery/ArtPage";


const ModelHome = () => {
    const { model } = useParams();

    return (<ArtPage
        queryParameters={{"model": model}}
        title={model.toUpperCase()}
        contentDescription={`AI Art generated using the ${model} model.`}
        model={model}
    />);
}

export default ModelHome