import React, {useEffect} from 'react';
import {useLoading} from "../components/loading";
import {useAuth0} from "@auth0/auth0-react";
import {startSession} from "../../hooks/artapi";

function LoadingAuthUpdater(props) {
    const { setIsAuthenticated, setFatalError, setAuthComplete } = useLoading();
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        async function completeAuth() {
            try {
                await startSession();
                setAuthComplete(true);
            } catch(e) {
                console.error("Failed to start session!", e);
                setFatalError("Unable to connect.");
            }
            setIsAuthenticated(isAuthenticated);
        }

        if(isAuthenticated && user) {
            completeAuth();
        } else if(!isAuthenticated) {
            completeAuth();
        }
    }, [isAuthenticated, user]);

    return (
        <></>
    );
}

export default LoadingAuthUpdater;