// src/custom_highlight.js
import ace from 'ace-builds/src-noconflict/ace';

ace.define(
    'ace/mode/custom_highlight_rules',
    ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/text_highlight_rules'],
    function (require, exports, module) {
        const oop = require('ace/lib/oop');
        const TextHighlightRules = require('ace/mode/text_highlight_rules').TextHighlightRules;

        const CustomHighlightRules = function () {
            this.$rules = {
                start: [
                    {
                        token: 'variable.content', // Change this line
                        regex: /\{([^}]+)\}/,
                    },
                ],
            };
        };

        oop.inherits(CustomHighlightRules, TextHighlightRules);
        exports.CustomHighlightRules = CustomHighlightRules;
    }
);
