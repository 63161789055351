import React from "react";
import PropTypes from "prop-types";
import Gallery from "react-photo-gallery";
import GridImage from "./components/GridImage";

function calculateColumns(containerWidth) {
  let columns = 1;
  if (containerWidth >= 500) columns = 4;
  if (containerWidth >= 900) columns = 6;
  return columns;
}

const ImageMosaic = ({ images, handleClick, columns = undefined, minColumns=undefined }) => (
  <div className='gallery-container'>
    <Gallery
      columns={containerWidth => {
        if(columns && columns > 0) return columns;
        return calculateColumns(containerWidth);
      }}
      onClick={handleClick}
      photos={images}
      margin={5}
      padding={0}
      direction="column"
      renderImage={GridImage}
    />
  </div>
);

ImageMosaic.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      alt: PropTypes.string.isRequired,
      caption: PropTypes.string.isRequired,
      height: PropTypes.number.isRequired,
      width: PropTypes.number.isRequired,
      src: PropTypes.string.isRequired
    })
  ),
  handleClick: PropTypes.func.isRequired
};

export default ImageMosaic;


