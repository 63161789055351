import React, {useEffect} from 'react';
import {useEndpoint} from "../../../../hooks/api";
import {Dropdown, DropdownButton} from "react-bootstrap";
import {artApiFetchAuthAsync} from "../../../../hooks/artapi";

function Models({agent, onModelChanged, ...props}) {
    const [models, setModels] = React.useState([]);
    const { fetchAuth: listModels } = useEndpoint("gpt/list-models");

    async function fetchModels() {
        if(agent) {
            console.log("Fetching models for", agent.name);
            const models = await listModels({"service": agent.service});
            if (models) {
                setModels(models);
            }
        }
    }

    async function updateModel(model) {

        // Trigger onModelChanged event
        onModelChanged(model);
    }

    useEffect(() => {
        fetchModels();
    }, [agent?.id]);

    return (
        <div>
            <strong>Model:</strong>
            <DropdownButton id="dropdown-item-button" title={agent.model ?? "Select Model"}>
                {models && models.map(model =>
                    <Dropdown.Item key={model} as="button"
                                   onClick={() => updateModel(model)}>{model}</Dropdown.Item>
                )}
            </DropdownButton>
        </div>
    );
}

export default Models;