// Import necessary libraries and components
import React, { useState, useEffect } from 'react';
import { Motion, spring } from 'react-motion';
import {useNavigate} from "react-router-dom";
import './dropdown.css';

const Dropdown = React.forwardRef(({ menuItems, onSelected }, ref) => {
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState(menuItems);
    const [menuStack, setMenuStack] = useState([menuItems]);
    const [menuHeight, setMenuHeight] = useState(null);

    useEffect(() => {

    }, [activeMenu, menuStack]);

    function onClicked(item, goToMenu = undefined) {
        try {
            console.log("OncClicked: ", item, goToMenu, menuStack);
            if (goToMenu !== undefined) {
                setActiveMenu(menuStack[goToMenu]);
                setMenuStack(menuStack.slice(0, goToMenu + 1));
            } else if (item.submenu) {
                setActiveMenu(item.submenu);
                setMenuStack([...menuStack, item.submenu]);
            } else if (item.onClick) {
                if (onSelected && !item.onClick()) onSelected();
            } else {
                navigate(item.link);
                if (onSelected) onSelected();
            }
        } catch (e) {
            console.log("Error: ", e);
        }
    }

    function DropdownItem({ item, goToMenu=undefined }) {
        return (
            <div className="dm-menu-item" onClick={(e) => {onClicked(item, goToMenu)}}>
                {item.icon && <span className="dm-icon-button">{item.icon && <item.icon />}</span>}
                {item.label}
                {item.submenu && <span className="dm-icon-right">{'>'}</span>}
            </div>
        );
    }

    function renderMenuItems(items, menuKey) {
        return (
            <div className={`dm-menu menu-${menuKey} ${activeMenu === menuKey ? 'active' : ''}`}>
                {menuStack.length > 1 && <DropdownItem item={{ label: 'Back', icon: null }} goToMenu={menuStack.length - 2} />}
                {items.map((item, index) => (
                    <div key={index} className={"dm-menu-item"} onClick={() => {onClicked(item)}}>
                        {item.icon && <span className="dm-icon-button">{item.icon && <item.icon />}</span>}
                        {item.label}
                        {item.submenu && <span className="dm-icon-right">{'>'}</span>}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className="dm-dropdown" style={{ height: menuHeight }} ref={ref}>
            {renderMenuItems(activeMenu, 0)}
        </div>
    );
});

export default Dropdown;
