import React, {useCallback, useEffect} from 'react';
import PageHeader from "../../components/PageHeader/pageheadercontrol";
import RunpodList from "../../components/runpod/runpodlist";

const Runpod = () => {
    const headerImage = '/img/headers/users.png'

    function content() {
        return (<RunpodList />);
    }

    return (
        <PageHeader image={headerImage}
                    title=""
                    description={""}
                    breadcrumb={[
                        ["Home", "/"],
                        ["Hive", "/hive"],
                        ["Runpod", "/hive/runpod"]
                    ]}>

            {content()}
        </PageHeader>)
}

export default Runpod;