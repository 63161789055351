import {useEffect, useRef, useState} from 'react';

export default function useInterval(callback, delay, immediate=false) {
    const savedCallback = useRef();
    const [immediateState, setImmediate] = useState(immediate);

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;

        if(immediateState) {
            savedCallback.current();
            setImmediate(false);
        }
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }

        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
