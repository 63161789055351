import {useEffect, useState} from "react";
import {artApiFetchAsync} from "./artapi";

export const fetchRandomImage = async (model="") => {
    return await artApiFetchAsync("art",
        "order=random",
        "limit=1",
        "q=model=" + model).then(r => r[0]['url']);
}

export const useRandomImage =  (model="", seed = 0) => {
    const [image, setRandomImage] = useState("");

    useEffect(() => {
        async function fetchData() {
            const url = await fetchRandomImage(model);
            setRandomImage(url);
        }
        fetchData();
    }, [model, seed]);
    return [image]
}

export default useRandomImage;