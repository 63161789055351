import React, {useEffect, useState} from 'react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import './preset.css';
import {Form, InputGroup, Spinner} from "react-bootstrap";
import PromptVariables from "../../components/artapi/promptvariables";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import useWindowDimensions from "../../../hooks/WindowDimensions";
import {useEndpoint} from "../../../hooks/api";
import ImageGallery from "../../components/ImageGallery";

function Preset(props) {
    let { key } = useParams();
    const [prompt, setPrompt] = useState('');
    const [preset, setPreset] = useState(null);
    const [state, setState] = useState({
        isPaneOpen: false,
        isPaneOpenLeft: false,
    });
    const [images, setImages] = useState([{"url": "http://doubtech.ai/img/logo.png"}]);
    const {height: windowHeight, width: windowWidth} = useWindowDimensions();
    const { fetchAuth: fetchPreset } = useEndpoint("stable-diffusion/preset-info");
    const { postBody: postJob, postBodyAuth: postJobAuth, fetch: fetchJob } = useEndpoint("art-api/job");
    const [variables, setVariableData] = useState({});
    const [id, setId] = useState(key ?? props.id);
    const [showTitle, setShowTitle] = useState(props.showTitle === undefined ? true : props.showTitle);

    useEffect(() => {

    }, [id]);

    function clearImage() {
        setImages([{"url": "http://doubtech.ai/img/logo.png"}]);
    }

    async function fetchImage() {
        setImages(null);
        try {
            // If he key is not a key, then it's a preset id if there are - in the string and if so, fetch the key
            const isKey = key &&  key.includes("-");
            let fetchRequest = isKey ? {"key": key} : {"preset": id};

            const initialRequest = {...fetchRequest, ...variables, "prompt": prompt};
            console.log("Initial request params: ", initialRequest);
            let result = await fetchJob(initialRequest);

            if("error" in result) {
                toast.error("Generation failed.\n" + result.error);
                clearImage();
                return;
            }

            // wait until the image is ready, check status every 5 seconds
            while (result.status === "queued" || result.status === "processing") {
                await new Promise(r => setTimeout(r, 5000));
                result = await fetchJob({...initialRequest, "id": result.id});
            }

            console.log("result", result);

            if (result.status === "complete") {
                var image = result.url;
                // Wait until the image url doesn't return a 4xx error
                // Use fetch to check the status and headers of the image url until the the status is 200
                // or the headers contain the content-type image
                let response = await fetch(image);
                while (!response.ok || !response.headers.get("content-type").includes("image")) {
                    await new Promise(r => setTimeout(r, 5000));
                    response = await fetch(image);
                }

                if (result.images?.length > 1) {
                    setImages(result.images);
                } else {
                    setImages([{"url": image}]);
                }
            } else {
                toast.error("Generation failed.\n" + result.status);
                clearImage();
            }
        } catch(e) {
            console.log("Error", e);
            toast.error("Generation failed.\n" + e);
            clearImage();
        }
    }

    async function fetchPresetAsync() {
        if (id) {
            // If he key is not a key, then it's a preset id if there are - in the string and if so, fetch the key
            const isKey = id.includes("-");
            let fetchRequest = isKey ? {"key": id} : {"id": id};
            const preset = await fetchPreset(fetchRequest);
            if ("error" in preset) {
                toast.error("Failed to fetch preset.\n" + preset.error);
            } else {
                setPreset(preset);
            }
        }
    }
    useEffect(() => {
        fetchPresetAsync();
    }, [key]);

    function drawImages() {
        if(images.length === 0) {
            return <div className={"centered-content"}>No images found</div>;
        } else if (images.length === 1) {
            return <img src={images[0].url} alt="Centered Content" style={{
                height: '100%',
                objectFit: 'contain', // Preserves aspect ratio
                width: '100%'
            }}/>
        } else {
            // Draw gallery with images
            const imageList = images.map((image, index) => {
                return {
                    src: image.url,
                    width: 500,
                    height: 500
                };
            });
            // create a grid of images with divs not the image gallery that fits the available space
            return (
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
                    gap: '10px',
                    gridAutoRows: 'minmax(100px, auto)'
                }}>
                    {images.map((image, index) => (
                        <div key={index} style={{width: '100%', height: '100%', overflow: 'hidden'}}>
                            <img src={image.url} alt={`Image ${index}`}
                                 style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
                        </div>
                    ))}
                </div>
            );


        }
    }

    return (
        <div>
            <div style={{padding: 24}}>
                <InputGroup>
                    <Form.Control
                        placeholder="Prompt"
                        aria-label="Prompt"
                        aria-describedby="basic-addon2"
                        value={prompt}
                        onChange={e => setPrompt(e.target.value)}
                        onKeyPress={e => {
                            if (e.key === 'Enter' || e.charCode === 13) {
                                fetchImage();
                            }
                        }}
                    />
                </InputGroup>
            </div>
            <div style={{
                position: 'absolute',
                top: '50%',
                left: '-40',
                transform: 'translateY(-50%)'
            }}>
                <span style={{marginRight: '10px', fontSize: '90px', cursor: "pointer"}} onClick={() => setState({isPaneOpenLeft: true})}>&gt;</span>
            </div>
            <SlidingPane
                isOpen={state.isPaneOpenLeft}
                title="Preset Properties"
                from="left"
                width="800px"
                style={{left: "200px", background: "#333333"}}
                onRequestClose={() => setState({isPaneOpenLeft: false})}
            >
                <div style={{margin: "24", marginLeft: 68}}>
                    <PromptVariables
                        preset={preset}
                        onVariablesChanged={(v) => {
                            // Get the {name: value} pairs from the variables if there is a value set
                            const variableData = {};
                            // Iterate over each key value pair in the object
                            Object.keys(v).forEach(key => {
                                // If the value is not empty, add it to the variableData object
                                if (v[key]) {
                                    variableData[key] = v[key].value ?? v[key].default;

                                    if(key === "seed" && (variableData[key] == "random" || variableData[key] == "randomize")) {
                                        variableData[key] = Math.floor(Math.random() * 1000000);
                                    }
                                }
                            });

                            setVariableData(variableData);
                        }}
                    />
                </div>
            </SlidingPane>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: windowHeight - 128, // Make sure the parent of this div has a defined height
            }}>
                {images ? drawImages() :
                    <div className={"centered-content"}>
                        <Spinner animation="border" role="status"></Spinner><br/>
                        <span className="visually-hidden">Generating...</span>
                    </div>
                }
            </div>


            {showTitle && <div style={{position: "fixed", right: 24, bottom: 24}}>
                <h1>{preset?.name}</h1>
            </div>}

        </div>
    );
}

export default Preset;