import React, {useEffect, useState} from 'react';
import {Dropdown, DropdownButton, Form} from 'react-bootstrap';
import {useEndpoint} from "../../../hooks/api";

function Checkpoints(props) {
    const {fetch} = useEndpoint("comfyui/checkpoints");

    const [selectedCheckpoint, setSelectedCheckpoint] = useState('');
    const [checkpoints, setCheckpoints] = useState(undefined);

    useEffect(() => {
        async function fetchCheckpoints() {
            const checkpoints = await fetch({});
            setCheckpoints(checkpoints);
        }

        fetchCheckpoints();
    }, []);

    const handleSelect = (eventKey) => {
        setSelectedCheckpoint(eventKey);
        props.onSelect(eventKey);
    };

    return (
        checkpoints ?
            <DropdownButton
                id="dropdown-basic-button"
                title={selectedCheckpoint || "Select a checkpoint"}
                onSelect={handleSelect}
                defaultValue={props.checkpoint}
            >
                {Object.entries(checkpoints).map(([key, value]) => (
                    <Dropdown.Item key={key} eventKey={key}>
                        {key}
                    </Dropdown.Item>
                ))}
            </DropdownButton> : <Form.Control type={"text"} placeholder={"checkpoint"} value={props.checkpoint} onChange={(event) => {
                handleSelect(event.target.value)
            }} />
    );
}

export default Checkpoints;
