import React from 'react';
import Footer from '../components/footer';


export default () => {
  window.location.href = "https://jaxns.auth0.com/activate";

  return (<div className='wraperitem'>
        <section className='container-fluid p-0'>
          <div className='row'>


          </div>
        </section>

        <Footer />
      </div>
  )
}
