import React from 'react';
import Models from "../Models";
import ServiceDropdown from "../dropdowns/ServiceDropdown";
import ApiKeyTable from "../apikeys/ApiKeyTable";
import {useEndpoint} from "../../../../../hooks/api";
import {toast} from "react-toastify";

function AgentPropertiesTab({agent, onAgentUpdated, ...props}) {
    const {fetchAuth: updateServiceRequest} = useEndpoint("gpt/change-service");
    const { fetchAuth: updateModelRequest } = useEndpoint("gpt/change-model");
    async function updateService(service) {
        const response = await updateServiceRequest({
            "id": agent.id,
            "service": service
        });
        if(response.error) {
            toast.error(`Failed to update service for agent ${agent.name}. ${response.error}`);
            return;
        }
        agent.service = service;
        onAgentUpdated(agent);
    }

    async function updateModel(model) {
        const response = await updateModelRequest({
            "id": agent.id,
            "model": model
        })
        if(response.error) {
            toast.error(`Failed to update model for agent ${agent.name}. ${response.error}`);
            return;
        }
        agent.model = model;
        onAgentUpdated(agent);
    }

    return (
        <>
            <h2>
                <span>{agent.name.toUpperCase()}</span>
                <br/>
                <span style={{fontSize: 16, color: "#888888"}}>{agent.namespace.toUpperCase()}</span>
            </h2>
            {agent.description && <div>
                <span>{agent.description}</span>
            </div>}


            <div>
                <strong>Service:</strong>
                <ServiceDropdown value={agent.service} onChange={updateService}/>
            </div>

            <Models agent={agent} onModelChanged={updateModel}/>

            <ApiKeyTable agent={agent} />
        </>
    );
}

export default AgentPropertiesTab;