import React, {useEffect, useState} from 'react';
import {Helmet} from "react-helmet";
import {artApiFetchAsync} from "../../hooks/artapi";
import Lightbox from "./ImageGallery/components/CoolLightbox";
import GalleryFooter from "./ImageGallery/components/CoolLightbox/components/GalleryFooter";

const ImageViewer = () => {
    const [images, setImages] = React.useState();
    const [currentImageIndex, setCurrentIndex] = useState();
    const index = currentImageIndex === undefined ? 0 : currentImageIndex;

    useEffect(() => {
        async function fetchImage() {
            const id = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
            const response = await artApiFetchAsync("art", "limit=1", "sort=timestamp", "order=desc", "id="+id);
            console.log("Image: ", response);
            const images = response.map(image => {
                const img = {
                    src: image.url,
                    preview: image.preview ?? image.url,
                    alt: image.alt ?? image.parameters.prompt ?? "",
                    caption: image.title ?? "",
                    width: image.width ?? 500,
                    height: image.height ?? 500,
                    prompt: image.parameters.prompt ?? "",
                    id: image.id,
                    source: image.source
                }

                return img;
            });
            setImages(images);
        }

        fetchImage();
    },[]);
    return (images ? <div>
        <Helmet>
            <meta property="og:title"
                  content={"AI Art | " + (images[0].title ?? "Image")}/>
            <meta property="og:description"
                  content={images[0].alt}/>
            <meta property="og:image" content={images[0].url}/>
            <title>{"AI Art | " + (images[0].title ?? "Image")}</title>
        </Helmet>
        <Lightbox
        currentImageIndex={index}
        setCurrentIndex={setCurrentIndex}
        isOpen={true}
        images={images}
        footer={true}
        renderHeader={() => (<div className="header">
            <a href="/home"><img src="/img/logo.png" alt="AI Art" style={{margin: 16}} width={32} className="logo"/></a>
        </div>)}
        renderFooter={() => (<GalleryFooter image={images[index]} />)}
        onClose={() => {}}/>)
    </div> : (<div>Loading...</div>));
}

export default ImageViewer;