import React, {useEffect} from 'react';
import Footer from '../../components/footer';
import {artApiFetchAsync, artApiFetchAuthAsync} from "../../../hooks/artapi";
import {useAuth0} from "@auth0/auth0-react";
import ImageGallery from "../../components/ImageGallery";
import PageHeader from "../../components/PageHeader/pageheadercontrol";


const CCGCards = () => {
    const headerImage = '/img/headers/ccg.png'
    const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
    const [images, setImages] = React.useState([{
        src: "/img/midjourney-square.png",
        alt: "Waiting for images...",
        caption: "Waiting for images....",
        width: 500,
        height: 500
    }]);
    const [error, setError] = React.useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            if(isAuthenticated && !user) return;

            const fetchedImages = [];
            const token = isAuthenticated ? await getAccessTokenSilently() : null;
            const data = token ?
                await artApiFetchAuthAsync(token,'cards') :
                await artApiFetchAsync('cards');
            console.log("Cards: ", data);
            if(!data.error) {
                data.map(image => {
                    fetchedImages.push({
                        src: 'https://api.aiart.doubtech.com/card-image?id=' + image.id,
                        preview: `https://api.aiart.doubtech.com/card-image?id=${image.id}`,
                        alt: image.title,
                        id: image.id,
                        prompt: image.title,
                        width: 187,
                        height: 262,
                        caption: ""
                    });
                });
                setImages(fetchedImages);
                console.log("Fetched images: ", fetchedImages);
            } else {
                setError(data.error);
            }
        };
        fetchImages();
    }, [user]);

    return (
  <PageHeader image={headerImage} title={"Cards"}
              description={"A collection of AI and manually generated CCG cards."}
              breadcrumb={[
                  ["Home", "/"],
                  ["CCG", "/ccg"],
                  ["Cards", "/ccg/cards"]
              ]} >

    <section className='container-fluid black_more'>
      <div className='row m-10-hor'>
        <div className='col-12'>
            {error && (<div className='error'>{error}</div>)}
            {!error && <ImageGallery images={images}/>}
        </div>
      </div>
    </section>

    <Footer />

  </PageHeader>
);
}

export default CCGCards