import React, { useState, useEffect, useCallback } from 'react';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAsync, artApiFetchAuthAsync, artApiPostAsync, artApiPostAuthAsync} from "../../../hooks/artapi";
import {useIndexDB} from "../../providers/indexdb";
import {useEndpoint} from "../../../hooks/api";

function useAgentChat({agent = null, agentKey = null, sessionKey = undefined}) {
    const [ messages, setMessages ] = React.useState([]);
    const { getAccessTokenSilently, user } = useAuth0();
    const [ messageCount, setMessageCount ] = React.useState(0);
    const { getValue, setValue } = useIndexDB();
    const [ currentAgent, setAgent ] = React.useState(agent);
    const [ currentAgentKey, setAgentKey ] = React.useState(agentKey);


    const { postBody: postPrompt} = useEndpoint("gpt/agent");
    const { postBodyAuth: postPromptAuth} = useEndpoint("gpt/agent");

    function getStorageKey() {
        let key = "agent-chat::" + currentAgent.name;
        if(sessionKey) key += "::" + sessionKey;
        console.log("Got storage key: ", key);
        return key;
    }

    useEffect(() => {}, [messages]);

    useEffect( () => {
        async function getMessages() {
            const messages = await getValue(getStorageKey(), []);
            if (messages) {
                setMessages(messages);
                setMessageCount(messages.length);
            }
        }

        getMessages();
    }, [currentAgent, currentAgentKey, sessionKey]);

    async function saveCookie(messages) {
        await setValue(getStorageKey(), messages);
    }

    function resetSubmittedHistory() {
        messages.push({"resetHistory": true,
            sentTime: new Date().toUTCString(),
            sender: "You",
            direction: "outgoing",
        });
        setMessages(messages);
        setMessageCount(messageCount + 1);
        saveCookie(messages);
    }

    function deleteMessage(message) {
        const index = messages.indexOf(message);
        if(index > -1) {
            messages.splice(index, 1);
            setMessages(messages);
            setMessageCount(messageCount - 1);
            saveCookie(messages);
        }
    }

    function submitPrompt(promptValue, includeHistory = true, resetHistory = false) {
        const message = {
            content: promptValue,
            sentTime: new Date().toUTCString(),
            sender: "You",
            direction: "outgoing"
        };
        if(resetHistory) message.resetHistory = true;
        messages.push(message);
        setMessages(messages);
        setMessageCount(messageCount + 1);
        //saveCookie();

        async function fetchResponse() {
            await setValue(getStorageKey(), messages);
            if(!currentAgent) return;
            if(!promptValue) return;

            console.log("Prompt: ", promptValue);

            const body = {
            }

            if (includeHistory) {
                body.messages = [];
                // Add all of the messages in messages until we reach the beginning or a message.resetHistory preserve the order
                for (let i = messages.length - 1; i >= 0; i--) {
                    if (messages[i].resetHistory) {
                        break;
                    }

                    if ("error" in messages[i]) {
                        // Skip errors
                        continue;
                    }

                    // If this isn't a duplicate message, add it to the body
                    if (body.messages.length === 0 || body.messages[0].content !== messages[i].content) {
                        body.messages.unshift({
                            role: messages[i].role,
                            content: messages[i].content
                        });
                    }
                }
            } else {
                body.prompt = promptValue;
            }

            let messageResponse;
            if(currentAgentKey) {
                messageResponse = await postPrompt(body, {
                    "key": currentAgentKey,
                    "agent": currentAgent.name,
                    "namespace": currentAgent.namespace
                });
            } else {
                messageResponse = await postPromptAuth(body, {
                    "agent": currentAgent.name,
                    "namespace": currentAgent.namespace
                });
            }
            if(messageResponse) {
                if("error" in messageResponse) {
                    let error = messageResponse.error;
                    // If the error is an ampty string or null, set it to a default error message
                    if(!error) {
                        error = "An error occurred";
                    }
                    messages[messages.length - 1].error = error;
                    console.log("Error: ", body);
                } else {
                    //setResponseValue(messageResponse.content);
                    messages.push({
                        content: messageResponse.content,
                        error: messageResponse.error,
                        sentTime: new Date().toUTCString(),
                        sender: currentAgent.name,
                        direction: "incoming"
                    });
                    console.log("Sent: ", body);
                }
                setMessages(messages);
                setMessageCount(messageCount + 2);
                saveCookie(messages);
            }
        }

        fetchResponse();
    }

    return { submitPrompt, resetSubmittedHistory, deleteMessage, setAgent, setAgentKey, messages, messageCount };
}

export default useAgentChat;