import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';


/**
 * A single image element in a masonry style image grid
 */
const GridImage = ({ key, index, left, top, photo, onClick }) => {
  const { height, width, src, preview, alt, caption, id } = photo;

  return (
    <ImageContainer
      key={`${key}-${index}`}
      index={index}
      onClick={e => onClick(e, { index })}
      style={{ left, top, height, width }}
    >
      <OverlayContainer className='MainGimg'>
        <LazyLoadImage src={preview ?? src} alt={alt} caption={caption} width={width} height={height} effect="opacity" />
        <Caption className='overlayCap'>
          <span>{caption}</span>
        </Caption>
      </OverlayContainer>
    </ImageContainer>
  );
};

GridImage.propTypes = {
  key: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
  top: PropTypes.number.isRequired,
  containerHeight: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  photo: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    caption: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    src: PropTypes.string.isRequired
  }).isRequired
};

export default GridImage;

const Caption = styled.div`
  position: absolute;
`;

const OverlayContainer = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  transition: border-color 0.2s linear;
  display: block;
  position: absolute;
  cursor: pointer;
  border-width: 0;
  border-color: transparent;
  border-style: solid;
  :hover {
    border-color: ${({ theme }) => theme.pageContentLinkHoverColor};
  }
`;

const Image = styled.img`
  width: inherit;
  height: inherit;
  position: absolute;
`;
