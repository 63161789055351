import React, {useEffect, useState} from 'react';
import { Form, Button } from 'react-bootstrap';
import { MdAdd } from 'react-icons/md';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync, useAuthenticatedArtApi} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import {useEndpoint} from "../../../hooks/api";

const NewKeyForm = ({ onCreate, keyEndpoint="keys/key-types", createKeyEndpoint = "keys/create-key"}) => {
    const { response: userInfo, error: userError } = useAuthenticatedArtApi("user-info");
    const { fetchAuth: fetchKeys } = useEndpoint(keyEndpoint);
    const { fetchAuth: createKey } = useEndpoint(createKeyEndpoint);

    const [name, setName] = useState(null);
    const [type, setType] = useState(null);
    const [keyTypes, setKeyTypes] = useState([]);

    async function fetchKeyTypes() {
        if (userInfo) {
            const keyTypes = await fetchKeys();
            setKeyTypes(keyTypes);
        }
    }

    useEffect(() => {
        fetchKeyTypes();
    }, [userInfo]);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };

    async function refreshAccess() {
        await fetch("https://server.doubtech.com/api/server-to-server/refresh-access", {mode: 'no-cors'});
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        const keyType = type ?? keyTypes[0].entitlement;
        console.log(keyType);
        if(!name) {
            toast.error("Name is required");
            return;
        }

        async function handleCreateKey() {
            const key = await createKey({"name": name, "entitlement": keyType});
            if('error' in key) {
                toast.error(key.error);
            } else {
                onCreate(key);

                setName('');
                setType('');
            }

            refreshAccess();
        }

        handleCreateKey();
    };

    return (
        <Form inline onSubmit={handleSubmit}>
            <Form.Group controlId="formKeyName">
                <Form.Label>Name&nbsp;</Form.Label>{' '}
                <Form.Control
                    type="text"
                    placeholder="Enter key name"
                    value={name}
                    onChange={handleNameChange}
                    required
                />
            </Form.Group>{' '}
            <Form.Group controlId="formKeyType">
                <Form.Label>&nbsp;&nbsp;Type&nbsp;</Form.Label>{' '}
                <Form.Control
                    as="select"
                    value={type}
                    onChange={handleTypeChange}
                    required
                >
                    {keyTypes.map((keyType, index) => {
                        return <option key={index} value={keyType.entitlement}>{keyType.displayname}</option>;
                    })}
                </Form.Control>
            </Form.Group>{' '}&nbsp;
            <Button variant="success" type="submit">
                <MdAdd /> Create
            </Button>
        </Form>
    );
};

export default NewKeyForm;
