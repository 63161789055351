import React from 'react';
import PageHeader from "../../../components/PageHeader/pageheadercontrol";
import {CollapsibleCard, CollapsibleCardContent} from "../../../components/CollapsableCard";
import KeyList from "../../../components/apikeys/keylist";
import NewKeyForm from "../../../components/apikeys/newkeyform";


const ProxyAdminPage = () => {
    const headerImage = '/img/headers/proxies.png'
    const keyListRef = React.useRef(null);

    return (<PageHeader image={headerImage} title={"Proxy Management"}
                        description={"Perform administrative tasks"}
                        breadcrumb={[
                            ["Home", "/"],
                            ["Admin", "/admin"],
                            ["Proxy Management", "/admin/proxies"]
                        ]}
                        entitlement={"admin"}
            >


        <CollapsibleCard title="Proxies" className={"mt-5"} expand={true}>
            <CollapsibleCardContent>
                <KeyList ref={keyListRef}
                         listEndpoint={"proxies/list-proxies"}
                         deleteEndpoint={"proxies/delete-proxy"}
                         enableEndpoint={"proxies/enable-proxy"}
                         disableEndpoint={"proxies/disable-proxy"}
                />

                <NewKeyForm onCreate={() => {keyListRef.current.refresh()}}
                        createKeyEndpoint={"proxies/create-proxy"} />
            </CollapsibleCardContent>
        </CollapsibleCard>

    </PageHeader>);
}

export default ProxyAdminPage;