import React, {useState} from 'react';
import './agentgrid.css';
import {Form} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {useNavigate} from 'react-router-dom';

function AgentGrid({agents}) {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    const allAgents = Object.values(agents).flat();

    const filteredAgents = allAgents.filter(agent =>
        agent.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const groupedAgents = filteredAgents.reduce((groups, agent) => {
        const namespace = agent.namespace || 'Uncategorized';
        if (!groups[namespace]) {
            groups[namespace] = [];
        }
        groups[namespace].push(agent);
        return groups;
    }, {});

    function handleClick(agent) {
        navigate(`/gpt/agent/${agent.id}`);
    }

    return (
        <div className="agent-grid">
            <Form.Control
                type="text"
                placeholder="Search agents..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="mb-3"
            />
            {Object.keys(groupedAgents).map((namespace, index) => (
                <div key={index}>
                    <h4 className="namespace-title">
                        {namespace}
                    </h4>
                    <div className="namespace-agents">
                        {groupedAgents[namespace].map((agent, idx) => (
                            <div key={idx} className="agent-box" onClick={() => handleClick(agent)}>
                                <div className="agent-box-content">
                                    <h3 className="agent-name">{agent.name}</h3>
                                    <p className="agent-description">{agent.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default AgentGrid;