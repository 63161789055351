import React from 'react';

const ModelIcon = ({model}) => {
    var img = "/img/logo.png";
    if(model == 'stable-diffusion') img = "/img/sd.png";
    if(model == 'midjourney') img = '/img/midjourney.png';
    if(model == 'daliy') img = '/img/dali.png';

    return (
        <div>
            <img
            src={img}
            width={256}
            height={256}
        />
        </div>
    )
}

export default ModelIcon;