import React, {useEffect, useState} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {artApiFetchAuthAsync, artApiPostAuthAsync} from "../../../hooks/artapi";
import {Button, Form, Modal, Table} from 'react-bootstrap';
import {FaTrashAlt, MdModeEdit} from "react-icons/all";

import './table.css';
import {MdAdd} from "react-icons/md";

function DataTable({ listEndpoint, addEndpoint, deleteEndpoint, modifyEndpoint, fields=[], infoEndpoint, maxCellWidth = 100}) {
    const { getAccessTokenSilently, user } = useAuth0();
    const [data, setData] = useState([]);
    const [newData, setNewData] = useState({});
    const [editData, setEditData] = useState({});
    const [cellWidths, setCellWidths] = useState([]);

    // Add state for managing the modal
    const [modalShow, setModalShow] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const [editModalShow, setEditModalShow] = useState(false);
    const [addModalShow, setAddModalShow] = useState(false);

    const [displayFields, setDisplayFields] = useState(fields);
    const [dataFields, setDataFields] = useState(fields);

    useEffect(() => {
        const calculateCellWidths = () => {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = '14px Arial';  // Adjust to your actual table font
            const maxLengths = displayFields.map((field) =>
                Math.min(context.measureText(data.reduce((a, v) => a[field]?.length > v[field]?.length ? a : v, {})[field] || '').width, maxCellWidth)
            );

            // Calculate total available width
            const totalAvailableWidth = window.innerWidth - 40; // Subtract 40 for some padding
            // Now distribute the totalAvailableWidth proportionally to the maxLengths
            const totalLength = maxLengths.reduce((sum, len) => sum + len, 0);
            const cellWidths = maxLengths.map((len) => (len / totalLength) * totalAvailableWidth);

            setCellWidths(cellWidths);
        }

        // Call initially and whenever window resizes
        calculateCellWidths();
        window.addEventListener('resize', calculateCellWidths);
        return () => window.removeEventListener('resize', calculateCellWidths);
    }, [data, maxCellWidth, displayFields]);

    // Function for opening the row add modal
    const openAddModal = () => {
        setAddModalShow(true);
    }

    // Function for closing the row add modal
    const closeAddModal = () => {
        setAddModalShow(false);
        setNewData({});  // Clear the new data when the modal is closed
    }

    const handleNewChange = (e, fieldName) => {
        setNewData({ ...newData, [fieldName]: e.target.value });
    }

    // Function for opening the cell content modal
    const openModal = (content) => {
        setModalContent(content);
        setModalShow(true);
    }

    // Function for closing the cell content modal
    const closeModal = () => {
        setModalShow(false);
        setModalContent('');
    }

    // Function for opening the row edit modal
    const openEditModal = (item) => {
        setEditData(item);
        setEditModalShow(true);
    }

    // Function for closing the row edit modal
    const closeEditModal = () => {
        setEditModalShow(false);
    }

    const handleClick = (item) => {
        openModal(item);
    }

    useEffect(() => {
        async function fetchData() {
            const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const response = await artApiFetchAuthAsync(token, listEndpoint);
            if("error" in response) {
                console.error(`Failed to fetch data.\n${response.error}`);
            } else {
                setData(response);
                const keys = Object.keys(response[0] || {});
                if(fields.length == 0 && keys.length == 0) {
                    const fieldResponse = await artApiFetchAuthAsync(token, infoEndpoint);
                    if("error" in fieldResponse) {
                        console.error(`Failed to fetch data.\n${fieldResponse.error}`);
                    } else {
                        setDisplayFields(fieldResponse);
                        setDataFields(fieldResponse);
                    }
                } else {
                    const df = fields.length > 0 ? fields : keys;
                    setDisplayFields(df);
                    setDataFields(keys);
                }
            }
        }

        fetchData();
    }, [listEndpoint, getAccessTokenSilently]);

    const handleChange = (e, fieldName) => {
        setEditData({ ...editData, [fieldName]: e.target.value });
    }

    const addData = async (newData) => {
        const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});

        try {
            await artApiPostAuthAsync(token, addEndpoint, JSON.stringify(newData));
            setData([...data, newData]); // update the state with the new data
        } catch (error) {
            console.error("Error while adding new data: ", error);
        }
    }

    const modifyData = async () => {
        const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});

        try {
            await artApiPostAuthAsync(token, modifyEndpoint, JSON.stringify(editData));
            // Update local state after successful modification.
            setData(data.map((item) => (item.id === editData.id ? editData : item)));  // Assuming each item has a unique 'id' field
            closeEditModal();
        } catch (error) {
            console.error("Error while modifying data: ", error);
        }
    }

    const deleteData = async (dataToDelete, index) => {
        const token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});

        try {
            await artApiPostAuthAsync(token, deleteEndpoint, JSON.stringify(dataToDelete));
            setData(data.filter((item, i) => i !== index)); // Remove the deleted item from state
        } catch (error) {
            console.error("Error while deleting data: ", error);
        }
    }

    return (
        <>
            <div className="table-container">
                <div className="table-content">
                    <Table striped bordered hover variant="dark">
                        {/* Header */}
                        <thead>
                        <tr>
                            {displayFields.map((field, index) => <th key={index}>{field}</th>)}
                            <th>
                                Actions
                                <Button variant="primary" onClick={openAddModal}>
                                    <MdAdd />
                                </Button>
                            </th>
                        </tr>
                        </thead>

                        {/* Data rows */}
                        <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                {displayFields.map((field, fieldIndex) =>
                                    <td
                                        key={fieldIndex}
                                        style={{ maxWidth: Math.min(maxCellWidth, cellWidths[fieldIndex]) }}
                                        className="ellipsis"
                                        onClick={() => handleClick(item[field])}
                                    >
                                        {(() => {
                                            const urlPattern = new RegExp('^(http|https)://', 'i');
                                            if (urlPattern.test(item[field])) {
                                                const isImage = /\.(jpeg|jpg|gif|png)$/.test(item[field]);
                                                if (isImage) {
                                                    // Add inline styles to ensure the image fits and preserves aspect ratio
                                                    return <img src={item[field]} alt={item[field]} style={{maxWidth: "100%", maxHeight: "100%", objectFit: "contain"}}/>
                                                } else {
                                                    // Extract the filename and its extension
                                                    const fileName = item[field].split("/").pop();
                                                    const hasExtension = /\.[^/.]+$/.test(fileName);

                                                    // Only return filename if it has an extension
                                                    if (hasExtension) {
                                                        return <a href={item[field]}>{fileName}</a>
                                                    } else {
                                                        // If no extension, return the full field
                                                        return <a href={item[field]}>{item[field]}</a>
                                                    }
                                                }
                                            }
                                            return <span className='cell-content'>{item[field]}</span>;
                                        })()}
                                    </td>
                                )}

                                <td>
                                    <Button variant="primary" onClick={() => openEditModal(item)}><MdModeEdit /></Button>
                                    <Button variant="danger" onClick={() => deleteData(item, index)}><FaTrashAlt /></Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>

                    {/* The row add modal */}
                    <Modal show={addModalShow} onHide={closeAddModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add row</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                {dataFields.map((field, index) =>
                                    <Form.Group key={index}>
                                        <Form.Label>{field}</Form.Label>
                                        <Form.Control type="text" value={newData[field] || ''} onChange={(e) => handleNewChange(e, field)} />
                                    </Form.Group>
                                )}
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeAddModal}>Cancel</Button>
                            <Button variant="success" onClick={() => {addData(newData); closeAddModal();}}>Add Row</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* The cell content modal */}
                    <Modal show={modalShow} onHide={closeModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Cell content</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>{modalContent}</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeModal}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                    {/* The row edit modal */}
                    <Modal show={editModalShow} onHide={closeEditModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit row</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                {displayFields.map((field, index) =>
                                    <Form.Group key={index}>
                                        <Form.Label>{field}</Form.Label>
                                        <Form.Control type="text" value={editData[field] || ''} onChange={(e) => handleChange(e, field)} />
                                    </Form.Group>
                                )}
                            </Form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={closeEditModal}>Cancel</Button>
                            <Button variant="success" onClick={modifyData}>Save changes</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        </>
    );
}

export default DataTable;
