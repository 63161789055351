import React, {useState} from 'react';
import {Button, Card, Form} from "react-bootstrap";

import {artApiFetchAuthAsync} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import {useAuth0} from "@auth0/auth0-react";

function CreateDataSet({ onCreated, onCancel }) {
    const { getAccessTokenSilently, user } = useAuth0();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);
    const [category, setCategory] = useState('');

    const [collapsed, setCollapsed] = useState(true);

    function createDataSet() {
        async function fetchResponse() {
            var token = await getAccessTokenSilently({scopes: ['openid', 'profile', 'email']});
            const messageResponse = await artApiFetchAuthAsync(token, "prompt-data/create-dataset",
                "name=" + encodeURIComponent(name),
                "description=" + encodeURIComponent(description),
                "category=" + encodeURIComponent(category),
                "isPrivate=" + encodeURIComponent(isPrivate));
            // if error in response, show error
            if("error" in messageResponse) {
                toast.error(`Failed to fetch datasets.\n${messageResponse.error}`);
            } else {
                onCreated(messageResponse);
            }
        }

        fetchResponse();
    }

    return <Card>
        <Card.Header>
            <div className={"create-card-flex-between-container"}>
                <span>Create Data Set</span>
            </div>
        </Card.Header>
        <Card.Body>
            <Form.Group controlId="content">
                <Form.Label>Name</Form.Label>
                <Form.Control value={name} onChange={(event) => setName(event.target.value)} />
            </Form.Group>

            <Form.Group controlId="category">
                <Form.Label>Category</Form.Label>
                <Form.Control value={category} onChange={(event) => setCategory(event.target.value)} />
            </Form.Group>

            <Form.Group controlId="content">
                <Form.Label>Description:</Form.Label>
                <Form.Control as="textarea" value={description} onChange={(event) => setDescription(event.target.value)} />
            </Form.Group>

            <Form.Group controlId="content">
                <Form.Check label={"Private"} type="checkbox" checked={isPrivate} onChange={(event) => setIsPrivate(event.target.checked)} />
            </Form.Group>

            <div className={"create-card-flex-between-container"}>
                <div className={"create-card-flex-between-items-grow"} />
                <Button variant="primary" type="submit" onClick={() => onCancel()}>
                    Cancel
                </Button>
                <Button variant="primary" type="submit" onClick={() => createDataSet()}>
                    Create
                </Button>
            </div>
        </Card.Body>
    </Card>
}

export default CreateDataSet;

