import React, {forwardRef, useImperativeHandle, useEffect, useState} from 'react';
import {Form, Button, Table} from 'react-bootstrap';
import {MdAdd, MdDelete} from 'react-icons/md';
import {useAuth0} from "@auth0/auth0-react";
import {artApiFetchAuthAsync, useAuthenticatedArtApi} from "../../../hooks/artapi";
import {toast} from "react-toastify";
import ObfuscatedKey from "../obfuscatedkey";
import {useEndpoint} from "../../../hooks/api";
import StyledButton from "../styledbutton";

const RunpodList = forwardRef(( props, ref ) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const { response: userInfo, error: userError } = useAuthenticatedArtApi("user-info");
    const [keys, setKeys] = useState([]);
    const [keyTypes, setKeyTypes] = useState([]);
    const { fetchAuth: listEndpoint } = useEndpoint("art-api/runpod/list");
    const { fetchAuth: deleteEndpoint } = useEndpoint("art-api/runpod/delete");
    const { fetchAuth: infoEndpoint } = useEndpoint("art-api/runpod/info");
    const { postAuth: addEndpoint } = useEndpoint("art-api/runpod/add");
    const { postAuth: healthEndpoint } = useEndpoint("art-api/runpod/health");

    useImperativeHandle(ref, () => ({
        refresh() {
            fetchKeys();
        }
    }) );

    async function fetchKeys() {
        if (userInfo) {
            const keys = await listEndpoint();
            if(!keys) {
                return;
            }
            if (keys.error) {
                toast.error(keys.error);
                return;
            }

            // Iterate over each key and get the health of the key via its id
            for (let key of keys) {
                key.health = await healthEndpoint({"id": key.id});
            }
            console.log("keys: ", keys);

            setKeys(keys);
        }
    }

    useEffect(() => {
        fetchKeys();
    }, [userInfo]);

    function handleDelete(key) {
        async function executeDeleteKey(key) {
            const result = await deleteEndpoint({"id": key});
            if('error' in result) {
                toast.error(result.error);
            } else {
                fetchKeys();
            }
        }

        executeDeleteKey(key);
    }

    return (
        <Table striped bordered hover variant="dark">
            <thead>
            <tr>
                <th>Name</th>
                <th>Description </th>
                <th>Private</th>
                <th>Delete</th>
            </tr>
            </thead>
            <tbody>
            {keys && keys.map(key => {
                return (<>
                    <tr key={key.id}>
                        <td>{key.name}</td>
                        <td>{key.description}</td>
                        <td><input type="checkbox" checked={key.private === 1} readOnly /></td>
                        <td><StyledButton onClick={() => handleDelete(key.id)}><MdDelete /></StyledButton></td>
                    </tr>
                    <tr>
                    <td colSpan="4">
                    <div className="row">
                        <div className="col-md-6">
                            <strong>Jobs:</strong>
                            <ul>
                                <li>Completed: {key.health.jobs.completed}</li>
                                <li>Failed: {key.health.jobs.failed}</li>
                                <li>In Progress: {key.health.jobs.inProgress}</li>
                                <li>In Queue: {key.health.jobs.inQueue}</li>
                                <li>Retried: {key.health.jobs.retried}</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <strong>Workers:</strong>
                            <ul>
                                <li>Idle: {key.health.workers.idle}</li>
                                <li>Initializing: {key.health.workers.initializing}</li>
                                <li>Ready: {key.health.workers.ready}</li>
                                <li>Running: {key.health.workers.running}</li>
                                <li>Throttled: {key.health.workers.throttled}</li>
                                <li>Unhealthy: {key.health.workers.unhealthy}</li>
                            </ul>
                        </div>
                    </div>
                </td>
                </tr>
                </>);
            })}
            </tbody>
        </Table>
    );
});

export default RunpodList;
