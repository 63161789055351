import React, { useState } from 'react';
import { Motion, spring } from 'react-motion';

function AnimatedImage({ src, alt, onClick }) {
    const [loaded, setLoaded] = useState(false);

    const defaultStyle = {
        opacity: 0,
        scale: 0.8
    };

    const style = loaded ? {
        opacity: spring(1),
        scale: spring(1)
    } : defaultStyle;

    return (
        <Motion defaultStyle={defaultStyle} style={style}>
            {interpolatingStyle =>
                <div style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative'
                }}>
                    <img
                        onClick={onClick}
                        src={src}
                        alt={alt}
                        style={{
                            opacity: interpolatingStyle.opacity,
                            objectFit: 'cover',
                            objectPosition: 'center center',  // Ensure image is centered
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: `translate(-50%, -50%) scale(${interpolatingStyle.scale})`
                        }}
                        onError={() => setLoaded(false)}
                        onLoad={() => setLoaded(true)
                    }
                    />
                </div>
            }
        </Motion>
    );
}

export default AnimatedImage;
