import React, {useEffect} from 'react';
import {Card, ListGroup} from "react-bootstrap";
import {MdDelete, MdFileCopy, MdLink, MdNewLabel} from "react-icons/md";
import CodePreview from "../../../codepreview/codepreview";
import {toast} from "react-toastify";
import AgentPromptForm from "../../agentpromptform";
import {artApiFetchAuthAsync} from "../../../../../hooks/artapi";
import {useEndpoint} from "../../../../../hooks/api";
import {MdShare, MdVpnKey} from "react-icons/all";
import AddApiKeyDialog from "../dialogs/AddApiKeyDialog";

function ApiKeyTable({agent, prompt, ...props}) {
    const {fetchAuth: deleteKeyRequest} = useEndpoint("gpt/keys/delete");
    const {fetchAuth: fetchApiKeyAuth} = useEndpoint("gpt/keys/list");
    const [apiKeys, setApiKeys] = React.useState([]);
    const [postData, setPostData] = React.useState();
    const [rawPostData, setRawPostData] = React.useState({prompt: prompt});
    const [ createApiKey, setCreateApiKey ] = React.useState(false);

    function copyUrl(apiKey) {
        navigator.clipboard.writeText("https://api.aiart.doubtech.com/gpt/agent?key=" + apiKey + "&prompt=").then(() => {
            toast.success("URL copied to clipboard");
        });
    }

    function copyShareUrl(apiKey) {
        // Get the current base domain for the root of this site then append the path /gpt/chat/ and the api key
        const url = window.location.origin + "/gpt/chat/" + apiKey;

        navigator.clipboard.writeText(url).then(() => {
            toast.success("URL copied to clipboard");
        });
    }

    function copyKey(apiKey) {
        navigator.clipboard.writeText(apiKey);
        toast.success("API Key copied to clipboard");
    }

    async function fetchApiKeys() {
        const apiKeyResponse = await fetchApiKeyAuth({
            "id": agent.id
        });
        if (apiKeyResponse) {
            setApiKeys(apiKeyResponse);
        }
    }

    useEffect(() => {
        fetchApiKeys();
    }, []);

    function deleteKey(apiKey) {
        async function fetchResponse() {
            await deleteKeyRequest({
                "id": apiKey
            });

            await fetchApiKeys();
        }

        fetchResponse();
    }

    const transformToStars = (text) => {
        return text.replace(/[A-Za-z0-9]/g, '*');
    };

    function prepGet(apikey, hide) {
        return `curl -H "Authorization: Bearer ${hide ? transformToStars(apikey) : apikey}" "https://api.aiart.doubtech.com/gpt/agent?prompt=${encodeURIComponent(prompt)}"`;
    }

    function prepPost() {
        function escapeJsonForShell(jsonData) {
            return JSON.stringify(jsonData)
                .replace(/\\/g, '\\\\')  // Escape backslashes
                .replace(/"/g, '\\"');   // Escape double quotes
        }

        return <AgentPromptForm onPostDataChanged={(data) => {
            setRawPostData(data);
            setPostData(escapeJsonForShell(data));
        }}/>;
    }

    function createCurlPost(apiKey, hide) {
        return `curl -H "Authorization: Bearer ${hide ? transformToStars(apiKey) : apiKey}" \\
            -H "Content-Type: application/json" \\
            -d "${postData}" \\
            "https://api.aiart.doubtech.com/gpt/agent"`;
    }

    function createPythonCurlPost(apiKey, hide) {
        const transformedKey = hide ? '***' : apiKey;
        return `import requests

headers = {
    'Authorization': 'Bearer ${transformedKey}',
    'Content-Type': 'application/json',
}

data = ${JSON.stringify(rawPostData)}

response = requests.post('https://api.aiart.doubtech.com/gpt/agent', headers=headers, json=data)
print(response.text)`;
    }

    function createPhpCurlPost(apiKey, hide) {
        const transformedKey = hide ? '***' : apiKey;
        return `<?php

$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, 'https://api.aiart.doubtech.com/gpt/agent');
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
curl_setopt($ch, CURLOPT_POST, 1);
curl_setopt($ch, CURLOPT_POSTFIELDS, "${postData}");
curl_setopt($ch, CURLOPT_HTTPHEADER, array(
  'Authorization: Bearer ${transformedKey}',
  'Content-Type: application/json',
));

$response = curl_exec($ch);
if (curl_errno($ch)) {
    echo 'Error:' . curl_error($ch);
}
curl_close($ch);

echo $response;
?>`;
    }

    return (
        <>
        <Card className={"mt-5"}>
            <Card.Header>
                <div className={"create-card-flex-between-container"}>
                    <span>API Keys</span>
                    <MdVpnKey className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {
                        agent ? setCreateApiKey(!createApiKey) : setCreateApiKey(false);
                    }}/>
                </div>
            </Card.Header>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    {prepPost()}
                </ListGroup.Item>
                {apiKeys && apiKeys.map(key =>
                    <ListGroup.Item style={{cursor: "pointer"}}
                                    key={key.apikey}>
                        <div>
                            <div className={"create-card-flex-between-container"}>
                                {key.description}
                                <div className={"create-card-flex-between-items-grow"}/>
                                <MdShare className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {
                                    copyShareUrl(key.apikey)
                                }}/>
                                <MdLink className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {
                                    copyUrl(key.apikey)
                                }}/>
                                <MdDelete className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {
                                    deleteKey(key.apikey)
                                }}/>
                                <MdFileCopy className={"mdicon"} style={{cursor: "pointer"}} onClick={() => {
                                    copyKey(key.apikey)
                                }}/>


                            </div>
                            <br/>
                            <CodePreview title={"GET"} language={"bash"} className={"mt-5"}
                                         clipboardCode={prepGet(key.apikey, false)}>
                                {prepGet(key.apikey, true)}
                            </CodePreview>
                            <CodePreview title={"POST"} language={"bash"} className={"mt-5"}
                                         clipboardCode={createCurlPost(key.apikey, false)}>
                                {createCurlPost(key.apikey, true)}
                            </CodePreview>

                            <CodePreview title={"POST"} language={"python"} className={"mt-5"}
                                         clipboardCode={createPythonCurlPost(key.apikey, false)}>
                                {createPythonCurlPost(key.apikey, true)}
                            </CodePreview>

                            <CodePreview title={"POST"} language={"php"} className={"mt-5"}
                                         clipboardCode={createPhpCurlPost(key.apikey, false)}>
                                {createPhpCurlPost(key.apikey, true)}
                            </CodePreview>
                        </div>
                    </ListGroup.Item>)}
            </ListGroup>
        </Card>
            <AddApiKeyDialog agent={agent} show={createApiKey} onApiKeyCreated={fetchApiKeys} onClose={() => setCreateApiKey(false)} />
        </>
    );
}

export default ApiKeyTable;